import { TableCell, TableContainer, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyleTableContainer = styled(TableContainer)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.background.default,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    "& .MuiTableCell-root": {
        borderColor: theme.palette.background.default,
    },
}));

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderWidth: 0,
}));

export const StyledTableRow = styled(TableRow)(({ theme, isSelected }) => ({
    backgroundColor: isSelected ? "rgba(87, 103, 242, 0.3)" : "transparent",
    "&:hover": {
        backgroundColor: isSelected ? "rgba(87, 103, 242, 0.2)" : "#f5f5f5",
    },
    "& .MuiInputBase-root": {
        backgroundColor: isSelected ? theme.palette.common.white : "none",
    },
}));
