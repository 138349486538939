import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import { Button, styled, useTheme } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
    justifyContent: "space-between",
    // "& .MuiSvgIcon-root.selected": {
    //     fill: theme.palette.service_status.ongoing,
    // },
}));

export default function CustomDeviceSelectButton({
    label,
    fullWidth,
    disabled,
    isSelected,
    onClick,
}) {
    const theme = useTheme();
    return (
        <StyledButton
            fullWidth={fullWidth}
            disabled={disabled}
            variant="contained"
            onClick={(e) => {
                if (onClick !== undefined) onClick(e);
            }}
            endIcon={isSelected ? <CheckCircle className="selected" /> : <RadioButtonUnchecked />}
            color={isSelected ? "success" : "primary"}
        >
            {label}
        </StyledButton>
    );
}
