import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { API, Auth, Geo } from "aws-amplify";
import moment from "moment";
import { setUserInfo, showLoader, updateSignUpProcessFlow } from "./authSlice";

export const hostAPI = createApi({
    reducerPath: "hostAPI",
    baseQuery: fakeBaseQuery(),
    endpoints: (builder) => ({
        updateSignUpProcess: builder.mutation({
            queryFn: async ({
                statusCode,
            }, {
                dispatch,
            }) => {
                try {
                    const response = await API.put(
                        "UserAPI",
                        `/updateSignUpProcessStatus?statusCode=${statusCode}`,
                    );

                    dispatch(
                        updateSignUpProcessFlow({
                            statusCode,
                        }),
                    );

                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
        updateTomorrowReport: builder.mutation({
            queryFn: async ({
                standardCheckInTime,
                standardCheckOutTime,
                isFromSignUp,
                isTomorrowReportActive,
                tomorrowReportTimeSpan,
                localTimeZoneDatabaseName,
            }, {
                dispatch,
            }) => {
                try {
                    const myInit = {
                        body: {
                            standardCheckInTime,
                            standardCheckOutTime,
                            isFromSignUp,
                            isTomorrowReportActive,
                            tomorrowReportTimeSpan,
                            localTimeZoneDatabaseName,
                        },
                    };

                    const res = await API.put("UserAPI", "/updateProfile", myInit);
                    const awsUser = await Auth.currentAuthenticatedUser();
                    const { attributes } = awsUser;

                    dispatch(
                        setUserInfo({
                            id: res.id,
                            firstName: attributes.given_name,
                            lastName: attributes.family_name,
                            standardCheckInTime: res.standardCheckInTime,
                            standardCheckOutTime: res.standardCheckOutTime,
                            isStandardTimeUpdated: res.isStandardTimeUpdated,
                            isTomorrowReportActive: res.isTomorrowReportActive,
                            tomorrowReportTimeSpan: res.tomorrowReportTimeSpan,
                            localTimeZoneDatabaseName: res.localTimeZoneDatabaseName,
                        }),
                    );

                    return {
                        data: res,
                    };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
        getAllAutomatedRequestList: builder.query({
            queryFn: async (
                { propertyFK },
                {
                    getState,
                },
            ) => {
                try {
                    const localUserTZ = getState().auth.userInfo.localTimeZoneDatabaseName;
                    const response = await API.get("ServiceProviderApi", `/getAutomatedRequests?propertyFK=${propertyFK}`);

                    for (let index = 0; index < response.length; index++) {
                        const element = response[index];
                        if (element.dateOfRequest !== null) {
                            const dtRequest = moment.tz(element.dateOfRequest, localUserTZ);

                            element.convertedDateOfRequest = dtRequest.format("MM/DD/YYYY");
                            element.convertedTimeOfRequest = dtRequest.format("hh:mm A");
                        } else {
                            element.convertedDateOfRequest = "";
                            element.convertedTimeOfRequest = "";
                        }
                    }

                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: [{
                type: "automatedRequest",
                id: "list",
            }],
        }),
        cancelServiceRequest: builder.mutation({
            queryFn: async ({
                sarId,
                srId,
                sarStatusId,
                srStatusId,
            }) => {
                const init = {
                    body: {
                        saR_Id: sarId,
                        sR_Id: srId,
                        saR_StatusId: sarStatusId,
                        sR_StatusId: srStatusId,
                    },
                };
                try {
                    const response = await API.post("ServiceProviderApi", "/cancelServiceRequest", init);
                    return {
                        data: response,
                    };
                } catch (error) {
                    return {
                        error,
                    };
                }
            },
            invalidatesTags: [{
                type: "automatedRequest",
                id: "list",
            }],
        }),
        getAllProperties: builder.query({
            queryFn: async () => {
                try {
                    const response = await API.get("UserPropertyAPI", "/getAllProperties");

                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: [{
                type: "properties",
                id: "list",
            }],
        }),
        getProperty: builder.query({
            queryFn: async ({
                propertyId,
            }) => {
                try {
                    const response = await API.get("UserPropertyAPI", `/${propertyId}`);

                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: ({ id }) => [{
                type: "property",
                id,
            }],
        }),
        getPropertyStatus: builder.query({
            queryFn: async ({
                propertyId,
            }, {
                getState,
            }) => {
                try {
                    const response = await API.get("UserPropertyAPI", `/getPropertyStatus?propertyFk=${propertyId}`);
                    return {
                        data: response,
                    };
                } catch (error) {
                    return {
                        error,
                    };
                }
            },
        }),
        addServiceProvider: builder.mutation({
            queryFn: async ({
                serviceProviderId,
                phoneNumber,
                group,
                firstName,
                lastName,
                serviceTypeId,
                propertyFK,
                minAfterCheckOut,
            }, {
                dispatch,
                getState,
            }) => {
                try {
                    dispatch(showLoader({ open: true, message: "Sending invite. Please wait..." }));
                    const init = {
                        body: {
                            serviceProviderId,
                            phoneNumber,
                            group,
                            firstName,
                            lastName,
                            serviceTypeId,
                            propertyFK,
                            minAfterCheckOut,
                        },
                    };
                    const response = await API.post("ServiceProviderApi", "/inviteServiceProvider", init);
                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                } finally {
                    dispatch(showLoader({ open: false, message: "" }));
                }
            },
            invalidatesTags: [{
                type: "automatedRequest",
                id: "list",
            }],
        }),
        addProperty: builder.mutation({
            queryFn: async ({
                propertyName,
                locationLong,
                locationLat,
                propertyStatus,
                streetAddress1,
                city,
                state,
                country,
                postalCode,
                timeZone,
                bedroomCount,
                bathroomCount,
                isFromSignUp,
                propertyRooms,
            }) => {
                try {
                    const init = {
                        body: {
                            propertyName,
                            locationLong,
                            locationLat,
                            propertyStatus,
                            streetAddress1,
                            city,
                            state,
                            country,
                            postalCode,
                            timeZone,
                            bedroomCount,
                            bathroomCount,
                            isFromSignUp,
                            propertyRooms,
                        },
                    };
                    const response = await API.post("UserPropertyAPI", "/", init, { timeout: 2000 });

                    return { data: response };
                } catch (error) {
                    if (error?.response.status === 400) {
                        return {
                            error: {
                                message: "Error encountered while adding a property. Please try again.",
                            },
                        };
                    }
                    if (error?.response.status === 500) {
                        return {
                            error: {
                                message: error?.response?.data?.detail,
                            },
                        };
                    }
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: [{
                type: "properties",
                id: "list",
            }],
        }),
        updateProperty: builder.mutation({
            queryFn: async ({
                propertyId,
                propertyName,
                cognitoUserId,
                locationLong,
                locationLat,
                streetAddress1,
                city,
                state,
                country,
                postalCode,
                timeZone,
                bedroomCount,
                bathroomCount,
                propertyRooms,
            }) => {
                try {
                    const init = {
                        body: {
                            propertyId,
                            propertyName,
                            cognitoUserId,
                            locationLong,
                            locationLat,
                            streetAddress1,
                            city,
                            state,
                            country,
                            postalCode,
                            timeZone,
                            bedroomCount,
                            bathroomCount,
                            propertyRooms,
                        },
                    };
                    const response = await API.put("UserPropertyAPI", "/", init, { timeout: 2000 });
                    return { data: response };
                } catch (error) {
                    if (error?.response.status === 400) {
                        return {
                            error: {
                                message: "Error encountered while updating your property. Please try again.",
                            },
                        };
                    }
                    if (error?.response.status === 500) {
                        return {
                            error: {
                                message: error?.response?.data?.detail,
                            },
                        };
                    }
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: [{
                type: "properties",
                id: "list",
            }, "property"],
        }),
        removeProperty: builder.mutation({
            queryFn: async ({ propertyFK }) => {
                try {
                    const response = API.del("UserPropertyAPI", `/${propertyFK}`);
                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: [{
                type: "properties",
                id: "list",
            }],
        }),
        checkPropertyNameExist: builder.query({
            queryFn: async ({
                propertyId,
                propertyName,
            }) => {
                try {
                    if (propertyName.length === 0) {
                        return {
                            data: null,
                        };
                    }
                    const init = {
                        body: {
                            propertyId,
                            propertyName: propertyName.trim(),
                        },
                    };
                    const response = API.post("UserPropertyAPI", "/checkPropertyName", init);
                    return {
                        data: response,
                    };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
        searchLocation: builder.query({
            queryFn: async ({
                locationVal,
            }) => {
                if (locationVal === "") {
                    return {
                        data: null,
                    };
                }

                try {
                    const searchOptionsWithBiasPosition = { maxResults: 5 };
                    const result = await Geo.searchByText(locationVal, searchOptionsWithBiasPosition);

                    // transform response
                    const transformedResult = result.map((e) => {
                        const res = {
                            longitude: e.geometry.point[0].toString(),
                            latitude: e.geometry.point[1].toString(),
                            label: e.label,
                            country: e.country || "",
                            municipality: e.municipality || "",
                            postalCode: e.postalCode || "",
                            region: e.region || "",
                            street: e.street,
                            timeZone: e.timeZone.name || "",
                        };
                        return res;
                    });

                    return {
                        data: transformedResult,
                    };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
    }),
});

export const {
    useUpdateSignUpProcessMutation,
    useGetAllAutomatedRequestListQuery,
    useCancelServiceRequestMutation,
    useGetAllPropertiesQuery,
    useGetPropertyQuery,
    useGetPropertyStatusQuery,
    useAddServiceProviderMutation,
    useAddPropertyMutation,
    useUpdatePropertyMutation,
    useRemovePropertyMutation,
    useSearchLocationQuery,
    useLazySearchLocationQuery,
    useLazyCheckPropertyNameExistQuery,
    useUpdateTomorrowReportMutation,
} = hostAPI;
