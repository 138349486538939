import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";
import { authSlice } from "./authSlice";

const propertyAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        addToIoTPropertyReference: builder.mutation({
            queryFn: async ({ property }) => {
                try {
                    const myInit = {
                        body: property,
                    };
                    const response = await API.post(
                        "IoTAPI",
                        "/propertyReferences",
                        myInit,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
        addPropertyRooms: builder.mutation({
            queryFn: async ({
                propertyFk,
                rooms,
                isFromSignUp = false,
            }, {
                dispatch,
            }) => {
                try {
                    const myInit = {
                        body: rooms,
                    };

                    const response = await API.post(
                        "UserPropertyAPI",
                        `/addRooms/${propertyFk}/${isFromSignUp}`,
                        myInit,
                    );

                    return {
                        data: response,
                    };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
    }),
});

export const {
    useAddToIoTPropertyReferenceMutation,
    useAddPropertyRoomsMutation,
} = propertyAPI;
