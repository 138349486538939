import { Save } from "@mui/icons-material";
import { TextField, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { useDispatch } from "react-redux";
import { UpdateRelativeTimeCondition } from "../../api/IoT/conditions";
import { useAddRelativeTimeConditionMutation, useUpdateRelativeTimeConditionMutation } from "../../features/auth/conditionsAPI";
import { showLoader } from "../../features/auth/authSlice";

const PCoRSetUp = forwardRef(({
    relTimeCond,
    callbackIsFormValid,
    callbackSaveSuccess }, ref) => {
    const [timeCondition, setTimeCondition] = useState({
        conditionId: relTimeCond.timeConditionId,
        TimeValue: relTimeCond.timeValue,
        relativeTime: relTimeCond.relativeTime,
        relative: "",
        checkin: "",
        RuleId: relTimeCond.ruleId,
        isActive: relTimeCond.isActive,
        BookingAlertLimit: relTimeCond.bookingAlertLimit,
        MinuteAlertLimit: relTimeCond.minuteAlertLimit,
        MinuteAlertTimeInterval: relTimeCond.minuteAlertTimeInterval,
    });
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const dispatch = useDispatch();

    const [updateRelativeTimeCondition, {
        isLoading: isLoadingUpdateRelativeTimeCondition,
        isSuccess: isSuccessUpdateRelativeTimeCondition,
        isError: isErrorUpdateRelativeTimeCondition,
    }] = useUpdateRelativeTimeConditionMutation();

    useEffect(() => {
        if (relTimeCond !== undefined) {
            setTimeCondition({
                ...timeCondition,
                conditionId: relTimeCond.timeConditionId,
                TimeValue: relTimeCond.timeValue,
                relativeTime: relTimeCond.relativeTime,
                relative: "",
                checkin: "",
                RuleId: relTimeCond.ruleId,
                isActive: relTimeCond.isActive,
                BookingAlertLimit: relTimeCond.bookingAlertLimit,
                MinuteAlertLimit: relTimeCond.minuteAlertLimit,
                MinuteAlertTimeInterval: relTimeCond.minuteAlertTimeInterval,
            });
            callbackIsFormValid(true);
        }
    }, [relTimeCond]);

    useEffect(() => {
        if (isLoadingUpdateRelativeTimeCondition) {
            dispatch(
                showLoader({
                    open: true,
                    message: "Setting up Post Check-Out Routine. Please wait...",
                }),
            );
        }

        if (isSuccessUpdateRelativeTimeCondition) {
            dispatch(
                showLoader({
                    open: false,
                    message: "",
                }),
            );
            if (callbackSaveSuccess !== undefined) callbackSaveSuccess(true);
        }

        if (isErrorUpdateRelativeTimeCondition) {
            dispatch(
                showLoader({
                    open: false,
                    message: "",
                }),
            );
            if (callbackSaveSuccess !== undefined) callbackSaveSuccess(false);
        }
    }, [isLoadingUpdateRelativeTimeCondition,
        isSuccessUpdateRelativeTimeCondition,
        isErrorUpdateRelativeTimeCondition,
    ]);

    function numberOnly(id) {
        const element = document.getElementById(id);
        element.value = element.value.replace(/[^0-9]/gi, "");
    }
    const handleTimeChange = (e) => {
        if (callbackIsFormValid !== undefined) {
            callbackIsFormValid(e.target.value.length > 0);
        }
        setTimeCondition({
            ...timeCondition,
            TimeValue: e.target.value,
        });
    };

    const EditConditionHandler = async () => {
        // setLoadingUpdate(true);
        // await UpdateRelativeTimeCondition(timeCondition.conditionId, timeCondition);
        updateRelativeTimeCondition({
            id: timeCondition.conditionId,
            condition: timeCondition,
        });
        // callbackSaveSuccess(setLoadingUpdate);
    };

    const CancelConditionHandler = async () => {
        // setLoadingUpdate(true);
        if (callbackSaveSuccess !== undefined) callbackSaveSuccess(true);
    };

    // for sign up
    const proceedSetup = () => {
        if (timeCondition.TimeValue === "" || timeCondition.TimeValue === 30) {
            CancelConditionHandler();
        } else {
            EditConditionHandler();
        }
    };

    useImperativeHandle(ref, () => ({
        proceedSetup,
    }));

    return (
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <TextField
                variant="outlined"
                value={timeCondition.TimeValue}
                label="Minutes"
                onInput={(e) => numberOnly(e.target.id)}
                inputProps={{
                    maxLength: 4,
                }}
                onChange={handleTimeChange}
                sx={{ width: "100px", mr: 2 }}
            />
            {/* <LoadingButton
                variant="contained"
                loading={loadingUpdate}
                loadingPosition="center"
                onClick={(e) => {
                    if (timeCondition.TimeValue === "" || timeCondition.TimeValue === 30) {
                        CancelConditionHandler(e);
                    } else {
                        EditConditionHandler(e);
                    }
                }}
            >
                <Typography variant="button">Proceed</Typography>
            </LoadingButton> */}
        </Box>

    );
});

export default PCoRSetUp;
