import { Add, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Checkbox,
    IconButton,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetDeviceTypesQuery } from "../../features/auth/devicesAPI";
import { StyledTableCell, StyledTableHeadCell, StyleTableContainer } from "../helper/StyleTable";
import { DOORSENSOR_DEVICETYPEID, LEAKSENSOR_DEVICETYPEID, MOTIONSENSOR_DEVICETYPEID, THERMOSTAT_DEVICETYPEID } from "./constants/DeviceTypes";

function ProxyDevices({
    enableBtns,
    setThDev,
    setMtnDev,
    setLkDev,
    setDoorDev,
    checkBasicSensors,
    sensorsConfirmBtn,
    disableBtn,
    savingProg,
    cancelProg,
    showLoader,
}) {
    const [showCheckinDoorCol, setShowCheckinDoorCol] = useState(false);
    const [devicesArr, setDevices] = useState([]);
    const [devicesId, setDevicesId] = useState(devicesArr.length);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const convertToProperCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const addToDevices = (displayName, typeId, ischeckinoutdoor, externalId) => {
        const tempDev = {
            DevicesId: devicesId + 1,
            Name: deviceTypes.filter((t) => t.deviceTypeId === typeId)[0].name,
            DisplayName: displayName,
            DeviceTypeId: typeId,
            type: deviceTypes.filter((t) => t.deviceTypeId === typeId)[0].name,
            DeviceId: externalId,
            FloorNumber: 1,
            isCheckInOutDoor: ischeckinoutdoor,
        };
        setDevices((x) => [...x, tempDev]);
        setDevicesId(devicesId + 1);
    };

    const { data: dataGetDeviceTypes } = useGetDeviceTypesQuery();

    function numberOnly(id) {
        const element = document.getElementById(id);
        element.value = element.value.replace(/[^0-9]/gi, "");
    }

    const removeFromDevices = (id) => {
        const tempDevicesArr = devicesArr.filter((d) => d.DevicesId !== id);
        setDevices(tempDevicesArr);
        if (tempDevicesArr.filter((d) => d.DeviceTypeId === DOORSENSOR_DEVICETYPEID).length === 0) {
            setShowCheckinDoorCol(false);
        }
    };

    const areDevicesValid = (arr) => arr.every((i) => i.DisplayName !== "" && i.FloorNumber !== "");

    const submitDevices = () => {
        sensorsConfirmBtn();
    };

    useEffect(() => { // Check if device list is valid
        setDisableConfirmBtn(!areDevicesValid(devicesArr));
    }, [devicesArr]);

    useEffect(() => {
        if (dataGetDeviceTypes !== undefined) setDeviceTypes(dataGetDeviceTypes);
    }, [dataGetDeviceTypes]);

    useEffect(() => {
        enableBtns();
        setDoorDev(devicesArr.filter((x) => x.DeviceTypeId === DOORSENSOR_DEVICETYPEID));
        setLkDev(devicesArr.filter((x) => x.DeviceTypeId === LEAKSENSOR_DEVICETYPEID));
        setMtnDev(devicesArr.filter((x) => x.DeviceTypeId === MOTIONSENSOR_DEVICETYPEID));
        setThDev(devicesArr.filter((x) => x.DeviceTypeId === THERMOSTAT_DEVICETYPEID));
    }, [devicesArr]);

    const checkInDoorHandler = (typeId) => {
        if (typeId === DOORSENSOR_DEVICETYPEID) {
            setShowCheckinDoorCol(true);
        } else if (
            devicesArr.filter((dev) => dev.DeviceTypeId === DOORSENSOR_DEVICETYPEID).length === 1
        ) {
            setShowCheckinDoorCol(false);
        }
    };

    const [disableConfirmBtn, setDisableConfirmBtn] = useState(true);

    return (
        <>
            <StyleTableContainer sx={{ maxHeight: 300 }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableHeadCell colSpan={showCheckinDoorCol ? 4 : 3}>
                                <Typography variant="h6">IFTTT Devices</Typography>
                            </StyledTableHeadCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableHeadCell width={70} />
                            <StyledTableHeadCell>Name</StyledTableHeadCell>
                            <StyledTableHeadCell width={186} align="center">Type</StyledTableHeadCell>
                            {/* <StyledTableHeadCell align="center">Floor Number</StyledTableHeadCell> */}
                            {showCheckinDoorCol && (
                                <StyledTableHeadCell width={190}>Used as Main Entry/Exit</StyledTableHeadCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!showLoader && deviceTypes.length > 0 ? (
                            <>
                                {devicesArr.length > 0 && devicesArr.map((d) => (
                                    <TableRow key={d.DevicesId}>
                                        <TableCell style={{ verticalAlign: "top" }}>
                                            <IconButton
                                                color="error"
                                                onClick={() => {
                                                    removeFromDevices(d.DevicesId);
                                                    if (
                                                        devicesArr.filter(
                                                            (dev) => dev.DeviceTypeId === DOORSENSOR_DEVICETYPEID,
                                                        ).length === 0
                                                    ) {
                                                        setShowCheckinDoorCol(false);
                                                    }
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ verticalAlign: "top" }}>
                                            <TextField
                                                error={d.DisplayName.length === 0}
                                                helperText="Name must be filled out.*"
                                                size="small"
                                                fullWidth
                                                value={d.DisplayName}
                                                onChange={(e) => {
                                                    setDevices(
                                                        devicesArr.map((dev) => (dev.DevicesId === d.DevicesId
                                                            ? {
                                                                ...dev,
                                                                DisplayName: e.target.value,
                                                            }
                                                            : dev)),
                                                    );
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ verticalAlign: "top" }}>
                                            <Select
                                                // disabled={disableControls}
                                                size="small"
                                                fullWidth
                                                value={d.DeviceTypeId}
                                                onChange={(e) => {
                                                    const filterType = deviceTypes.filter(
                                                        (t) => t.deviceTypeId === e.target.value,
                                                    )[0].name;
                                                    setDevices(
                                                        devicesArr.map((dev) => (dev.DevicesId === d.DevicesId
                                                            ? {
                                                                ...dev,
                                                                DeviceTypeId: e.target.value,
                                                                type: filterType,
                                                                Name: filterType,
                                                            }
                                                            : dev)),
                                                    );
                                                    checkInDoorHandler(e.target.value);
                                                }}
                                            >
                                                {deviceTypes.map((t) => (
                                                    <MenuItem key={t.deviceTypeId} value={t.deviceTypeId}>
                                                        {/* {convertToProperCase(t.name)} */}
                                                        {t.displayName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </TableCell>
                                        {/* <TableCell align="center" style={{ verticalAlign: "top" }}>
                                            <TextField
                                                style={{ width: "64px" }}
                                                value={d.FloorNumber}
                                                type="number"
                                                variant="outlined"
                                                error={
                                                    Number.isNaN(d.FloorNumber) || d.FloorNumber === ""
                                                }
                                                onInput={(e) => numberOnly(e.target.id)}
                                                inputProps={{
                                                    maxLength: 4,
                                                }}
                                                onChange={(e) => {
                                                    setDisableConfirmBtn(false);
                                                    const temp = devicesArr.map((dev) => (dev.DevicesId === d.DevicesId
                                                        ? {
                                                            ...dev,
                                                            FloorNumber: e.target.value,
                                                        }
                                                        : dev));
                                                    setDevices(temp);
                                                    // }
                                                    if (e.target.value === "") {
                                                        setDisableConfirmBtn(true);
                                                    }
                                                }}
                                            />
                                        </TableCell> */}
                                        {d.DeviceTypeId === 1 && (
                                            <TableCell align="center" style={{ verticalAlign: "top" }}>
                                                {/* <Checkbox
                                                    checked={d.isCheckInOutDoor}
                                                    onChange={(e) => {
                                                        setDevices(
                                                            devicesArr.map((dev) => (dev.DevicesId === d.DevicesId
                                                                ? {
                                                                    ...dev,
                                                                    isCheckInOutDoor: e.target.checked,
                                                                }
                                                                : dev)),
                                                        );
                                                    }}
                                                /> */}
                                                <Switch
                                                    checked={d.isCheckInOutDoor}
                                                    onChange={(e) => {
                                                        setDevices(
                                                            devicesArr.map((dev) => (dev.DevicesId === d.DevicesId
                                                                ? {
                                                                    ...dev,
                                                                    isCheckInOutDoor: e.target.checked,
                                                                }
                                                                : dev)),
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                addToDevices("", 1, true, "");
                                                checkInDoorHandler(1);
                                            }}
                                        >
                                            <Add />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell />
                                    <TableCell />
                                    {showCheckinDoorCol && <TableCell />}
                                </TableRow>
                            </>
                        ) : (
                            <TableRow>
                                <StyledTableCell colSpan={6}>
                                    <Skeleton variant="rounded" height={30} width="inherit">
                                        Loading...Please Wait
                                    </Skeleton>
                                </StyledTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyleTableContainer>
            <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                sx={{ marginTop: 3 }}
            >
                <LoadingButton
                    loading={cancelProg}
                    variant="outlined"
                    color="close"
                    disabled={savingProg}
                    onClick={() => {
                        checkBasicSensors();
                    }}
                >
                    Cancel
                </LoadingButton>
                <LoadingButton
                    loading={savingProg}
                    disabled={
                        disableBtn
                        || cancelProg
                        || devicesArr.length === 0
                        || disableConfirmBtn
                    }
                    onClick={() => {
                        submitDevices();
                    }}
                    variant="contained"
                >
                    Confirm
                </LoadingButton>
            </Stack>
        </>
    );
}

export default ProxyDevices;
