import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, Skeleton, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { duration } from "moment";
import { useAddDeviceConditionMutation, useAddGroupConditionMutation, useAddRelativeTimeConditionMutation, useDeleteDeviceConditionMutation, useDeleteGroupConditionMutation, useDeleteRelativeTimeConditionMutation, useGetConditionsByRuleQuery } from "../../features/auth/conditionsAPI";
import { useGetDevicesAndStatesByPropertyIdQuery } from "../../features/auth/devicesAPI";
import { useAddRuleGroupMutation, useGetRuleGroupQuery, useGetRulesWithoutGroupConditionsQuery } from "../../features/auth/rulesAPI";
import CustomTimeField from "../helper/CustomTimeField";
import { ALERT_RULE_GROUP_CUSTOM, ALERT_RULE_GROUP_EXISTING } from "./Conditions";

const StyledAlertGroupSub = styled(List)(({ theme }) => ({
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    ".Mui-selected": {
        color: theme.palette.primary.main,
        backgroundColor: `${theme.palette.common.white}!important`,
    },
    ul: {
        listStyleType: "disc",
    },
}));

const StyledWeekdayToggleButtons = styled(ToggleButtonGroup)(({ theme }) => ({
    ".MuiToggleButton-root": {
        borderColor: theme.palette.background.default,
        "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
}));

export const INSTANT_DURATION = "is immediately";
export const CONTINUOUS_DURATION = "stays";
export default function AddConditions({
    ruleId,
    propertyId,
    onClick,
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [isBookingLimitEnabled, setisBookingLimitEnabled] = useState(false);
    const [isMinuteLimitEnabled, setisMinuteLimitEnabled] = useState(false);

    const [timeConditions, setTimeConditions] = useState([]);

    // ruleConditionId, ruleId, deviceId, deviceStateId, isActive
    const [showAddCondition, setShowAddCondition] = useState(false);

    // add device condition
    const [devicesStates, setDevicesStates] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState();
    const [selectedDeviceStateId, setSelectedDeviceStateId] = useState();
    const [selectedStatesList, setSelectedStatesList] = useState([]);

    // Add Custom Condition
    const [bookingAlertLimit, setBookingAlertLimit] = useState(-1);
    const [minuteAlertLimit, setMinuteAlertLimit] = useState(-1);
    const [minuteAlertInterval, setMinuteAlertInterval] = useState(0);
    const [specificTimeValue, setSpecificTimeValue] = useState();

    const durationOptions = [
        {
            id: 1,
            value: INSTANT_DURATION,
        },
        {
            id: 2,
            value: CONTINUOUS_DURATION,
        },
    ];

    const [openDeviceState, setOpenDeviceState] = useState(true);
    const handleCloseDeviceState = () => {
        setOpenDeviceState(false);
    };
    const handleOpenDeviceState = () => {
        setOpenDeviceState(true);
    };

    const [durationMinutes, setDurationMinutes] = useState(5);

    const [deviceConditionDurationType, setDeviceConditionDurationType] = useState(INSTANT_DURATION);

    const [beforeAfterState, setBeforeAfterState] = useState(true);
    const [conditionType, setConditionType] = useState("Device");
    const [days, setDays] = useState(() => []);

    const [timeCondition, setTimeCondition] = useState(
        {
            TimeValue: 0,
            relativeTime: 1,
            relative: "",
            checkin: "",
            RuleId: ruleId,
            isActive: true,
            BookingAlertLimit: bookingAlertLimit,
            MinuteAlertLimit: minuteAlertLimit,
            MinuteAlertTimeInterval: minuteAlertInterval,
            SpecificTime: specificTimeValue,
            RecurringDays: days,
            TimeSet: "",
        },
    );
    const [selectedRuleGroup, setSelectedRuleGroup] = useState([]);
    const [checked, setChecked] = useState(false);
    const [multiselectRuleGroup, setMultiselectRuleGroup] = useState([]);
    const [showCustomRuleGroupButton, setShowCustomRuleGroupButton] = useState(false);
    const [showCustomRuleGroup, setShowCustomRuleGroup] = useState(false);

    const [customRuleGroup, setCustomRuleGroup] = useState({
        name: "",
        isActive: true,
        PropertyId: propertyId,
    });

    const [groupedRules, setGroupedRules] = useState([]);

    const [timeText, setTimeText] = useState("");
    const [timeConditionFormVisible, setTimeConditionFormVisible] = useState(false);
    const [specificTimeFormVisible, setSpecificTimeFormVisible] = useState(false);
    const [selectedTimeConditionType, setSelectedTimeConditionType] = useState("");
    const [selectedAlertGroupSub, setSelectedAlertGroupSub] = useState(ALERT_RULE_GROUP_EXISTING);

    const [timeSet, setTimeSet] = useState(null);

    const numberOnly = (id) => {
        const element = document.getElementById(id);
        element.value = element.value.replace(/[^0-9]/gi, "");
    };

    const timeTextNotification = () => {
        if (timeCondition.TimeValue === 0
            || timeCondition.relative.length === 0
            || timeCondition.checkin.length === 0
        ) return <>&nbsp;</>;

        return (
            <Alert
                severity="info"
                variant="outlined"
            >
                <Typography variant="caption">
                    {"Notify me only "}
                    <b>{timeCondition.TimeValue}</b>
                    {" minute(s) "}
                    <b>{timeCondition.relative.toLowerCase()}</b>
                    {" "}
                    <b>{timeCondition.checkin.toLowerCase()}</b>
                    {" if an alert is activated."}
                    minutes
                </Typography>
            </Alert>

        );
    };

    // << rkt query
    const {
        data: dataGetConditionsByRule,
        isLoading: isLoadingGetConditionsByRule,
        isFetching: isFetchingGetConditionsByRule,
    } = useGetConditionsByRuleQuery({
        ruleId,
    });
    const {
        data: dataGetRulesWithoutGroup,
        isLoading: isLoadingRulesWithoutGroup,
        isFetching: isFetchingulesWithoutGroup,
    } = useGetRulesWithoutGroupConditionsQuery({
        propertyId,
    });
    const {
        data: dataDevicesAndStates,
        isloading: isLoadingDevicesAndStates,
        isFetching: isFetchingDevicesAndStates,
    } = useGetDevicesAndStatesByPropertyIdQuery({
        propertyId,
    });
    const {
        data: dataRuleGroup,
        isLoading: isLoadingGetRuleGroup,
        isFetching: isFetchingGetRuleGroup,
    } = useGetRuleGroupQuery({
        propertyId,
    });
    const isLoadingData = isLoadingRulesWithoutGroup || isFetchingulesWithoutGroup
        || isLoadingDevicesAndStates || isFetchingDevicesAndStates
        || isLoadingGetRuleGroup || isFetchingGetRuleGroup;

    const [
        addRelativeTimeCondition, {
            isLoading: isLoadingAddRelativeTimeCondition,
            isFetching: isFetchingAddRelativeTimeCondition,
        }] = useAddRelativeTimeConditionMutation({
        fixedCacheKey: `add-relative-time-condition-${ruleId}`,
    });
    const [
        addDeviceCondtion, {
            isLoading: isLoadingAddDeviceCondtion,
            isFetching: isFetchingAddDeviceCondtion,
        }] = useAddDeviceConditionMutation({
        fixedCacheKey: `add-device-condition-${ruleId}`,
    });
    const [
        addGroupCondition,
        {
            isLoading: isLoadingAddGroupCondition,
            isFetching: isFetchingAddGroupCondition,
        }] = useAddGroupConditionMutation({
        fixedCacheKey: `add-group-condition-${ruleId}`,
    });
    const [
        addRuleGroup, {
            isLoading: isLoadingAddRuleGroup,
            isFetching: isFetchingAddRuleGroup,
        }] = useAddRuleGroupMutation();

    const isAddingCondition = isLoadingAddRelativeTimeCondition || isFetchingAddRelativeTimeCondition
        || isLoadingAddDeviceCondtion || isFetchingAddDeviceCondtion
        || isLoadingAddRuleGroup || isFetchingAddRuleGroup
        || isLoadingAddGroupCondition || isFetchingAddGroupCondition
        || isLoadingGetConditionsByRule || isFetchingGetConditionsByRule;

    useEffect(() => {
        if (dataDevicesAndStates !== undefined) {
            const defaultValue = {
                device: {
                    deviceId: 0,
                    displayName: <em>Select a device</em>,
                },
                states: [
                    {
                        deviceStateId: 0,
                        name: "Select a device state",
                    },
                ],
            };
            setDevicesStates([defaultValue, ...dataDevicesAndStates]);
            if (dataDevicesAndStates.length > 0) {
                setSelectedDeviceId(defaultValue.device.deviceId);
                setSelectedStatesList(defaultValue.states);
                setSelectedDeviceStateId(defaultValue.states[0].deviceStateId);
            }
        }
    }, [dataDevicesAndStates]);
    useEffect(() => {
        if (dataGetConditionsByRule !== undefined) {
            setTimeConditions(dataGetConditionsByRule.relativeTimeConditions);
        }
    }, [dataGetConditionsByRule]);
    // >> rkt query

    const onClickAddCondition = async () => {
        setShowCustomRuleGroup(false);
        setShowCustomRuleGroupButton(false);
        setCustomRuleGroup({
            name: "",
            isActive: true,
            PropertyId: propertyId,
        });
        setConditionType("Device");
        setMultiselectRuleGroup([]);
        setGroupedRules([]);
        setTimeCondition({
            TimeValue: 30,
            relativeTime: 1,
            relative: "",
            checkin: "",
            RuleId: ruleId,
            isActive: true,
            BookingAlertLimit: bookingAlertLimit,
            MinuteAlertLimit: minuteAlertLimit,
            MinuteAlertTimeInterval: minuteAlertInterval,
        });
        setShowAddCondition(true);
    };

    const conditionTypeHandleChange = (event) => {
        setMultiselectRuleGroup([]);
        setShowCustomRuleGroup(false);
        setShowCustomRuleGroupButton(false);
        setGroupedRules([]);
        setTimeCondition({
            TimeValue: 30,
            relativeTime: 1,
            relative: "",
            checkin: "",
            RuleId: ruleId,
            isActive: true,
            BookingAlertLimit: bookingAlertLimit,
            MinuteAlertCount: 0,
            MinuteAlertLimit: minuteAlertLimit,
            MinuteAlertTimeInterval: minuteAlertInterval,
            BookingAlertCount: 0,
            RecurringDays: [],
            TimeSet: "",
        });
        setConditionType(event.target.value);
        if (event.target.value === "Rule Group") {
            setShowCustomRuleGroupButton(true);
        } else {
            setShowCustomRuleGroup(false);
            setShowCustomRuleGroupButton(false);
        }
    };

    const updateTimeText = () => {
        if (timeCondition.relative && timeCondition.checkin && timeCondition.TimeValue) {
            setTimeText(`Notify me only ${timeCondition.TimeValue} minutes ${timeCondition.relative.toLowerCase()} ${timeCondition.checkin.toLowerCase()} if an alert is activated.`);
        } else {
            setTimeText("");
        }
    };

    const handleTimeChange = (e) => {
        setTimeCondition({
            ...timeCondition,
            TimeValue: e.target.value,
        });
        updateTimeText();
    };

    const handleRelativeChange = (e) => {
        setTimeCondition({
            ...timeCondition,
            relative: e.target.value,
        });
        updateTimeText();
    };

    const handleCheckinChange = (e) => {
        setTimeCondition({
            ...timeCondition,
            checkin: e.target.value,
        });
        updateTimeText();
    };

    const handleDays = (event, newFormats) => {
        setDays(newFormats);
    };

    const handleMinuteLimitState = (event) => {
        setisMinuteLimitEnabled(event.target.checked);
        setMinuteAlertLimit(-1);
        setMinuteAlertInterval(-1);
    };

    const setMinuteAlertLimitHandler = (limitNum) => {
        setMinuteAlertLimit(limitNum);
    };

    const handleBookingLimitState = (event) => {
        setisBookingLimitEnabled(event.target.checked);
        setBookingAlertLimit(-1);
    };

    const setBookingAlertLimitHandler = (limitNum) => {
        setBookingAlertLimit(limitNum);
    };

    const ruleGroupHandleChange = (event) => {
        // console.log(event);
        if (event.target.checked) {
            // console.log(JSON.parse(event.target.value));
            setSelectedRuleGroup(JSON.parse(event.target.value).deviceRules);

            // console.log([...multiselectRuleGroup, JSON.parse(event.target.value)]);
            setMultiselectRuleGroup([...multiselectRuleGroup, JSON.parse(event.target.value)]);
            setChecked(true);
        } else {
            // console.log(multiselectRuleGroup.filter((x) => x.ruleGroupId !== JSON.parse(event.target.value).ruleGroupId));
            setMultiselectRuleGroup(multiselectRuleGroup.filter((x) => x.ruleGroupId !== JSON.parse(event.target.value).ruleGroupId));

            setChecked(false);
        }
    };

    const groupedRulesHandleChange = (event) => {
        if (event.target.checked) {
            // console.log([...groupedRules, Number(event.target.value)]);
            setGroupedRules([...groupedRules, Number(event.target.value)]);
        } else {
            // console.log(groupedRules.filter((gr) => gr !== Number(event.target.value)));
            setGroupedRules(groupedRules.filter((gr) => gr !== Number(event.target.value)));
        }
    };

    // same in Conditions.js
    const ConvertToRelativeTime = (relativeCheckIn, t) => {
        const timeConditionObject = t;
        // eslint-disable-next-line max-len
        setTimeCondition({ ...timeCondition, BookingAlertLimit: bookingAlertLimit, MinuteAlertLimit: minuteAlertLimit, MinuteAlertTimeInterval: minuteAlertInterval, days, timeSet });
        timeCondition.BookingAlertLimit = bookingAlertLimit;
        timeCondition.MinuteAlertLimit = minuteAlertLimit;
        timeCondition.MinuteAlertTimeInterval = minuteAlertInterval;
        timeCondition.RecurringDays = days;
        timeCondition.TimeSet = timeSet;
        switch (relativeCheckIn) {
            case "AfterCheckIn":
                setTimeCondition({ ...timeCondition, relativeTime: 0 });
                timeConditionObject.relativeTime = 0;
                break;
            case "AfterCheckOut":
                setTimeCondition({ ...timeCondition, relativeTime: 1 });
                timeConditionObject.relativeTime = 1;
                break;
            case "InBetweenBookings":
                setTimeCondition({ ...timeCondition, relativeTime: 2 });
                timeConditionObject.relativeTime = 2;
                break;
            case "BeforeCheckIn":
                setTimeCondition({ ...timeCondition, relativeTime: 3 });
                timeConditionObject.relativeTime = 3;
                break;
            case "BeforeCheckOut":
                setTimeCondition({ ...timeCondition, relativeTime: 4 });
                timeConditionObject.relativeTime = 4;
                break;
            case "DuringBookings":
                setTimeCondition({ ...timeCondition, relativeTime: 5 });
                timeConditionObject.relativeTime = 5;
                break;
            case "SpecificTime":
                console.log("correct");
                setTimeCondition({ ...timeCondition, relativeTime: 6 });
                timeConditionObject.relativeTime = 6;
                break;
            default:
                setTimeCondition({ ...timeCondition, relativeTime: 1 });
                timeConditionObject.relativeTime = 1;
        }
        return timeConditionObject;
    };

    const AddConditionHandler = async () => {
        if (conditionType === "Time") {
            const timeConditionObject = ConvertToRelativeTime(timeCondition.relative + timeCondition.checkin, timeCondition, days, timeSet);
            // console.log("timeCondition: ", timeCondition);
            // console.log("timeConditionObject: ", timeConditionObject);
            // await AddRelativeTimeCondition(timeConditionObject);
            await addRelativeTimeCondition({
                condition: timeConditionObject,
            });
        } else if (conditionType === "Device") {
            const deviceConditionObject = {
                RuleId: ruleId,
                Deviceid: selectedDeviceId,
                DeviceStateId: selectedDeviceStateId,
                isActive: true,
                DeviceStateUnitReferenceId: 3,
                Duration: deviceConditionDurationType === CONTINUOUS_DURATION ? durationMinutes : 0,
            };
            // await AddDeviceCondition(deviceConditionObject);
            await addDeviceCondtion({
                condition: deviceConditionObject,
            });
        } else if (conditionType === "Rule Group" && !showCustomRuleGroup) {
            // console.log("rule groups: ", multiselectRuleGroup);
            // await AddGroupCondition(ruleId, multiselectRuleGroup);
            await addGroupCondition({
                RuleId: ruleId,
                RuleGroups: multiselectRuleGroup,
            });
        } else if (conditionType === "Rule Group" && showCustomRuleGroup) {
            // console.log("Rule Group: ", customRuleGroup);
            // console.log("Grouped Rules: ", groupedRules);
            // const newRuleGroup = await AddRuleGroup(customRuleGroup, groupedRules);
            const newRuleGroup = await addRuleGroup({
                RuleGroup: customRuleGroup,
                GroupedRuleIds: groupedRules,
            });
            await addGroupCondition({
                RuleId: ruleId,
                RuleGroups: [newRuleGroup.data.ruleGroup],
            });
        }
        // setHasRefreshResponse(true);
        setDefaultValues();
        setShowAddCondition(false);
    };

    const TimeTypeOnChangeHandler = (type) => {
        setSelectedTimeConditionType(type);
        switch (type) {
            case "AfterBefore":
                setTimeCondition({ ...timeCondition, relative: type, checkin: "CheckOut" });
                setBeforeAfterState(false);
                setTimeConditionFormVisible(true);
                setSpecificTimeFormVisible(false);
                break;
            case "InBetweenBookings":
                setTimeCondition({
                    ...timeCondition,
                    relative: type,
                    checkin: "",
                    TimeValue: 0,
                });
                setBeforeAfterState(true);
                setTimeConditionFormVisible(false);
                setSpecificTimeFormVisible(false);

                break;
            case "DuringBookings":
                setTimeCondition({
                    ...timeCondition,
                    relative: type,
                    checkin: "",
                    TimeValue: 0,
                });
                setBeforeAfterState(true);
                setTimeConditionFormVisible(false);
                setSpecificTimeFormVisible(false);
                break;
            case "SpecificTime":
                setTimeCondition({
                    ...timeCondition,
                    relative: type,
                });
                console.log(timeCondition);
                setTimeConditionFormVisible(false);
                setSpecificTimeFormVisible(true);
                setBeforeAfterState(true);
                break;
            default:
                setTimeCondition({ ...timeCondition, relative: type });
                setBeforeAfterState(false);
                setSpecificTimeFormVisible(false);
                setTimeConditionFormVisible(false);
                break;
        }
    };

    const setDefaultValues = () => {
        setMinuteAlertLimit(-1);
        setBookingAlertLimit(-1);
        setMinuteAlertInterval(0);
        setisMinuteLimitEnabled(false);
        setisBookingLimitEnabled(false);
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    if (onClick !== undefined) onClick();
                    onClickAddCondition();
                }}
                fullWidth={isMobile}
                sx={{
                    marginLeft: {
                        xs: 0,
                        md: theme.spacing(7),
                    },
                }}
            >
                <Add />
                {" "}
                Add Condition
            </Button>
            <Dialog
                open={showAddCondition}
                maxWidth={false}
                fullScreen={isMobile}
                sx={!isMobile ? {
                    "& .MuiDialog-paper": {
                        width: "600px", // Ensure custom width is respected
                    },
                } : {
                    "& .MuiDialog-paper": {
                        width: "100%",
                        height: "100%",
                        margin: 0,
                        maxWidth: "100%",
                        maxHeight: "100%",
                    },
                }}
            >
                <DialogTitle>Add Condition</DialogTitle>
                <DialogContent>
                    {!isLoadingData && devicesStates.length > 0 && (
                        <Grid container spacing={2} mt={0}>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="condition-type">Condition Type</InputLabel>
                                    <Select
                                        label="Condition Type"
                                        labelId="condition-type"
                                        value={conditionType}
                                        onChange={conditionTypeHandleChange}
                                    >
                                        <MenuItem disabled={isAddingCondition} value="Device">Device</MenuItem>
                                        <MenuItem disabled={isAddingCondition || timeConditions.length > 0} value="Time">Time and Bookings</MenuItem>
                                        <MenuItem disabled={isAddingCondition} value="Rule Group">Alert Group</MenuItem>
                                    </Select>
                                    {showCustomRuleGroupButton && (
                                        <StyledAlertGroupSub
                                            dense
                                            disablePadding
                                        >
                                            <ListItemButton
                                                selected={selectedAlertGroupSub === ALERT_RULE_GROUP_EXISTING}
                                                onClick={() => {
                                                    setShowCustomRuleGroup(false);
                                                    setSelectedAlertGroupSub(ALERT_RULE_GROUP_EXISTING);
                                                }}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    <ul><li>Choose from existing alert groups</li></ul>
                                                </Typography>
                                            </ListItemButton>
                                            <ListItemButton
                                                selected={selectedAlertGroupSub === ALERT_RULE_GROUP_CUSTOM}
                                                onClick={() => {
                                                    setShowCustomRuleGroup(true);
                                                    setSelectedAlertGroupSub(ALERT_RULE_GROUP_CUSTOM);
                                                }}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    <ul><li>Create custom alert group</li></ul>
                                                </Typography>
                                            </ListItemButton>
                                        </StyledAlertGroupSub>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {conditionType === "Device" && (
                                    <Grid container>
                                        <span style={{ marginRight: "16px" }}>If</span>
                                        <Grid item>
                                            <FormControl
                                                size="small"
                                                fullWidth
                                            >
                                                {/* <InputLabel variant="standard" id="devices-select">Select a device</InputLabel> */}
                                                <Select
                                                    variant="standard"
                                                    labelId="devices-select"
                                                    label="Device"
                                                    disabled={isAddingCondition}
                                                    value={selectedDeviceId}
                                                    onChange={(e) => {
                                                        setSelectedDeviceId(e.target.value);
                                                        const filtered = devicesStates.filter((x) => x.device.deviceId === e.target.value)[0].states;
                                                        setSelectedStatesList(filtered);
                                                        setSelectedDeviceStateId(filtered[0].deviceStateId);
                                                    }}
                                                >
                                                    {devicesStates.map((d) => (
                                                        <MenuItem key={d.device.deviceId} value={d.device.deviceId}>{d.device.displayName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {selectedDeviceId > 0 && (
                                            <Grid container spacing={1} sx={{ marginTop: "8px" }}>
                                                <span style={{ marginRight: "16px", color: "white" }}>If</span>
                                                <Grid item>
                                                    {selectedStatesList != null && (
                                                        <FormControl
                                                            size="small"
                                                            fullWidth
                                                        >
                                                            {/* <InputLabel id="states-select">State</InputLabel> */}
                                                            <Select
                                                                variant="standard"
                                                                labelId="duration-select"
                                                                label="Duration"
                                                                disabled={isAddingCondition}
                                                                fullWidth
                                                                value={deviceConditionDurationType}
                                                                onChange={(e) => {
                                                                    setDeviceConditionDurationType(e.target.value);
                                                                }}
                                                            >
                                                                {durationOptions.map((d) => (
                                                                    <MenuItem key={d.id} value={d.value}>{d.value}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                </Grid>
                                                {selectedDeviceId > 0 && (
                                                    <Grid item>
                                                        {selectedStatesList != null && (
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                {/* <InputLabel id="states-select">State</InputLabel> */}
                                                                <Select
                                                                    variant="standard"
                                                                    labelId="states-select"
                                                                    label="State"
                                                                    disabled={isAddingCondition}
                                                                    fullWidth
                                                                    value={selectedDeviceStateId}
                                                                    onChange={(e) => {
                                                                        setSelectedDeviceStateId(e.target.value);
                                                                    }}
                                                                    open={openDeviceState}
                                                                    onOpen={handleOpenDeviceState}
                                                                    onClose={handleCloseDeviceState}
                                                                >
                                                                    {selectedStatesList.map((s) => (
                                                                        <MenuItem
                                                                            key={s.deviceStateId}
                                                                            value={s.deviceStateId}
                                                                        >
                                                                            {s.displayName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    </Grid>
                                                )}
                                                {selectedDeviceId > 0 && deviceConditionDurationType === CONTINUOUS_DURATION
                                                && selectedDeviceStateId != null && (
                                                    <>
                                                        <Grid item>
                                                            for approximately
                                                        </Grid>
                                                        <Grid item xs={2} sx={{ marginRight: "1px" }}>
                                                            {selectedStatesList != null && (
                                                                <TextField
                                                                    id="filled-number"
                                                                    type="number"
                                                                    variant="standard"
                                                                    disabled={isAddingCondition}
                                                                    value={durationMinutes}
                                                                    onChange={(e) => {
                                                                        setDurationMinutes(e.target.value);
                                                                    }}
                                                                    slotProps={{
                                                                        inputLabel: {
                                                                            shrink: true,
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item>
                                                            minutes
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                )}

                                {conditionType === "Time" && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                fullWidth
                                                size="small"
                                            >
                                                <InputLabel id="time-condition-label">Time Condition Type</InputLabel>
                                                <Select
                                                    labelId="time-condition-label"
                                                    id="time-condition-helper"
                                                    label="Time Condition Type"
                                                    disabled={isAddingCondition}
                                                    onChange={(e) => TimeTypeOnChangeHandler(e.target.value)}
                                                    value={selectedTimeConditionType}
                                                >
                                                    <MenuItem value="AfterBefore">Before/After</MenuItem>
                                                    <MenuItem value="InBetweenBookings">In Between</MenuItem>
                                                    <MenuItem value="DuringBookings">Currently Booked</MenuItem>
                                                    <MenuItem value="SpecificTime">Time of Day</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {timeConditionFormVisible && (
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Stack
                                                            direction="row"
                                                            spacing={1}
                                                        >
                                                            <TextField
                                                                variant="outlined"
                                                                // fullWidth
                                                                disabled={beforeAfterState}
                                                                value={timeCondition.TimeValue}
                                                                label="Minutes"
                                                                onInput={(e) => numberOnly(e.target.id)}
                                                                inputProps={{
                                                                    maxLength: 4,
                                                                }}
                                                                onChange={handleTimeChange}
                                                            />
                                                            <FormControl
                                                                fullWidth
                                                                size="small"
                                                            >
                                                                <InputLabel>Before/After</InputLabel>
                                                                <Select
                                                                    labelId="before-after-label"
                                                                    id="before-after-helper"
                                                                    label="Before/After"
                                                                    disabled={beforeAfterState}
                                                                    value={timeCondition.relative}
                                                                    fullWidth
                                                                    onChange={handleRelativeChange}
                                                                >
                                                                    <MenuItem value="Before">Before</MenuItem>
                                                                    <MenuItem value="After">After</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            <FormControl
                                                                fullWidth
                                                                size="small"
                                                            >
                                                                <InputLabel id="checkinout-label">Checkin/Checkout</InputLabel>
                                                                <Select
                                                                    labelId="checkinout-label"
                                                                    id="checkinout-helper"
                                                                    label="Checkin/Checkout"
                                                                    fullWidth
                                                                    disabled={beforeAfterState}
                                                                    value={timeCondition.checkin}
                                                                    onChange={handleCheckinChange}
                                                                >
                                                                    <MenuItem value="CheckIn">Check-in</MenuItem>
                                                                    <MenuItem value="CheckOut">Check-out</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {timeTextNotification()}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {specificTimeFormVisible && (
                                            <Grid item xs={12}>
                                                <Stack
                                                    spacing={1}
                                                    direction={{
                                                        xs: "column",
                                                        md: "row",
                                                    }}
                                                >
                                                    <CustomTimeField
                                                        format="hh:mm A"
                                                        value={timeSet}
                                                        onChange={(newValue) => setTimeSet(newValue)}
                                                    />
                                                    <StyledWeekdayToggleButtons
                                                        value={days}
                                                        onChange={handleDays}
                                                        color="primary"
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        <ToggleButton value="SUN">S</ToggleButton>
                                                        <ToggleButton value="MON">M</ToggleButton>
                                                        <ToggleButton value="TUE">T</ToggleButton>
                                                        <ToggleButton value="WED">W</ToggleButton>
                                                        <ToggleButton value="THU">T</ToggleButton>
                                                        <ToggleButton value="FRI">F</ToggleButton>
                                                        <ToggleButton value="SAT">S</ToggleButton>
                                                    </StyledWeekdayToggleButtons>
                                                </Stack>
                                            </Grid>
                                        )}
                                        {selectedTimeConditionType !== "" && (
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    spacing={{
                                                        xs: 1,
                                                        md: 2,
                                                    }}
                                                >
                                                    <Grid item xs={12} md={6}>
                                                        <FormControlLabel
                                                            control={(
                                                                <Switch
                                                                    checked={isMinuteLimitEnabled}
                                                                    onChange={handleMinuteLimitState}
                                                                    color="primary"
                                                                />
                                                            )}
                                                            label="Limit alerts per minute?"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        {isMinuteLimitEnabled && (
                                                            <Stack
                                                                direction="row"
                                                                spacing={1}
                                                                sx={{
                                                                    paddingLeft: isMobile ? theme.spacing(7) : 0,
                                                                }}
                                                            >
                                                                <TextField
                                                                    label="Maximum Alerts"
                                                                    variant="outlined"
                                                                    disabled={!isMinuteLimitEnabled}
                                                                    value={timeCondition.minuteLimit}
                                                                    onInput={(e) => numberOnly(e.target.id)}
                                                                    inputProps={{
                                                                        maxLength: 4,
                                                                    }}
                                                                    onChange={(e) => setMinuteAlertLimitHandler(e.target.value)}
                                                                    size="small"
                                                                />
                                                                <TextField
                                                                    label="Minutes"
                                                                    variant="outlined"
                                                                    disabled={!isMinuteLimitEnabled}
                                                                    value={timeCondition.minuteInterval}
                                                                    onInput={(e) => numberOnly(e.target.id)}
                                                                    inputProps={{
                                                                        maxLength: 4,
                                                                    }}
                                                                    onChange={(e) => setMinuteAlertInterval(e.target.value)}
                                                                    size="small"
                                                                />
                                                            </Stack>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControlLabel
                                                            control={(
                                                                <Switch
                                                                    checked={isBookingLimitEnabled}
                                                                    onChange={handleBookingLimitState}
                                                                    color="primary"
                                                                />
                                                            )}
                                                            label="Limit alerts per booking?"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        {isBookingLimitEnabled && (
                                                            <Box
                                                                sx={{
                                                                    paddingLeft: isMobile ? theme.spacing(7) : 0,
                                                                }}
                                                            >
                                                                <TextField
                                                                    label="Maximum Alerts"
                                                                    variant="outlined"
                                                                    disabled={!isBookingLimitEnabled}
                                                                    value={timeCondition.bookingLimit}
                                                                    onInput={(e) => numberOnly(e.target.id)}
                                                                    size="small"
                                                                    inputProps={{
                                                                        maxLength: 4,
                                                                    }}
                                                                    onChange={(e) => setBookingAlertLimitHandler(e.target.value)}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}

                                {conditionType === "Rule Group" && !showCustomRuleGroup && (
                                    <Grid container spacing={1}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <FormLabel id="ruleGroup-select">
                                                Alert Groups
                                            </FormLabel>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ maxHeight: "30vh", overflow: "auto" }}
                                            >
                                                <FormGroup
                                                    onChange={
                                                        ruleGroupHandleChange
                                                    }
                                                >
                                                    {dataRuleGroup.length > 0 && !isLoadingData && dataRuleGroup.map((rg) => (
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    key={rg.ruleGroupId}
                                                                    disabled={rg.deviceRules.length === 0 || isAddingCondition}
                                                                    value={JSON.stringify(rg)}
                                                                    size="small"
                                                                />
                                                            )}
                                                            label={rg.name}
                                                            key={rg.ruleGroupId}
                                                        />
                                                    ))}
                                                    {dataRuleGroup.length === 0 && !isLoadingData && (
                                                        <Alert severity="error">
                                                            No existing rule groups.
                                                        </Alert>
                                                    )}

                                                    {isLoadingData && <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>}

                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel id="enclosed-rules">
                                                Summary:
                                            </FormLabel>
                                            <List
                                                sx={{
                                                    listStyleType: "disc",
                                                    listStylePosition: "inside",
                                                }}
                                            >
                                                {/* {selectedRuleGroup.length > 0 && checked && selectedRuleGroup.map((dr) => (
                                                    <ListItem sx={{ display: "list-item" }}>
                                                        {dr.name}
                                                    </ListItem>
                                                ))} */}
                                                {multiselectRuleGroup.length > 0 && multiselectRuleGroup.map((mr, i) => (
                                                    mr.deviceRules.length > 0 && mr.deviceRules.map((dr) => (
                                                        <Fragment key={dr.ruleId + 101}>
                                                            {i === multiselectRuleGroup.length - 1 && (
                                                                <ListItem
                                                                    sx={{ display: "list-item", fontWeight: "bold" }}
                                                                    key={dr.ruleId}
                                                                >
                                                                    {dr.name}
                                                                </ListItem>
                                                                // <span>last</span>
                                                            )}
                                                            {i !== multiselectRuleGroup.length - 1 && (
                                                                <ListItem
                                                                    sx={{ display: "list-item" }}
                                                                    key={dr.ruleId}
                                                                >
                                                                    {dr.name}
                                                                </ListItem>
                                                                // <span>not last</span>
                                                            )}
                                                        </Fragment>
                                                    ))
                                                ))}
                                                {selectedRuleGroup.length === 0 && checked && (
                                                    <Alert severity="error">No enclosed rules.</Alert>
                                                )}
                                                {/* {!checked && (
                                                    <>Choose a rule group.</>
                                                )} */}
                                                {multiselectRuleGroup.length === 0 && !checked && (
                                                    <>Select one or more alert groups.</>
                                                )}
                                            </List>
                                        </Grid>
                                        {" "}

                                    </Grid>
                                )}

                                {conditionType === "Rule Group" && showCustomRuleGroup && (
                                    <Grid container spacing={1}>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ maxHeight: "30vh", overflow: "auto" }}
                                        >
                                            <InputLabel id="time-select">Custom Alert Group</InputLabel>
                                            <TextField
                                                margin="normal"
                                                error={customRuleGroup.name.length === 0}
                                                helperText="Name must be filled out."
                                                placeholder="Enter alert group name."
                                                size="small"
                                                fullWidth
                                                disabled={isAddingCondition}
                                                label="Alert Group Name:"
                                                value={customRuleGroup.name}
                                                onChange={(e) => setCustomRuleGroup({ ...customRuleGroup, name: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <FormLabel style={{ position: "sticky" }} id="rule-select">
                                                Alerts
                                            </FormLabel>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ maxHeight: "30vh", overflow: "auto" }}
                                            >
                                                <FormGroup
                                                    onChange={groupedRulesHandleChange}
                                                >
                                                    {dataGetRulesWithoutGroup !== undefined
                                                        && dataGetRulesWithoutGroup.length > 0
                                                        && showCustomRuleGroup
                                                        && !isLoadingData
                                                        && dataGetRulesWithoutGroup.map((r) => (
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        disabled={isAddingCondition}
                                                                        value={r.ruleId}
                                                                    />
                                                                )}
                                                                label={r.name}
                                                                key={r.ruleGroupId}
                                                            />
                                                        ))}

                                                    {dataGetRulesWithoutGroup !== undefined
                                                        && dataGetRulesWithoutGroup.length === 0 && !isLoadingData && (
                                                        <Alert severity="error">
                                                            No existing alerts.
                                                        </Alert>
                                                    )}

                                                    {isLoadingData && <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>}
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                        </Grid>
                    )}
                    {!isLoadingData && devicesStates.length === 0 && (
                        <Alert color="error">At least one device is needed to create a condition.</Alert>
                    )}

                    {isLoadingData && (
                        <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        disabled={isAddingCondition}
                        color="info"
                        onClick={() => setShowAddCondition(false)}
                    >
                        Cancel
                    </Button>
                    {devicesStates.length > 0 && (
                        <LoadingButton
                            variant="contained"
                            loading={isAddingCondition}
                            loadingPosition="end"
                            endIcon={<Add />}
                            onClick={(e) => AddConditionHandler(e)}
                        >
                            <span>Add</span>
                        </LoadingButton>
                    )}
                    {devicesStates.length === 0 && (
                        <LoadingButton
                            variant="contained"
                            disabled
                            // loading={isAddingCondition}
                            loadingPosition="end"
                            endIcon={<Add />}
                            onClick={(e) => AddConditionHandler(e)}
                        >
                            <span>Add</span>
                        </LoadingButton>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}
