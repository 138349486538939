import React, { useEffect, useState } from "react";
import {
    Checkbox,
    IconButton,
    MenuItem,
    Select,
    Skeleton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { StyledTableCell, StyleTableContainer } from "../helper/StyleTable";
// import { getAllDeviceTypes } from "../../api/IoT/types";
import { useGetDeviceTypesQuery } from "../../features/auth/devicesAPI";

function ProxyDevice({ setDevData, devData, setName, showLoader, floorNumber, setFloorNumber }) {
    const {
        data: dataGetDeviceTypes,
        isLoading: isLoadingGetDeviceTypes,
        isFetching: isFetchingGetDeviceTypes,
    } = useGetDeviceTypesQuery();
    // const [deviceTypes, setDeviceTypes] = useState([]);
    const convertToProperCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    const isGettingDevicesTypes = isLoadingGetDeviceTypes || isFetchingGetDeviceTypes;

    return (
        <StyleTableContainer>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledTableCell colSpan={devData.type === "doorSensor" ? 4 : 3}>
                            <Typography variant="h6">IFTTT Devices</Typography>
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell align="center" width={165}>Type</StyledTableCell>
                        {devData.type === "doorSensor" && (
                            <StyledTableCell align="center" width={120}>Used as Main Entry/Exit</StyledTableCell>
                        )}
                        {/* <TableCell>Floor No.</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {showLoader && (
                        <TableRow>
                            <StyledTableCell
                                colSpan={devData.type === "doorSensor" ? 5 : 4}
                            >
                                <Skeleton variant="rounded" height={30} width="inherit">
                                    Loading...Please Wait
                                </Skeleton>
                            </StyledTableCell>
                        </TableRow>
                    )}
                    {!showLoader && (
                        <TableRow>
                            <TableCell sx={{ verticalAlign: "top" }}>
                                <TextField
                                    sx={{
                                        margin: 0,
                                    }}
                                    className="ifttt-name"
                                    margin="normal"
                                    // error={customRuleGroup.name.length === 0}
                                    // helperText="Name must be filled out."
                                    // placeholder="Enter alert group name."
                                    size="small"
                                    fullWidth
                                    // disabled={disableControls}
                                    // label="Device name:"
                                    // value={currentDevice.DisplayName}
                                    value={devData.DisplayName}
                                    helperText="Name must be filled out. *"
                                    error={devData.DisplayName === ""}
                                    onChange={(e) => {
                                        // setCurrentDevice({ ...currentDevice, DisplayName: e.target.value });
                                        setName(e.target.value);
                                        setDevData({ ...devData, DisplayName: e.target.value });
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={{ verticalAlign: "top" }}>
                                {isGettingDevicesTypes && (
                                    <Skeleton
                                        width="100%"
                                        height={70}
                                    />
                                )}
                                {dataGetDeviceTypes !== undefined && dataGetDeviceTypes.length > 0 && (
                                    <Select
                                        // disabled={disableControls}
                                        size="small"
                                        fullWidth
                                        // value={currentDevice.DeviceTypeId}
                                        value={devData.type}
                                        onChange={(e) => {
                                            // setCurrentDevice({ ...currentDevice, DeviceTypeId: e.target.value });
                                            // checkInDoorHandler(e.target.value);
                                            // setTypeId(e.target.value);
                                            setDevData({
                                                ...devData,
                                                type: e.target.value,
                                                Name: e.target.value,
                                            });
                                        }}
                                    >
                                        {dataGetDeviceTypes !== undefined && dataGetDeviceTypes.map((t) => (
                                            <MenuItem key={t.deviceTypeId} value={t.name}>
                                                {/* {convertToProperCase(t.name)} */}
                                                {t.displayName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </TableCell>
                            {devData.type === "doorSensor" && (
                                <TableCell align="center" sx={{ verticalAlign: "top" }}>
                                    {/* <Checkbox
                                        checked={devData.isCheckInOutDoor}
                                        onChange={(e) => {
                                            setDevData({
                                                ...devData,
                                                isCheckInOutDoor: e.target.checked,
                                            });
                                        }}
                                    /> */}
                                    <Switch
                                        checked={devData.isCheckInOutDoor}
                                        onChange={(e) => {
                                            setDevData({
                                                ...devData,
                                                isCheckInOutDoor: e.target.checked,
                                            });
                                        }}
                                    />
                                </TableCell>
                            )}
                            {/* <TableCell>
                                <TextField
                                    className="txtFloorNumber"
                                    // label="Floor Number"
                                    type="number"
                                    error={floorNumber.length === 0}
                                    // helperText="Floor Number must be filled out"
                                    onChange={(e) => {
                                        setFloorNumber(e.target.value);
                                    }}
                                    fullWidth
                                    size="small"
                                    value={floorNumber}
                                // disabled={loading}
                                />
                            </TableCell> */}
                        </TableRow>
                    )}
                    {/* <TableRow>
                        <TextField
                            className="txtFloorNumber"
                            label="Floor Number"
                            type="number"
                            error={floorNumber.length === 0}
                            helperText="Floor Number must be filled out"
                            onChange={(e) => {
                                setFloorNumber(e.target.value);
                            }}
                            fullWidth
                            size="small"
                            // disabled={loading}
                        />
                    </TableRow> */}
                </TableBody>
            </Table>
        </StyleTableContainer>
    );
}

export default ProxyDevice;
