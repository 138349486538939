/* eslint-disable indent */
import { faDoorClosed, faPersonWalkingLuggage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Grid, Skeleton, Stack, Switch, Table, TableBody, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CheckBasicSensors } from "../../api/IoT/devices";
import { useAddDevicesMutation, useAddUpdatePropertyDeviceStateMutation, useDeletePropertyDeviceStateMutation, useGetDevicePartnersQuery } from "../../features/auth/devicesAPI";
import { StyleTableContainer, StyledTableCell, StyledTableHeadCell, StyledTableRow } from "../helper/StyleTable";
import ProxyDevices from "./ProxyDevices";
import { DOORSENSOR_DEVICETYPEID } from "./constants/DeviceTypes";
import * as PARTNERS from "./constants/Partners";

function DeviceNameInput({
    disabled,
    value,
    onChange,
}) {
    return (
        <TextField
            size="small"
            fullWidth
            disabled={disabled}
            value={value}
            onChange={onChange}
        />
    );
}

function SelectMultipleDevices(
    { paramAuthCode, paramPartnerId, paramPropertyId, closeDlgCallback, callbackRequiredHandler, propertyId, iftttPartnerAccountId },
) {
    const dispatch = useDispatch();
    const [partnerAccId, setpartnerAccountId] = useState(null);
    const [DoorDevFromAPI, setDoorDeviceFromAPI] = useState([]);
    const [MtnSensorDevFromAPI, setMtnSensorDeviceFromAPI] = useState([]);
    const [ThermostatDevFromAPI, setThermostatDeviceFromAPI] = useState([]);
    const [LeakSensorDevFromAPI, setLeakSensorDeviceFromAPI] = useState([]);
    const [DoorLockDevFromAPI, setDoorLockDeviceFromAPI] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [infoMsg, setInfoMsg] = useState("");
    const [showGetDevicesLoader, setShowGetDevicesLoader] = useState(false);
    const [disableConfirmBtn, setDisableConfirmBtn] = useState(false);
    const [disableCancelBtn, setDisableCancelBtn] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showSavingProgress, setShowSavingProgress] = useState(false);
    const [showCancelProgress, setShowCancelProgress] = useState(false);
    const [
        addDevices,
        {
            data: addedDevices,
            error: errorAddDevices,
            isError: isErrorAddDevices,
            isSuccess: isSuccessAddDevices,
        },
    ] = useAddDevicesMutation();
    const [
        addUpdatePropertyDeviceState,
        {
            data: addedUpdatePropertyDeviceState,
            error: errorAddedUpdatePropertyDeviceState,
            isError: isErrorAddedUpdatePropertyDeviceState,
            isSuccess: isSuccessAddedUpdatePropertyDeviceState,
        },
    ] = useAddUpdatePropertyDeviceStateMutation();
    const [
        deletePropertyDeviceState,
        {
            data: deletedPropertyDeviceState,
            message: addedDeletedPropertyDeviceState,
            error: errorDeletedPropertyDeviceState,
            isError: isErrorDeletedPropertyDeviceState,
            isSuccess: isSuccessDeletedPropertyDeviceState,
        },
    ] = useDeletePropertyDeviceStateMutation();

    const [pollingGetDevicePartners, setPollingGetDevicePartners] = useState(3000);
    const [counterGetDevicePartners, setCounterGetDevicePartners] = useState(0);
    const [paramCode, setParamCode] = useState(paramAuthCode || "");
    const {
        data: dataGetDevicePartners,
        isSuccess: isSuccessGetDevicePartners,
        isError: isErrorGetDevicePartners,
        isFetching: isFetchingGetDevicePartners,
        isLoading: isLoadingGetDevicePartners,
    } = useGetDevicePartnersQuery({
        apiBody: {
            body: {
                code: paramCode,
                propertyId: paramPropertyId,
                partnerId: paramPartnerId,
            },
        },
    }, {
        skip: iftttPartnerAccountId !== 0,
        pollingInterval: pollingGetDevicePartners,
    });

    const isGettingDevicePartners = isFetchingGetDevicePartners || isLoadingGetDevicePartners;

    // if get device partners is success, set pollingGetDevicePartners to zero (0)
    // else continue polling for 3 seconds
    useEffect(() => {
        if (isSuccessGetDevicePartners) {
            setPollingGetDevicePartners(0);
        }
    }, [isSuccessGetDevicePartners]);

    // if error getting device partners, increase counterGetDevicePartners
    useEffect(() => {
        if (isErrorGetDevicePartners) {
            setInfoMsg("Retrying...");
            setParamCode("");
            setCounterGetDevicePartners(counterGetDevicePartners + 1);
        }
    }, [isErrorGetDevicePartners]);

    useEffect(() => {
        if (counterGetDevicePartners > 2) {
            setInfoMsg("");
            setErrorMsg("Failed to get devices due to an unexpected error. Please try again later.");
            // setting pollingGetDevicePartners to zero will stop repeate get device partners request
            setPollingGetDevicePartners(0);
        }
    }, [counterGetDevicePartners]);

    useEffect(() => {
        setShowGetDevicesLoader(isGettingDevicePartners);
    }, [isGettingDevicePartners]);

    useEffect(() => {
        setpartnerAccountId(iftttPartnerAccountId);
    }, [iftttPartnerAccountId]);

    useEffect(() => {
        if (dataGetDevicePartners !== undefined) {
            setInfoMsg("");
            setShowGetDevicesLoader(false);
            const getAllDoors = dataGetDevicePartners.Payload.DoorSensors;
            const getAllMotionSensors = dataGetDevicePartners.Payload.MotionSensors;
            const getAllThermostats = dataGetDevicePartners.Payload.Thermostats;
            const getAllLeakSensors = dataGetDevicePartners.Payload.LeakSensors;
            const getAllDoorLocks = dataGetDevicePartners.Payload.Locks;

            setpartnerAccountId(dataGetDevicePartners.PartnerAccountId);

            if (paramPartnerId === PARTNERS.YOLINK_PARTNERID) {
                const deviceArrDoor = getAllDoors.map((d, i) => {
                    const formattedData = {
                        DeviceId: d.deviceId,
                        type: d.type,
                        DeviceToken: d.token,
                        partnerId: paramPartnerId,
                        PartnerAccountId: dataGetDevicePartners.PartnerAccountId,
                        PropertyId: parseInt(paramPropertyId, 10),
                        // DisplayName: `Entrance ${i + 1}`,
                        DisplayName: `Door Sensor - Sensor Id:${d.deviceId}`,
                        Name: d.name,
                        isSelected: false,
                        FloorNumber: 1,
                        isCheckInOutDoor: false,
                    };

                    return formattedData;
                });
                setDoorDeviceFromAPI(deviceArrDoor);

                const deviceArrMotionSens = getAllMotionSensors.map((d) => {
                    const formattedData = {
                        DeviceId: d.deviceId,
                        type: d.type,
                        DeviceToken: d.token,
                        partnerId: paramPartnerId,
                        PartnerAccountId: dataGetDevicePartners.PartnerAccountId,
                        PropertyId: parseInt(paramPropertyId, 10),
                        DisplayName: `${d.name} - ${d.deviceId}`,
                        Name: d.name,
                        FloorNumber: 1,
                        isSelected: false,
                    };
                    return formattedData;
                });
                setMtnSensorDeviceFromAPI(deviceArrMotionSens);

                const deviceArrLeakSens = getAllLeakSensors.map((d) => {
                    const formattedData = {
                        DeviceId: d.deviceId,
                        type: d.type,
                        DeviceToken: d.token,
                        partnerId: paramPartnerId,
                        PartnerAccountId: dataGetDevicePartners.PartnerAccountId,
                        PropertyId: parseInt(paramPropertyId, 10),
                        DisplayName: `${d.name} - ${d.deviceId}`,
                        Name: d.name,
                        FloorNumber: 1,
                        isSelected: false,
                    };
                    return formattedData;
                });
                setLeakSensorDeviceFromAPI(deviceArrLeakSens);

                const deviceArrDoorLocks = getAllDoorLocks.map((d) => {
                  const formattedData = {
                    DeviceId: d.deviceId,
                    type: d.type,
                    DeviceToken: d.token,
                    partnerId: paramPartnerId,
                    PartnerAccountId: dataGetDevicePartners.PartnerAccountId,
                    PropertyId: parseInt(paramPropertyId, 10),
                    DisplayName: `${d.name} - ${d.deviceId}`,
                    Name: d.name,
                    FloorNumber: 1,
                    isSelected: false,
                  };
                  return formattedData;
                });
                setDoorLockDeviceFromAPI(deviceArrDoorLocks);
            }
            if (paramPartnerId === PARTNERS.YOLINK_PARTNERID || paramPartnerId === PARTNERS.SENSIBO_PARTNERID) {
                const deviceThermostats = getAllThermostats.map((d) => {
                    const formattedData = {
                        DeviceId: d.deviceId,
                        type: d.type,
                        DeviceToken: d.token,
                        partnerId: paramPartnerId,
                        PartnerAccountId: dataGetDevicePartners.PartnerAccountId,
                        PropertyId: parseInt(paramPropertyId, 10),
                        DisplayName: `${d.name} - ${d.deviceId}`,
                        Name: d.name,
                        FloorNumber: 1,
                        isSelected: false,
                    };
                    return formattedData;
                });
                setThermostatDeviceFromAPI(deviceThermostats);
            }
            setShowGetDevicesLoader(false);
            if (dataGetDevicePartners.Payload.Others.length > 0) {
                setDisableConfirmBtn(false);
            }
        }
    }, [dataGetDevicePartners]);

    function numberOnly(id) {
        const element = document.getElementById(id);
        element.value = element.value.replace(/[^0-9]/gi, "");
    }

    const GetDevicesToSubmit = () => {
        setErrorMsg("");
        const devicesToSubmit = [];

        let getDoorSelected = [];
        let getMotionSelected = [];
        let getThermostatSelected = [];
        let getLeakSelected = [];
        let getDoorLockSelected = [];

        if (paramPartnerId !== PARTNERS.IFTTT_PARTNERID) {
            getDoorSelected = DoorDevFromAPI.filter((x) => x.isSelected === true);
            getMotionSelected = MtnSensorDevFromAPI.filter(
                (x) => x.isSelected === true,
            );
            getLeakSelected = LeakSensorDevFromAPI.filter(
                (x) => x.isSelected === true,
            );
            getThermostatSelected = ThermostatDevFromAPI.filter(
                (x) => x.isSelected === true,
            );
            getDoorLockSelected = DoorLockDevFromAPI.filter(
              (x) => x.isSelected === true,
            );
        } else {
            getDoorSelected = DoorDevFromAPI;
            getMotionSelected = MtnSensorDevFromAPI;
            getThermostatSelected = ThermostatDevFromAPI;
            getLeakSelected = LeakSensorDevFromAPI;
            getDoorLockSelected = DoorLockDevFromAPI;
        }

        let hasDoorSensor = false;
        let hasMainDoorSensor = false;
        let hasMotionSensor = false;
        let hasThermostat = false;
        let hasLeakSensor = false;
        let hasDoorLock = false;

        if (getDoorSelected.length > 0) {
            hasDoorSensor = true;
            if (getDoorSelected.find((x) => x.isCheckInOutDoor === true)) {
                hasMainDoorSensor = true;
            }
            getDoorSelected.forEach((d) => {
                const formattedData = {
                    ExternalId: d.DeviceId,
                    type: d.type,
                    DeviceToken: d.DeviceToken,
                    partnerId: paramPartnerId,
                    PartnerAccountId: partnerAccId,
                    PropertyId: paramPropertyId,
                    DisplayName: d.DisplayName,
                    FloorNumber: d.FloorNumber !== null ? d.FloorNumber : 1,
                    Name: d.Name,
                    isCheckInOutDoor: d.isCheckInOutDoor,
                };
                devicesToSubmit.push(formattedData);
            });
        }
        if (getMotionSelected.length > 0) {
            hasMotionSensor = true;
            getMotionSelected.forEach((d) => {
                const formattedData = {
                    ExternalId: d.DeviceId,
                    type: d.type,
                    DeviceToken: d.DeviceToken,
                    partnerId: paramPartnerId,
                    PartnerAccountId: partnerAccId,
                    PropertyId: paramPropertyId,
                    DisplayName: d.DisplayName,
                    FloorNumber: d.FloorNumber !== null ? d.FloorNumber : 1,
                    Name: d.Name,
                };
                devicesToSubmit.push(formattedData);
            });
        }
        if (getLeakSelected.length > 0) {
            hasLeakSensor = true;
            getLeakSelected.forEach((d) => {
                const formattedData = {
                    ExternalId: d.DeviceId,
                    type: d.type,
                    DeviceToken: d.DeviceToken,
                    partnerId: paramPartnerId,
                    PartnerAccountId: partnerAccId,
                    PropertyId: paramPropertyId,
                    DisplayName: d.DisplayName,
                    FloorNumber: d.FloorNumber !== null ? d.FloorNumber : 1,
                    Name: d.Name,
                };
                devicesToSubmit.push(formattedData);
            });
        }

        if (getThermostatSelected.length > 0) {
            hasThermostat = true;
            getThermostatSelected.forEach((d) => {
                const formattedData = {
                    ExternalId: d.DeviceId,
                    type: d.type,
                    DeviceToken: d.DeviceToken,
                    partnerId: paramPartnerId,
                    PartnerAccountId: partnerAccId,
                    PropertyId: paramPropertyId,
                    DisplayName: d.DisplayName,
                    FloorNumber: d.FloorNumber !== null ? d.FloorNumber : 1,
                    Name: d.Name,
                };
                devicesToSubmit.push(formattedData);
            });
        }
        if (getDoorLockSelected.length > 0) {
          hasDoorLock = true;
          getDoorLockSelected.forEach((d) => {
            const formattedData = {
              ExternalId: d.DeviceId,
              type: d.type,
              DeviceToken: d.DeviceToken,
              partnerId: paramPartnerId,
              PartnerAccountId: partnerAccId,
              PropertyId: paramPropertyId,
              DisplayName: d.DisplayName,
              FloorNumber: d.FloorNumber !== null ? d.FloorNumber : 1,
              Name: d.Name,
            };
            devicesToSubmit.push(formattedData);
          });
        }
        return {
          devicesToSubmit,
          hasDoorSensor,
          hasMainDoorSensor,
          hasMotionSensor,
          hasThermostat,
          hasLeakSensor,
          hasDoorLock,
        };
    };

    const CheckBasicSensorsHandler = async (status) => {
        let response = GetDevicesToSubmit();
        if (status === "cancel") {
            setShowCancelProgress(true);
            response = [];
        }

        const checkBasicSensorsResponse = await CheckBasicSensors(
            propertyId,
        );
        if (
            response.hasDoorSensor
            !== checkBasicSensorsResponse.hasDoorSensor
            && !response.hasDoorSensor
        ) {
            response.hasDoorSensor = checkBasicSensorsResponse.hasDoorSensor;
        }
        if (
            response.hasMainDoorSensor
            !== checkBasicSensorsResponse.hasMainDoorSensor
            && !response.hasMainDoorSensor
        ) {
            response.hasMainDoorSensor = checkBasicSensorsResponse.hasMainDoorSensor;
        }
        if (
            response.hasMotionSensor
            !== checkBasicSensorsResponse.hasMotionSensor
            && !response.hasMotionSensor
        ) {
            response.hasMotionSensor = checkBasicSensorsResponse.hasMotionSensor;
        }
        if (
            response.hasThermostat
            !== checkBasicSensorsResponse.hasThermostat
            && !response.hasThermostat
        ) {
            response.hasThermostat = checkBasicSensorsResponse.hasThermostat;
        }
        closeDlgCallback(
            response.hasMainDoorSensor,
            response.hasDoorSensor,
            response.hasMotionSensor,
            response.hasThermostat,
            true,
        );
    };

    const deviceExistingErrorMessage = (deviceMessages) => {
        if (deviceMessages !== undefined) {
            return (
                <>
                    {deviceMessages.map((msg) => (
                        <Typography component="li">{msg}</Typography>
                    ))}
                </>
            );
        }
        return 0;
    };

    useEffect(() => {
        if (
            isSuccessAddedUpdatePropertyDeviceState
            && addedUpdatePropertyDeviceState !== undefined
        ) {
            SubmitDevices();
        }

        if (isErrorAddedUpdatePropertyDeviceState && errorAddedUpdatePropertyDeviceState) {
            setErrorMsg("An unexpected error occured. Please try again later.");
            setShowSavingProgress(false);
            setDisableConfirmBtn(false);
            setDisableCancelBtn(false);
        }
    }, [
        isSuccessAddedUpdatePropertyDeviceState,
        isErrorAddedUpdatePropertyDeviceState,
    ]);

    useEffect(() => {
        if (isSuccessAddDevices && addedDevices !== undefined) {
            (async () => {
                await CheckBasicSensorsHandler();
            })();
        }

        if (isErrorAddDevices && errorAddDevices !== undefined) {
            if (errorAddDevices.data.failedDevices.length > 0) {
                (async () => {
                    await API.get(
                        "UserAPI",
                        "/getSignUpProcessStatus",
                    ).then(async (statusRes) => {
                        API.put(
                            "UserAPI",
                            `/updateSignUpProcessStatus?statusCode=${statusRes}`,
                        )
                            .then(async () => {
                                const hasBasicSensors = await CheckBasicSensors(
                                    propertyId,
                                );
                                callbackRequiredHandler(
                                    "Unable to add the following devices",
                                    deviceExistingErrorMessage(
                                        errorAddDevices.data.displayMessages,
                                    ),
                                    "error",
                                    !(
                                        hasBasicSensors.hasDoorSensor
                                        && hasBasicSensors.hasMotionSensor
                                    ),
                                    "Skip",
                                    "Add other devices",
                                );
                            })
                            .catch((e) => {
                                alert(e);
                            });
                    }).finally(() => {
                        errorAddDevices.data.failedDevices.forEach(
                            async (device) => {
                                await deletePropertyDeviceState({
                                    propertyId,
                                    displayName: device.displayName,
                                }).then(() => {
                                    setDisableCancelBtn(false);
                                    setDisableConfirmBtn(false);
                                    setShowSavingProgress(false);
                                });
                            },
                        );
                    });
                })();
            }
        }
    }, [isSuccessAddDevices, isErrorAddDevices]);

    const SubmitDevices = async () => {
        setDisableConfirmBtn(true);
        setDisableCancelBtn(true);
        setShowSavingProgress(true);

        const response = GetDevicesToSubmit();
        if (response.devicesToSubmit.length === 0) {
            setShowSavingProgress(false);
            setErrorMsg(
                "There was an error in adding your device. Please close this dialog and try again.",
            );
            setDisableConfirmBtn(false);
            return;
        }
        const addDeviceBodyContent = {
            body: {
                partnerAccountId: partnerAccId,
                propertyId: parseInt(paramPropertyId, 10),
                devices: response.devicesToSubmit,
            },
        };
        await addDevices({ devicesArray: addDeviceBodyContent });
    };

    const SensorsConfirmBtn = async () => {
        setDisableConfirmBtn(true);
        setDisableCancelBtn(true);
        setShowSavingProgress(true);

        const response = GetDevicesToSubmit();
        if (response.devicesToSubmit.length === 0) {
            setErrorMsg("There was an error in adding your device. Please close this dialog and try again.");
            setDisableConfirmBtn(false);
            return;
        }
        const addDeviceBodyContent = {
            body: {
                partnerAccountId: partnerAccId,
                propertyId: parseInt(paramPropertyId, 10),
                devices: response.devicesToSubmit,
            },
        };
        // API.post("MultipleDevicesListAPI", "", addDeviceBodyContent)
        await addUpdatePropertyDeviceState({ devicesArray: addDeviceBodyContent.body.devices });
    };

    const updateDoorDeviceArr = (deviceData, isCheckInOutDoorBtnCicked) => {
        const newDeviceData = deviceData;

        if (isCheckInOutDoorBtnCicked) {
            if (newDeviceData.isSelected) {
                newDeviceData.isCheckInOutDoor = !newDeviceData.isCheckInOutDoor;
            } else {
                newDeviceData.isSelected = true;
                newDeviceData.isCheckInOutDoor = true;
            }
        } else if (newDeviceData.isSelected) {
            newDeviceData.isSelected = false;
            newDeviceData.isCheckInOutDoor = false;
        } else {
            newDeviceData.isSelected = true;

            if (DoorDevFromAPI.length === 1) newDeviceData.isCheckInOutDoor = true;
        }

        const newDevArr = DoorDevFromAPI.map((c) => {
            if (c.DeviceId === newDeviceData.DeviceId) {
                return newDeviceData;
            }
            return c;
        });

        setDoorDeviceFromAPI(newDevArr);
    };

    const updateDoorLockArr = (deviceData) => {
      const newDevArrr = DoorLockDevFromAPI.map((c) => {
        if (c.DeviceId === deviceData.DeviceId) {
          const newData = c;
          newData.isSelected = !newData.isSelected;
          return newData;
        }
        return c;
      });
      setDoorLockDeviceFromAPI(newDevArrr);
    };

    const updateMotionDeviceArr = (deviceData) => {
        const newDevArrr = MtnSensorDevFromAPI.map((c) => {
            if (c.DeviceId === deviceData.DeviceId) {
                const newData = c;
                newData.isSelected = !newData.isSelected;
                return newData;
            }
            return c;
        });
        setMtnSensorDeviceFromAPI(newDevArrr);
    };

    const updateLeakDeviceArr = (deviceData) => {
        const newDevArrr = LeakSensorDevFromAPI.map((c) => {
            if (c.DeviceId === deviceData.DeviceId) {
                const newData = c;
                newData.isSelected = !newData.isSelected;
                return newData;
            }
            return c;
        });
        setLeakSensorDeviceFromAPI(newDevArrr);
    };

    const updateThermostatDeviceArr = (deviceData) => {
        const newDevArrr = ThermostatDevFromAPI.map((c) => {
            if (c.DeviceId === deviceData.DeviceId) {
                const newData = c;
                newData.isSelected = !newData.isSelected;
                return newData;
            }
            return c;
        });
        setThermostatDeviceFromAPI(newDevArrr);
    };

    const returnUsedAsIcon = (d) => {
        if (d.isSelected && d.isCheckInOutDoor) return <CheckBox />;
        if (d.isSelected && !d.isCheckInOutDoor) return <CheckBoxOutlineBlank />;
        return <CheckBoxOutlineBlank />;
    };

    const updateSelectedRows = (currentRow) => {
        const updatedRows = currentRow.isSelected
            ? selectedRows.filter((row) => row.DeviceId !== currentRow.DeviceId)
            : [...selectedRows, currentRow];

        setSelectedRows(updatedRows);
        formIsValid(currentRow.DeviceId, currentRow.FloorNumber, updatedRows);
        return updatedRows;
    };

    const formIsValid = (deviceId, floorNumber, currentRows) => {
        const selected = currentRows;
        if (selected.length > 0) {
            const dev = selected.find((x) => x.DeviceId === deviceId);
            if (dev === undefined) {
                setDisableConfirmBtn(false);
            } else {
                dev.FloorNumber = floorNumber;
            }
            const result = selected.filter((x) => x.FloorNumber === "").length !== 0;
            setDisableConfirmBtn(result);
        }
    };

    const defaultColumns = (deviceTypeId) => (
        <>
            <StyledTableCell>
                {/* <TextField size="small" fullwidth variant="standard" disabled /> */}
                &nbsp;
            </StyledTableCell>
            {deviceTypeId === DOORSENSOR_DEVICETYPEID && (
                <StyledTableCell sx={{ textAlign: "center" }}>
                    {/* <TextField size="small" fullwidth variant="standard" disabled /> */}
                    &nbsp;
                </StyledTableCell>
            )}
        </>
    );

    const deviceCodeColumnWidth = "50%";
    const selectColumnWidth = "20%";
    const usedAsMainColumnWidth = "30%";
    const totalColumn = 3;

    const loaderTableRow = (
        <TableRow>
            <StyledTableCell colSpan={totalColumn}>
                <Skeleton
                    variant="rounded"
                    width="100%"
                    height={30}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    Loading...Please Wait
                </Skeleton>
            </StyledTableCell>
        </TableRow>
    );

    // <TextField
    //     disabled={!res.isSelected}
    //     value={res.DisplayName}
    //     size="small"
    //     fullWidth
    //     style={{
    //         backgroundColor: "#fff",
    //     }}
    //     onChange={(e) => {
    //         const _arr = [...DoorDevFromAPI];
    //         const arrData = _arr.find((x) => x.DeviceId === res.DeviceId);
    //         arrData.DisplayName = e.target.value;
    //         setDoorDeviceFromAPI(_arr);
    //     }}
    // />

    return (
        <Grid container spacing={2}>
            {errorMsg !== "" && (
                <Grid item xs={12}>
                    <Alert severity="error" onClose={() => setErrorMsg("")}>
                        {errorMsg}
                    </Alert>
                </Grid>
            )}
            {infoMsg !== "" && (
                <Grid item xs={12}>
                    <Alert severity="info" onClose={() => setInfoMsg("")}>
                        {infoMsg}
                    </Alert>
                </Grid>
            )}
            {paramPartnerId !== PARTNERS.IFTTT_PARTNERID && (
                <>
                    {/* <Grid container spacing={2}> */}
                    <Grid item xs={12}>
                        <StyleTableContainer sx={{ maxHeight: 300 }}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableHeadCell
                                            width={deviceCodeColumnWidth}
                                        >
                                            Select sensors for your entrances/exits
                                        </StyledTableHeadCell>
                                        <StyledTableHeadCell
                                            width={selectColumnWidth}
                                            align="center"
                                        >
                                            Select
                                            {/* Floor Number */}
                                        </StyledTableHeadCell>
                                        <StyledTableHeadCell
                                            width={usedAsMainColumnWidth}
                                            align="center"
                                        >
                                            {/* <Stack>
                                                <div>
                                                    Used as Main Entry/Exit
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        size="lg"
                                                        icon={faDoorClosed}
                                                        color="#cceacc"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faPersonWalkingLuggage}
                                                        color="green"
                                                        style={{
                                                            margin: "0px 0px 0px 0px",
                                                        }}
                                                    />
                                                </div>
                                            </Stack> */}
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    Used as Main Entry/Exit
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <span
                                                        style={{ marginRight: "10px" }}
                                                        className="fa-layers"
                                                    >
                                                        <FontAwesomeIcon
                                                            size="lg"
                                                            icon={faDoorClosed}
                                                            color="#cceacc"
                                                            style={{ marginLeft: 5 }}
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={faPersonWalkingLuggage}
                                                            color="green"
                                                            style={{
                                                                margin: "0px 0px 0px 0px",
                                                            }}
                                                        />
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </StyledTableHeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {showGetDevicesLoader && (
                                        loaderTableRow
                                    )}
                                    {DoorDevFromAPI.length > 0 && !showGetDevicesLoader
                                        && DoorDevFromAPI.map((res) => {
                                            // const devandtoken = `Door Sensor - Door Id :${res.DeviceId}`;
                                            if (
                                                res.type !== "MotionSensor"
                                                && res.type !== "LeakSensor"
                                                && res.type !== "Lock"
                                            ) {
                                                return (
                                                    <StyledTableRow
                                                        key={res.DeviceId}
                                                        isSelected={res.isSelected}
                                                    >
                                                        <StyledTableCell
                                                            colSpan={1}
                                                        >
                                                            <DeviceNameInput
                                                                disabled={!res.isSelected}
                                                                value={res.DisplayName}
                                                                onChange={(e) => {
                                                                    const _arr = [...DoorDevFromAPI];
                                                                    const arrData = _arr.find((x) => x.DeviceId === res.DeviceId);
                                                                    arrData.DisplayName = e.target.value;
                                                                    setDoorDeviceFromAPI(_arr);
                                                                }}
                                                            />
                                                            {/* <TextField
                                                                disabled={!res.isSelected}
                                                                value={res.DisplayName}
                                                                size="small"
                                                                fullWidth
                                                                style={{
                                                                    backgroundColor: "#fff",
                                                                }}
                                                                onChange={(e) => {
                                                                    const _arr = [...DoorDevFromAPI];
                                                                    const arrData = _arr.find((x) => x.DeviceId === res.DeviceId);
                                                                    arrData.DisplayName = e.target.value;
                                                                    setDoorDeviceFromAPI(_arr);
                                                                }}
                                                            /> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            style={{
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <Switch
                                                                checked={res.isSelected}
                                                                onChange={(e) => {
                                                                    updateSelectedRows(
                                                                        res,
                                                                    );
                                                                    updateDoorDeviceArr(
                                                                        res,
                                                                        false,
                                                                    );
                                                                }}
                                                            />
                                                            {/* temporarily hide floor number field */}
                                                            {/* <TextField
                                                                disabled={
                                                                    showSavingProgress
                                                                }
                                                                value={res.FloorNumber}
                                                                type="number"
                                                                size="small"
                                                                fullwidth
                                                                variant="outlined"
                                                                error={Number.isNaN(res.FloorNumber) || res.FloorNumber === ""}
                                                                onInput={(e) => numberOnly(
                                                                    e.target.id,
                                                                )}
                                                                inputProps={{
                                                                    maxLength: 4,
                                                                }}
                                                                onChange={(e) => {
                                                                    formIsValid(res.DeviceId, e.target.value, selectedRows);
                                                                    setDoorDeviceFromAPI(
                                                                        DoorDevFromAPI.map((d) => (d.DeviceId === res.DeviceId
                                                                            ? {
                                                                                ...d,
                                                                                FloorNumber: e.target.value,
                                                                            }
                                                                            : d)),
                                                                    );
                                                                }}
                                                            /> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ margin: 0, textAlign: "center" }}>
                                                            <Switch
                                                                disabled={!res.isSelected}
                                                                checked={res.isCheckInOutDoor}
                                                                onChange={() => {
                                                                    updateDoorDeviceArr(
                                                                        res,
                                                                        true,
                                                                    );
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            }
                                            return null;
                                        })}
                                    {DoorDevFromAPI.length === 0 && !showGetDevicesLoader && (
                                        <TableRow>
                                            <StyledTableCell colSpan={totalColumn}>
                                                No door sensors found.
                                            </StyledTableCell>
                                            {/* {defaultColumns(DOORSENSOR_DEVICETYPEID)} */}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </StyleTableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <StyleTableContainer sx={{ maxHeight: 200 }}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableHeadCell width={deviceCodeColumnWidth}>
                                            Select your door locks
                                        </StyledTableHeadCell>
                                        <StyledTableHeadCell width={selectColumnWidth} />
                                        <StyledTableHeadCell width={usedAsMainColumnWidth} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {showGetDevicesLoader && (
                                        loaderTableRow
                                    )}
                                    {DoorLockDevFromAPI.length > 0 && !showGetDevicesLoader
                                        && DoorLockDevFromAPI.map((res) => {
                                            // const devandtoken = `Motion Sensor - Sensor Id :${res.DeviceId}`;
                                            if (
                                                res.type !== "DoorSensor"
                                                && res.type !== "THSensor"
                                                && res.type !== "SmartRemoter"
                                                && res.type !== "LeakSensor"
                                                && res.type !== "MotionSensor"
                                            ) {
                                                return (
                                                    <StyledTableRow
                                                        key={res.DeviceId}
                                                        isSelected={res.isSelected}
                                                    >
                                                        <StyledTableCell>
                                                            <DeviceNameInput
                                                                disabled={!res.isSelected}
                                                                value={res.DisplayName}
                                                                onChange={(e) => {
                                                                    const _arr = [...DoorLockDevFromAPI];
                                                                    const arrData = _arr.find((x) => x.DeviceId === res.DeviceId);
                                                                    arrData.DisplayName = e.target.value;
                                                                    setDoorLockDeviceFromAPI(_arr);
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="center"
                                                        >
                                                            <Switch
                                                                checked={res.isSelected}
                                                                onChange={() => {
                                                                    updateSelectedRows(
                                                                        res,
                                                                    );
                                                                    updateDoorLockArr(
                                                                        res,
                                                                    );
                                                                }}
                                                            />
                                                            {/* <TextField
                                                                disabled={
                                                                    showSavingProgress
                                                                }
                                                                value={res.FloorNumber}
                                                                type="number"
                                                                size="small"
                                                                fullwidth
                                                                variant="outlined"
                                                                error={Number.isNaN(res.FloorNumber) || res.FloorNumber === ""}
                                                                onInput={(e) => numberOnly(
                                                                    e.target.id,
                                                                )}
                                                                inputProps={{
                                                                    maxLength: 4,
                                                                }}
                                                                onChange={(e) => {
                                                                    formIsValid(res.DeviceId, e.target.value, selectedRows);
                                                                    setMtnSensorDeviceFromAPI(
                                                                        MtnSensorDevFromAPI.map((d) => (d.DeviceId === res.DeviceId
                                                                            ? {
                                                                                ...d,
                                                                                FloorNumber: e.target.value,
                                                                            }
                                                                            : d)),
                                                                    );
                                                                }}
                                                            /> */}
                                                        </StyledTableCell>
                                                        {defaultColumns()}
                                                    </StyledTableRow>
                                                );
                                            }
                                            return null;
                                        })}
                                    {DoorLockDevFromAPI.length === 0
                                        && !showGetDevicesLoader && (
                                            <TableRow>
                                                <StyledTableCell colSpan={totalColumn}>
                                                    No door locks found.
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </StyleTableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <StyleTableContainer sx={{ maxHeight: 200 }}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableHeadCell width={deviceCodeColumnWidth}>
                                            Select your motion sensors
                                        </StyledTableHeadCell>
                                        <StyledTableHeadCell width={selectColumnWidth} />
                                        <StyledTableHeadCell width={usedAsMainColumnWidth} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {showGetDevicesLoader && (
                                        loaderTableRow
                                    )}
                                    {MtnSensorDevFromAPI.length > 0 && !showGetDevicesLoader
                                        && MtnSensorDevFromAPI.map((res) => {
                                            // const devandtoken = `Motion Sensor - Sensor Id :${res.DeviceId}`;
                                            if (
                                                res.type !== "DoorSensor"
                                                && res.type !== "THSensor"
                                                && res.type !== "SmartRemoter"
                                                && res.type !== "LeakSensor"
                                            ) {
                                                return (
                                                    <StyledTableRow
                                                        key={res.DeviceId}
                                                        isSelected={res.isSelected}
                                                    >
                                                        <StyledTableCell>
                                                            <DeviceNameInput
                                                                disabled={!res.isSelected}
                                                                value={res.DisplayName}
                                                                onChange={(e) => {
                                                                    const _arr = [...MtnSensorDevFromAPI];
                                                                    const arrData = _arr.find((x) => x.DeviceId === res.DeviceId);
                                                                    arrData.DisplayName = e.target.value;
                                                                    setMtnSensorDeviceFromAPI(_arr);
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="center"
                                                        >
                                                            <Switch
                                                                checked={res.isSelected}
                                                                onChange={() => {
                                                                    updateSelectedRows(
                                                                        res,
                                                                    );
                                                                    updateMotionDeviceArr(
                                                                        res,
                                                                    );
                                                                }}
                                                            />
                                                            {/* <TextField
                                                                disabled={
                                                                    showSavingProgress
                                                                }
                                                                value={res.FloorNumber}
                                                                type="number"
                                                                size="small"
                                                                fullwidth
                                                                variant="outlined"
                                                                error={Number.isNaN(res.FloorNumber) || res.FloorNumber === ""}
                                                                onInput={(e) => numberOnly(
                                                                    e.target.id,
                                                                )}
                                                                inputProps={{
                                                                    maxLength: 4,
                                                                }}
                                                                onChange={(e) => {
                                                                    formIsValid(res.DeviceId, e.target.value, selectedRows);
                                                                    setMtnSensorDeviceFromAPI(
                                                                        MtnSensorDevFromAPI.map((d) => (d.DeviceId === res.DeviceId
                                                                            ? {
                                                                                ...d,
                                                                                FloorNumber: e.target.value,
                                                                            }
                                                                            : d)),
                                                                    );
                                                                }}
                                                            /> */}
                                                        </StyledTableCell>
                                                        {defaultColumns()}
                                                    </StyledTableRow>
                                                );
                                            }
                                            return null;
                                        })}
                                    {MtnSensorDevFromAPI.length === 0
                                        && !showGetDevicesLoader && (
                                            <TableRow>
                                                <StyledTableCell colSpan={totalColumn}>
                                                    No motion sensors found.
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </StyleTableContainer>
                    </Grid>
                    {/* </Grid> */}
                    {/* <Grid container spacing={2}> */}
                    <Grid item xs={12}>
                        <StyleTableContainer sx={{ maxHeight: 200 }}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableHeadCell width={deviceCodeColumnWidth}>
                                            Select your leak sensors
                                        </StyledTableHeadCell>
                                        <StyledTableHeadCell width={selectColumnWidth} />
                                        <StyledTableHeadCell width={usedAsMainColumnWidth} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {showGetDevicesLoader && (
                                        loaderTableRow
                                    )}
                                    {LeakSensorDevFromAPI.length > 0 && !showGetDevicesLoader
                                        && LeakSensorDevFromAPI.map((res) => {
                                            // const devandtoken = `Leak Sensor - Sensor Id :${res.DeviceId}`;
                                            if (
                                                res.type !== "DoorSensor"
                                                && res.type !== "THSensor"
                                                && res.type !== "SmartRemoter"
                                                && res.type !== "MotionSensor"
                                            ) {
                                                return (
                                                    <StyledTableRow
                                                        key={res.DeviceId}
                                                        isSelected={res.isSelected}
                                                    >
                                                        <StyledTableCell colSpan={1}>
                                                            <DeviceNameInput
                                                                disabled={!res.isSelected}
                                                                value={res.DisplayName}
                                                                onChange={(e) => {
                                                                    const _arr = [...LeakSensorDevFromAPI];
                                                                    const arrData = _arr.find((x) => x.DeviceId === res.DeviceId);
                                                                    arrData.DisplayName = e.target.value;
                                                                    setLeakSensorDeviceFromAPI(_arr);
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <Switch
                                                                checked={res.isSelected}
                                                                onChange={() => {
                                                                    updateSelectedRows(res);
                                                                    updateLeakDeviceArr(res);
                                                                }}
                                                            />
                                                            {/* <TextField
                                                                disabled={
                                                                    showSavingProgress
                                                                }
                                                                value={res.FloorNumber}
                                                                type="number"
                                                                size="small"
                                                                fullwidth
                                                                variant="outlined"
                                                                error={Number.isNaN(res.FloorNumber) || res.FloorNumber === ""}
                                                                onInput={(e) => numberOnly(
                                                                    e.target.id,
                                                                )}
                                                                inputProps={{
                                                                    maxLength: 4,
                                                                }}
                                                                onChange={(e) => {
                                                                    formIsValid(res.DeviceId, e.target.value, selectedRows);
                                                                    setLeakSensorDeviceFromAPI(
                                                                        LeakSensorDevFromAPI.map((d) => (d.DeviceId === res.DeviceId
                                                                            ? {
                                                                                ...d,
                                                                                FloorNumber: e.target.value,
                                                                            }
                                                                            : d)),
                                                                    );
                                                                }}
                                                            /> */}
                                                        </StyledTableCell>
                                                        {defaultColumns()}
                                                    </StyledTableRow>
                                                );
                                            }
                                            return null;
                                        })}
                                    {LeakSensorDevFromAPI.length === 0
                                        && !showGetDevicesLoader && (
                                            <TableRow>
                                                <StyledTableCell colSpan={totalColumn}>
                                                    No leak sensors found.
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </StyleTableContainer>
                    </Grid>
                    {/* </Grid> */}
                    {/* <Grid container spacing={2}> */}
                    <Grid item xs={12}>
                        <StyleTableContainer sx={{ maxHeight: 200 }}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableHeadCell width={deviceCodeColumnWidth}>
                                            Select your AC thermostats
                                        </StyledTableHeadCell>
                                        <StyledTableHeadCell width={selectColumnWidth} />
                                        <StyledTableHeadCell width={usedAsMainColumnWidth} align="center" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {showGetDevicesLoader && (
                                        loaderTableRow
                                    )}
                                    {ThermostatDevFromAPI.length > 0 && !showGetDevicesLoader
                                        && ThermostatDevFromAPI.map((res) => {
                                            // const devandtoken = `Thermostat - Sensor Id :${res.DeviceId}`;
                                            if (res.type === "Thermostat") {
                                                return (
                                                    <StyledTableRow
                                                        key={res.DeviceId}
                                                        isSelected={res.isSelected}
                                                    >
                                                        <StyledTableCell>
                                                            <DeviceNameInput
                                                                disabled={!res.isSelected}
                                                                value={res.DisplayName}
                                                                onChange={(e) => {
                                                                    const _arr = [...ThermostatDevFromAPI];
                                                                    const arrData = _arr.find((x) => x.DeviceId === res.DeviceId);
                                                                    arrData.DisplayName = e.target.value;
                                                                    setThermostatDeviceFromAPI(_arr);
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="center"
                                                        >
                                                            <Switch
                                                                checked={res.isSelected}
                                                                onChange={() => {
                                                                    updateSelectedRows(
                                                                        res,
                                                                    );
                                                                    updateThermostatDeviceArr(
                                                                        res,
                                                                    );
                                                                }}
                                                            />
                                                            {/* <TextField
                                                                value={
                                                                    res.FloorNumber
                                                                }
                                                                type="number"
                                                                size="small"
                                                                fullwidth
                                                                variant="outlined"
                                                                error={
                                                                    Number.isNaN(
                                                                        res.FloorNumber,
                                                                    )
                                                                    || res.FloorNumber
                                                                    === ""
                                                                }
                                                                onInput={(e) => numberOnly(
                                                                    e.target.id,
                                                                )}
                                                                inputProps={{
                                                                    maxLength: 4,
                                                                }}
                                                                onChange={(e) => {
                                                                    formIsValid(res.DeviceId, e.target.value, selectedRows);
                                                                    setThermostatDeviceFromAPI(
                                                                        ThermostatDevFromAPI.map(
                                                                            (d) => (d.DeviceId
                                                                                === res.DeviceId
                                                                                ? {
                                                                                    ...d,
                                                                                    FloorNumber:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                                : d),
                                                                        ),
                                                                    );
                                                                }}
                                                            /> */}
                                                        </StyledTableCell>
                                                        {defaultColumns()}
                                                    </StyledTableRow>
                                                );
                                            }
                                            return null;
                                        })}
                                    {ThermostatDevFromAPI.length === 0
                                        && !showGetDevicesLoader && (
                                            <TableRow>
                                                <StyledTableCell>
                                                    No AC thermostats found.
                                                </StyledTableCell>
                                                {defaultColumns()}
                                                {defaultColumns()}
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </StyleTableContainer>
                    </Grid>
                    {/* </Grid> */}
                    {/* <Grid container> */}
                    <Grid item xs={12}>
                        <Alert severity="info" variant="standard">
                            <strong>Note:</strong>
                            {" "}
                            Used as Main Entry/Exit Door Sensors
                            {" "}
                            <span className="fa-layers">
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={faDoorClosed}
                                    color="#cceacc"
                                    style={{ marginLeft: 5 }}
                                />
                                <FontAwesomeIcon
                                    icon={faPersonWalkingLuggage}
                                    color="green"
                                    style={{
                                        margin: "0px 0px 0px 0px",
                                    }}
                                />
                            </span>
                            <span style={{ marginLeft: "10px" }}>
                                are used to notify hosts that their guests have arrived/left.
                                Door sensors can be toggled in/out of this alert group by
                                clicking on their
                            </span>
                            <span className="fa-layers">
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={faDoorClosed}
                                    color="#cceacc"
                                    style={{ marginLeft: 5 }}
                                />
                                <FontAwesomeIcon
                                    icon={faPersonWalkingLuggage}
                                    color="green"
                                    style={{
                                        margin: "0px 0px 0px 0px",
                                    }}
                                />
                            </span>
                            {" "}
                            <span style={{ marginLeft: "10px" }}>icon in Devices.</span>
                            {" "}
                        </Alert>
                    </Grid>
                    {/* </Grid> */}
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                            sx={{ marginTop: 3 }}
                        >
                            <LoadingButton
                                loading={showCancelProgress}
                                variant="outlined"
                                color="close"
                                disabled={disableCancelBtn || showSavingProgress}
                                onClick={() => {
                                    CheckBasicSensorsHandler("cancel");
                                }}
                            >
                                Cancel
                            </LoadingButton>
                            <LoadingButton
                                loading={showSavingProgress}
                                disabled={disableConfirmBtn || showCancelProgress || selectedRows.length === 0}
                                onClick={() => {
                                    SensorsConfirmBtn();
                                }}
                                variant="contained"
                            >
                                Add/Connect
                            </LoadingButton>
                        </Stack>
                    </Grid>
                </>
            )}

            {paramPartnerId === PARTNERS.IFTTT_PARTNERID && (
                <Grid item xs={12}>
                    <ProxyDevices
                        setDoorDev={setDoorDeviceFromAPI}
                        setMtnDev={setMtnSensorDeviceFromAPI}
                        setLkDev={setLeakSensorDeviceFromAPI}
                        setThDev={setThermostatDeviceFromAPI}
                        enableBtns={() => setDisableConfirmBtn(false)}
                        checkBasicSensors={() => CheckBasicSensorsHandler("cancel")}
                        sensorsConfirmBtn={() => SensorsConfirmBtn()}
                        disableBtn={disableConfirmBtn}
                        savingProg={showSavingProgress}
                        cancelProg={showCancelProgress}
                        showLoader={showGetDevicesLoader}
                        errorMsgProp={errorMsg}
                        infoMsgProp={infoMsg}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default SelectMultipleDevices;
