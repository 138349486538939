import { Box, Card, CardMedia } from "@mui/material";

export default function HouseCard({ children, smallImage, p, px, py }) {
    const imageFlex = smallImage ? 0.9 : 2;
    return (
        <Box height="100%" display="flex" justifyContent="center" py={4}>
            <Card sx={{ display: "flex", minHeight: 500, "--Card-radius": "32px" }}>
                <CardMedia
                    component="img"
                    image="/images/login-signup-photo.jpg"
                    alt="house"
                    sx={{ display: { xs: "none", md: "block" }, flex: imageFlex, minWidth: 0 }}
                />
                <Box flex={2}>
                    <Box p={p} px={px} py={py} height="100%" sx={{ overflowY: "auto" }}>
                        {children}
                    </Box>
                </Box>
            </Card>
        </Box>
    );
}
