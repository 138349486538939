import { API } from "aws-amplify";

export const STATUS_CODE_OTP_SUCCESS = 0;
export const STATUS_CODE_PROFILE_COMPLETE = 1;
export const STATUS_CODE_TOMORROW_REPORT_COMPLETE = 2;
export const STATUS_CODE_ADD_PROPERTY_COMPLETE = 3;
export const STATUS_CODE_ADD_ROOM_COMPLETE = 4;
export const STATUS_CODE_ADD_CALENDAR_COMPLETE = 5;
export const STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE = 6;
export const STATUS_CODE_PCR_COMPLETE = 7;
export const STATUS_CODE_TESTALERT_COMPLETE = 8;

export async function CheckRegistration() {
    const statusCode = await API.get("UserAPI", "/getSignUpProcessStatus").then((r) => r).catch(() => null);

    if (statusCode !== null) {
        switch (statusCode) {
            case STATUS_CODE_OTP_SUCCESS:
            case STATUS_CODE_PROFILE_COMPLETE:
            case STATUS_CODE_TOMORROW_REPORT_COMPLETE:
            case STATUS_CODE_ADD_PROPERTY_COMPLETE:
            case STATUS_CODE_ADD_ROOM_COMPLETE:
            case STATUS_CODE_ADD_CALENDAR_COMPLETE:
            case STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE:
            case STATUS_CODE_PCR_COMPLETE:
                return {
                    statusCode,
                    navigate: "/complete-registration",
                };
            default:
                return { navigate: "", statusCode };
        }
    }
    return true;
}
