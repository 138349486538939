import { faHotel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddBox, Check, Close, Delete, Edit } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, FormControl, IconButton, InputLabel, List, ListItem, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomRemoveDialog from "../helper/CustomRemoveDialog";
import { useAddPropertyRoomsMutation } from "../../features/auth/propertyAPI";
import { showLoader } from "../../features/auth/authSlice";

const StackItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const PropertyRoom = forwardRef(({
    callbackUpdateRooms,
    roomData,
    disableEntry = true,
    callbackIsFormValid,
    callbackSaveSuccess,
}, ref) => {
    const initialRoomState = {
        id: 0,
        rowIndex: 0,
        label: "",
    };
    const theme = useTheme();

    const [rooms, setRooms] = useState([]);
    const [newRoom, setNewRoom] = useState(initialRoomState);

    const [openEditRoomDlg, setOpenEditRoomDlg] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [openConfirmRemoveDlg, setOpenConfirmRemoveDlg] = useState(false);

    const [hasError, setHasError] = useState(false);

    const dispatch = useDispatch();

    const initialPropertyFk = useSelector((state) => state.auth.signUpProcessFlow.initialPropertyId);
    const [addPropertyRooms, {
        isError: isErrorAddPropertyRoom,
        isLoading: isLoadingAddPropertyRoom,
        isSuccess: isSuccessAddPropertyRoom,
    }] = useAddPropertyRoomsMutation();

    useEffect(() => {
        if (roomData === undefined) {
            const arr = [];
            const defVal1 = { ...initialRoomState };
            const defVal2 = { ...initialRoomState };
            defVal1.rowIndex = 1;
            defVal1.label = "Bathroom";
            defVal2.rowIndex = 2;
            defVal2.label = "Bedroom";

            arr.push(defVal1);
            arr.push(defVal2);

            setRooms(arr);
        } else {
            const arr = [];
            let idx = 0;
            roomData.forEach((e) => {
                const defVal = { ...initialRoomState };
                defVal.label = e.roomLabel;
                defVal.rowIndex = idx;
                arr.push(defVal);
                idx += 1;
            });

            setRooms(arr);
        }
    }, []);

    useEffect(() => {
        if (callbackUpdateRooms !== undefined) {
            const arr = [];
            rooms.forEach((x) => arr.push({ roomLabel: x.label }));
            callbackUpdateRooms(arr);
        }

        // for sign up
        if (callbackIsFormValid !== undefined) {
            callbackIsFormValid(showRoomLabelInput && rooms.length > 0);
        }
    }, [rooms]);

    useEffect(() => {
        if (!openEditRoomDlg) {
            setSelectedRoom(null);
        }
    }, [openEditRoomDlg]);

    useEffect(() => {
        if (!openConfirmRemoveDlg) setSelectedRoom(null);
    }, []);

    const editRoom = (data) => {
        const arr = [...rooms];
        const arrIndex = arr.findIndex((x) => x.rowIndex === data.rowIndex);
        arr[arrIndex].label = data.label;
        setRooms(arr);
    };

    const deleteRoom = (rowId) => {
        const arr = [...rooms];
        const arrIndex = arr.findIndex((x) => x.rowIndex === rowId);
        arr.splice(arrIndex, 1);
        setRooms(arr);
    };

    const addRoom = (data) => {
        // if rooms is an empty array then rooms[rooms.length - 1] is undefined, so we add default value of 0
        const lastIndexEntry = rooms[rooms.length - 1]?.rowIndex || 0;
        const newData = { ...initialRoomState };
        newData.rowIndex = lastIndexEntry + 1;
        newData.label = data.label;
        setRooms((r) => [...r, newData]);
        setNewRoom(initialRoomState);
    };

    // for sign up
    const roomCountInitialState = {
        bedroomCount: 1,
        bathroomCount: 1,
    };
    const [roomCount, setRoomCount] = useState(roomCountInitialState);
    const [showRoomLabelInput, setShowRoomLabelInput] = useState(false);
    const roomCountArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    useEffect(() => {
        if (isLoadingAddPropertyRoom) {
            dispatch(showLoader({ open: true, message: "Rooms are being saved. Please wait..." }));
        }

        if (isErrorAddPropertyRoom) {
            if (callbackSaveSuccess !== undefined) callbackSaveSuccess(false);
            dispatch(showLoader({ open: false, message: "" }));
        }

        if (isSuccessAddPropertyRoom) {
            if (callbackSaveSuccess !== undefined) callbackSaveSuccess(true);
            dispatch(showLoader({ open: false, message: "" }));
        }
    }, [isErrorAddPropertyRoom, isLoadingAddPropertyRoom, isSuccessAddPropertyRoom]);

    const addNewRoomRequest = async () => {
        if (rooms.length > 0) {
            const roomsParam = [];
            rooms.forEach((x) => roomsParam.push({
                roomLabel: x.label,
            }));
            addPropertyRooms({
                propertyFk: initialPropertyFk,
                rooms: roomsParam,
                isFromSignUp: true,
            });
        }
    };

    const resetRoomSettings = () => {
        setShowRoomLabelInput(false);
        setRooms([]);
        setRoomCount(roomCountInitialState);
    };

    useImperativeHandle(ref, () => ({
        addNewRoomRequest,
        resetRoomSettings,
    }));

    const handleShowLabelRooms = () => {
        setShowRoomLabelInput(true);
        const arr = [];
        let idx = 0;
        for (let index = 1; index <= roomCount.bedroomCount; index++) {
            const defVal1 = { ...initialRoomState };
            defVal1.label = `Bedroom ${index}`;
            defVal1.rowIndex = idx;
            arr.push(defVal1);
            idx += 1;
        }
        for (let index = 1; index <= roomCount.bathroomCount; index++) {
            const defVal1 = { ...initialRoomState };
            defVal1.label = `Bathroom ${index}`;
            defVal1.rowIndex = idx;
            arr.push(defVal1);
            idx += 1;
        }

        setRooms(arr);
    };

    const roomCountInput = (
        <Stack
            direction="row"
            spacing={2}
        >
            <Select
                size="small"
                value={roomCount.bedroomCount}
                onChange={(e) => {
                    setRoomCount((prev) => ({
                        ...prev,
                        bedroomCount: e.target.value,
                    }));
                }}
            >
                {roomCountArr.map((e) => (
                    <MenuItem
                        key={e}
                        value={e}
                        dense
                    >
                        {e}
                        {" "}
                        {e === 1 ? "Room" : "Rooms"}
                    </MenuItem>
                ))}
            </Select>
            <Select
                size="small"
                value={roomCount.bathroomCount}
                onChange={(e) => {
                    setRoomCount((prev) => ({
                        ...prev,
                        bathroomCount: e.target.value,
                    }));
                }}
            >
                {roomCountArr.map((e) => (
                    <MenuItem
                        key={e}
                        value={e}
                        dense
                    >
                        {e}
                        {" "}
                        {e === 1 ? "Bath" : "Baths"}
                    </MenuItem>
                ))}
            </Select>
            <Button
                size="small"
                variant="contained"
                disabled={roomCount.bedroomCount === 0 || roomCount.bathroomCount === 0}
                onClick={handleShowLabelRooms}
            >
                Go
            </Button>
        </Stack>
    );

    const roomLabelInput = (
        <Box
            maxWidth={300}
        >
            <List
                dense
                disablePadding
            >
                {rooms.map((r) => (
                    <ListItem
                        key={r.rowIndex}
                        disableGutters
                        secondaryAction={(
                            <Box
                                onClick={() => {
                                    deleteRoom(r.rowIndex);
                                }}
                                sx={{
                                    width: 31,
                                    height: 31,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                }}
                                display="flex"
                            >
                                <Delete color="error" />
                            </Box>
                        )}
                    >
                        <TextField
                            fullWidth
                            value={r.label}
                            size="small"
                            onChange={(e) => {
                                const roomArr = [...rooms];
                                const _roomData = roomArr.find((f) => f.rowIndex === r.rowIndex);
                                _roomData.label = e.target.value;
                                setRooms(roomArr);
                            }}
                        />
                    </ListItem>
                ))}

            </List>
            <Button
                fullWidth
                variant="contained"
                onClick={() => {
                    newRoom.label = "Room";
                    addRoom(newRoom);
                }}
            >
                Add Room
            </Button>
        </Box>
    );

    // render rooms for sign up
    if (callbackIsFormValid !== undefined) {
        if (!showRoomLabelInput) return (roomCountInput);
        return (roomLabelInput);
    }

    return (
        <>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Rooms</TableCell>
                        </TableRow>
                    </TableHead>
                    {/* {disableEntry && (
                        <TableBody>
                            <TableCell>
                                <Alert severity="info">
                                    Please perform location search before you can add or edit rooms
                                </Alert>
                            </TableCell>
                        </TableBody>
                    )} */}
                    <TableBody>
                        {rooms.map((r, i) => {
                            const idx = i;
                            return (
                                <TableRow key={idx}>
                                    <TableCell padding="none" sx={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <StackItem>
                                                <FontAwesomeIcon
                                                    icon={faHotel}
                                                    color={theme.palette.text.secondary}
                                                />
                                            </StackItem>
                                            <StackItem sx={{ flexGrow: 1 }}>
                                                {r.label}
                                            </StackItem>
                                            <StackItem>
                                                <IconButton
                                                    edge="end"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenEditRoomDlg(true);
                                                        setSelectedRoom(r);
                                                    }}
                                                    disabled={disableEntry}
                                                >
                                                    <Edit />
                                                </IconButton>
                                                <IconButton
                                                    edge="end"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenConfirmRemoveDlg(true);
                                                        setSelectedRoom(r);
                                                    }}
                                                    disabled={disableEntry}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </StackItem>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell
                                sx={{
                                    borderBottomWidth: 0,
                                }}
                            >
                                <Stack direction="row">
                                    <StackItem sx={{ flexGrow: 1 }}>
                                        <TextField
                                            sx={{
                                                flexGrow: 1,
                                                background: theme.palette.common.white,
                                            }}
                                            fullWidth
                                            error={hasError}
                                            helperText={hasError ? "* Required" : ""}
                                            // error={newRoom.label.trim().length === 0}
                                            // helperText={!disableEntry ? "* Required" : ""}
                                            size="small"
                                            value={newRoom.label}
                                            placeholder="Room Name"
                                            onChange={(e) => {
                                                setHasError(false);
                                                setNewRoom((prev) => ({ ...prev, label: e.target.value }));
                                            }}
                                            disabled={disableEntry}
                                        />
                                    </StackItem>
                                    <StackItem>
                                        <IconButton
                                            edge="end"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                if (newRoom.label.trim().length === 0) {
                                                    setHasError(true);
                                                    return;
                                                }
                                                addRoom(newRoom);
                                            }}
                                            disabled={disableEntry}
                                        >
                                            <AddBox />
                                        </IconButton>
                                    </StackItem>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
            <Dialog
                open={openEditRoomDlg}
            >
                <DialogContent>
                    <Stack
                        direction="row"
                    >
                        <Box>
                            {selectedRoom !== null && (
                                <TextField
                                    size="small"
                                    value={selectedRoom.label}
                                    placeholder="Room Name"
                                    onChange={(e) => {
                                        setSelectedRoom((prev) => ({
                                            ...prev,
                                            label: e.target.value,
                                        }));
                                    }}
                                />
                            )}
                        </Box>
                        <Box>
                            <>
                                <IconButton
                                    edge="end"
                                    color="primary"
                                    onClick={() => {
                                        if (selectedRoom.label.trim().length === 0) return;
                                        editRoom(selectedRoom);
                                        setOpenEditRoomDlg(false);
                                    }}
                                >
                                    <Check />
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    color="error"
                                    onClick={() => {
                                        setOpenEditRoomDlg(false);
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </>
                        </Box>
                    </Stack>
                </DialogContent>
            </Dialog>
            <CustomRemoveDialog
                open={openConfirmRemoveDlg}
                title="Remove Room"
                message={`Do you want to remove this room (${selectedRoom === null ? "" : selectedRoom.label})?`}
                okButtonText="Remove Room"
                onCancelClick={() => setOpenConfirmRemoveDlg(false)}
                onRemoveClick={() => {
                    setOpenConfirmRemoveDlg(false);
                    deleteRoom(selectedRoom.rowIndex);
                }}
            />
        </>
    );
});

export default PropertyRoom;
