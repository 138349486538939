import { Close, Error } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Skeleton, Stack, Typography, useTheme } from "@mui/material";

export default function CustomProgressAlertInfo({
    message,
    onClose,
}) {
    const theme = useTheme();

    return (
        <>
            {/* <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                mb={theme.spacing(1)}
                p={theme.spacing(1)}
                borderRadius={theme.spacing(1)}
                sx={{
                    backgroundColor: "rgba(234, 245, 251)",
                }}
            >
                <Stack>
                    <CircularProgress
                        color="info"
                        size="2rem"
                    />
                    <Error
                        fontSize="large"
                        style={{
                            color: theme.palette.info.light,
                            position: "absolute",
                            fontSize: "2rem",
                        }}
                    />
                </Stack>
                <Typography
                    variant="body2"
                    ml={theme.spacing(2)}
                    sx={{
                        flex: 1,
                    }}
                >
                    {message}
                </Typography>
                <IconButton>
                    <Close
                        fontSize="small"
                        color="#000"
                    />
                </IconButton>
            </Stack> */}
            <Box
                position="relative"
            >
                <Skeleton
                    variant="rounded"
                    height={50}
                    width="inherit"
                    animation="pulse"
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: theme.typography.body2.fontSize,
                        marginBottom: theme.spacing(1),
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                        backgroundColor: "rgba(234, 245, 251)",
                    }}
                />
                <Stack
                    position="absolute"
                    top={0}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    mb={theme.spacing(1)}
                    p={theme.spacing(1)}
                    borderRadius={theme.spacing(1)}
                    width="100%"
                    height="100%"
                >
                    <Error
                        fontSize="large"
                        style={{
                            color: theme.palette.info.light,
                            position: "absolute",
                            fontSize: "2rem",
                        }}
                    />
                    <Typography
                        variant="body2"
                        ml={theme.spacing(6)}
                        sx={{
                            flex: 1,
                        }}
                    >
                        {message}
                    </Typography>
                    {onClose !== undefined && (
                        <IconButton>
                            <Close
                                fontSize="small"
                                color="#000"
                            />
                        </IconButton>
                    )}
                </Stack>
            </Box>
        </>
    );
}
