import { Edit, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Skeleton, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDeleteDeviceConditionMutation, useGetConditionsByRuleQuery, useUpdateDeviceConditionMutation, useUpdateRelativeTimeConditionMutation } from "../../features/auth/conditionsAPI";
import { useGetDevicesAndStatesByPropertyIdQuery } from "../../features/auth/devicesAPI";
import CustomTimeField from "../helper/CustomTimeField";
import { CONTINUOUS_DURATION, INSTANT_DURATION } from "./AddConditions";

const StyledWeekdayToggleButtons = styled(ToggleButtonGroup)(({ theme }) => ({
    ".MuiToggleButton-root": {
        borderColor: theme.palette.background.default,
        "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
}));

export default function UpdateCondition({
    propertyId,
    ruleId,
    conditionType,
    rule,
    param,
    onClick,
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [isBookingLimitEnabled, setisBookingLimitEnabled] = useState(false);
    const [isMinuteLimitEnabled, setisMinuteLimitEnabled] = useState(false);

    const [deviceConditions, setDeviceConditions] = useState([]);

    // ruleConditionId, ruleId, deviceId, deviceStateId, isActive
    const [ruleConditionId, setRuleConditionId] = useState();
    // add device condition
    const [devicesStates, setDevicesStates] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState();
    const [selectedDeviceStateId, setSelectedDeviceStateId] = useState();
    const [selectedStatesList, setSelectedStatesList] = useState([]);
    // const [loadingDialog, setLoadingDialog] = useState(false);

    // Add Custom Condition
    const [bookingAlertLimit, setBookingAlertLimit] = useState(-1);
    const [minuteAlertLimit, setMinuteAlertLimit] = useState(-1);
    const [minuteAlertInterval, setMinuteAlertInterval] = useState(0);
    const [specificTimeValue, setSpecificTimeValue] = useState();

    const [beforeAfterState, setBeforeAfterState] = useState(true);
    // const [conditionType, setConditionType] = useState("Device");
    const [days, setDays] = useState(() => []);

    const [timeCondition, setTimeCondition] = useState(
        {
            TimeValue: 0,
            relativeTime: 1,
            relative: "",
            checkin: "",
            RuleId: ruleId,
            isActive: true,
            BookingAlertLimit: bookingAlertLimit,
            MinuteAlertLimit: minuteAlertLimit,
            MinuteAlertTimeInterval: minuteAlertInterval,
            SpecificTime: specificTimeValue,
            RecurringDays: days,
            TimeSet: "",
        },
    );

    const [timeText, setTimeText] = useState("");
    const [timeConditionFormVisible, setTimeConditionFormVisible] = useState(false);
    const [specificTimeFormVisible, setSpecificTimeFormVisible] = useState(false);
    const [selectedTimeConditionType, setSelectedTimeConditionType] = useState("");

    const [timeSet, setTimeSet] = useState("");
    const [editMode, setEditMode] = useState(false);

    // << rkt query
    const {
        data: dataDevicesAndStates,
        isLoading: isLoadingDevicesAndStates,
        isFetching: isFetchingDevicesAndStates,
    } = useGetDevicesAndStatesByPropertyIdQuery({
        propertyId,
    });

    const {
        data: dataGetConditionsByRule,
        isLoading: isLoadingGetConditionsByRule,
        isFetching: isFetchingGetConditionsByRule,
    } = useGetConditionsByRuleQuery({
        ruleId,
    });

    const [updateRelativeTimeCondition, {
        isLoading: isLoadingUpdateRelativeTimeCondition,
        isFetching: isFetchingUpdateRelativeTimeCondition,
    }] = useUpdateRelativeTimeConditionMutation({
        fixedCacheKey: `update-time-conditions-${ruleId}`,
    });

    const [updateDeviceCondition, {
        isLoading: isLoadingUpdateDeviceCondition,
        isFetching: isFetchinUpdateDeviceCondition,
    }] = useUpdateDeviceConditionMutation({
        fixedCacheKey: `update-device-condition-${ruleId}`,
    });

    const loadingDialog = isLoadingDevicesAndStates || isFetchingDevicesAndStates
        || isLoadingGetConditionsByRule || isFetchingGetConditionsByRule;

    const isUpdatingCondition = isLoadingUpdateRelativeTimeCondition
        || isFetchingUpdateRelativeTimeCondition
        || isLoadingUpdateDeviceCondition
        || isFetchinUpdateDeviceCondition;

    const [deleteDeviceCondition, {
        isLoading: isLoadingDeleteDeviceCondition,
        isFetching: isFetchingDeleteDeviceCondition,
    }] = useDeleteDeviceConditionMutation({
        fixedCacheKey: `delete-device-condition-${ruleId}`,
    });

    // rtk query >>

    useEffect(() => {
        if (dataDevicesAndStates !== undefined) {
            const defaultValue = {
                device: {
                    deviceId: 0,
                    displayName: <em>Select a device</em>,
                },
                states: [
                    {
                        deviceStateId: 0,
                        name: "Select a device state",
                    },
                ],
            };
            setDevicesStates([defaultValue, ...dataDevicesAndStates]);
            if (dataDevicesAndStates.length > 0) {
                setSelectedDeviceId(defaultValue.device.deviceId);
                setSelectedStatesList(defaultValue.states);
                setSelectedDeviceStateId(defaultValue.states[0].deviceStateId);
            }
        }
    }, [dataDevicesAndStates]);

    useEffect(() => {
        if (dataGetConditionsByRule !== undefined) {
            // structureCondition https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
            // deep copy object from rtk query (readonly) to become writable
            setDeviceConditions(structuredClone(dataGetConditionsByRule.deviceConditions));
        }
    }, [dataGetConditionsByRule]);

    const numberOnly = (id) => {
        const element = document.getElementById(id);
        element.value = element.value.replace(/[^0-9]/gi, "");
    };

    const updateTimeText = () => {
        if (timeCondition.relative && timeCondition.checkin && timeCondition.TimeValue) {
            setTimeText(`Notify me only ${timeCondition.TimeValue} minutes ${timeCondition.relative.toLowerCase()} ${timeCondition.checkin.toLowerCase()} if an alert is activated.`);
        } else {
            setTimeText("");
        }
    };

    const handleDays = (event, newFormats) => {
        setDays(newFormats);
    };

    const handleTimeChange = (e) => {
        setTimeCondition({
            ...timeCondition,
            TimeValue: e.target.value,
        });
        updateTimeText();
    };

    const handleCheckinChange = (e) => {
        setTimeCondition({
            ...timeCondition,
            checkin: e.target.value,
        });
        updateTimeText();
    };

    const [openDeviceState, setOpenDeviceState] = useState(true);
    const handleCloseDeviceState = () => {
        setOpenDeviceState(false);
    };
    const handleOpenDeviceState = () => {
        setOpenDeviceState(true);
    };

    const [durationMinutes, setDurationMinutes] = useState(5);

    const [deviceConditionDurationType, setDeviceConditionDurationType] = useState(null);
    const durationOptions = [
        {
            id: 1,
            value: INSTANT_DURATION,
        },
        {
            id: 2,
            value: CONTINUOUS_DURATION,
        },
    ];
    // same in AddConditions.jsx
    const ConvertToRelativeTime = (relativeCheckIn, t) => {
        const timeConditionObject = t;
        // console.log(relativeCheckIn);
        // eslint-disable-next-line max-len
        setTimeCondition({ ...timeCondition, BookingAlertLimit: bookingAlertLimit, MinuteAlertLimit: minuteAlertLimit, MinuteAlertTimeInterval: minuteAlertInterval, days, timeSet });
        timeCondition.BookingAlertLimit = bookingAlertLimit;
        timeCondition.MinuteAlertLimit = minuteAlertLimit;
        timeCondition.MinuteAlertTimeInterval = minuteAlertInterval;
        timeCondition.RecurringDays = days;
        timeCondition.TimeSet = timeSet;
        switch (relativeCheckIn) {
            case "AfterCheckIn":
                setTimeCondition({ ...timeCondition, relativeTime: 0 });
                timeConditionObject.relativeTime = 0;
                break;
            case "AfterCheckOut":
                setTimeCondition({ ...timeCondition, relativeTime: 1 });
                timeConditionObject.relativeTime = 1;
                break;
            case "InBetweenBookings":
                setTimeCondition({ ...timeCondition, relativeTime: 2 });
                timeConditionObject.relativeTime = 2;
                break;
            case "BeforeCheckIn":
                setTimeCondition({ ...timeCondition, relativeTime: 3 });
                timeConditionObject.relativeTime = 3;
                break;
            case "BeforeCheckOut":
                setTimeCondition({ ...timeCondition, relativeTime: 4 });
                timeConditionObject.relativeTime = 4;
                break;
            case "DuringBookings":
                setTimeCondition({ ...timeCondition, relativeTime: 5 });
                timeConditionObject.relativeTime = 5;
                break;
            case "SpecificTime":
                // console.log("correct");
                setTimeCondition({ ...timeCondition, relativeTime: 6 });
                timeConditionObject.relativeTime = 6;
                break;
            default:
                setTimeCondition({ ...timeCondition, relativeTime: 1 });
                timeConditionObject.relativeTime = 1;
        }
        return timeConditionObject;
    };

    // same in Condition.jsx
    const TimeTypeOnChangeHandler = (type) => {
        setSelectedTimeConditionType(type);
        switch (type) {
            case "AfterBefore":
                setTimeCondition({ ...timeCondition, relative: type, checkin: "CheckOut" });
                setBeforeAfterState(false);
                setTimeConditionFormVisible(true);
                setSpecificTimeFormVisible(false);
                break;
            case "InBetweenBookings":
                setTimeCondition({
                    ...timeCondition,
                    relative: type,
                    checkin: "",
                    TimeValue: 0,
                });
                setBeforeAfterState(true);
                setTimeConditionFormVisible(false);
                setSpecificTimeFormVisible(false);

                break;
            case "DuringBookings":
                setTimeCondition({
                    ...timeCondition,
                    relative: type,
                    checkin: "",
                    TimeValue: 0,
                });
                setBeforeAfterState(true);
                setTimeConditionFormVisible(false);
                setSpecificTimeFormVisible(false);
                break;
            case "SpecificTime":
                setTimeCondition({
                    ...timeCondition,
                    relative: type,
                });
                // console.log(timeCondition);
                setTimeConditionFormVisible(false);
                setSpecificTimeFormVisible(true);
                setBeforeAfterState(true);
                break;
            default:
                setTimeCondition({ ...timeCondition, relative: type });
                setBeforeAfterState(false);
                setSpecificTimeFormVisible(false);
                setTimeConditionFormVisible(false);
                break;
        }
    };

    const setMinuteAlertLimitHandler = (limitNum) => {
        setMinuteAlertLimit(limitNum);
    };

    const setBookingAlertLimitHandler = (limitNum) => {
        setBookingAlertLimit(limitNum);
    };

    const setPostCheckoutMinutesHandler = (e) => {
        setTimeCondition({
            ...timeCondition,
            TimeValue: e.target.value,
        });
    };

    const timeTextNotification = () => {
        if (timeCondition.TimeValue === 0
            || timeCondition.relative.length === 0
            || timeCondition.checkin.length === 0
        ) return <>&nbsp;</>;

        return (
            <Alert
                severity="info"
                variant="outlined"
            >
                <Typography variant="caption">
                    {"Notify me only "}
                    <b>{timeCondition.TimeValue}</b>
                    {" minute(s) "}
                    <b>{timeCondition.relative.toLowerCase()}</b>
                    {" "}
                    <b>{timeCondition.checkin.toLowerCase()}</b>
                    {" if an alert is activated."}
                    minutes
                </Typography>
            </Alert>

        );
    };

    const handleRelativeChange = (e) => {
        setTimeCondition({
            ...timeCondition,
            relative: e.target.value,
        });
        updateTimeText();
    };

    const handleMinuteLimitState = (event) => {
        setisMinuteLimitEnabled(event.target.checked);
        setMinuteAlertLimit(-1);
        setMinuteAlertInterval(-1);
    };

    const EditTimeConditionButtonHandler = (timeValue, relativeTime, timeConditionId) => {
        // console.log("type: ", "Time");
        // console.log("timeValue: ", timeValue);
        // console.log("relativeTime: ", relativeTime);
        // console.log("timeConditionId: ", timeConditionId);

        // setConditionType("Time");
        const timeConditionObject = timeCondition;
        timeConditionObject.TimeValue = timeValue;
        switch (relativeTime) {
            case 0:
                timeConditionObject.relative = "After";
                timeConditionObject.checkin = "CheckIn";
                break;
            case 1:
                timeConditionObject.relative = "After";
                timeConditionObject.checkin = "CheckOut";
                break;
            case 2:
                timeConditionObject.relative = "InBetweenBookings";
                timeConditionObject.checkin = "";
                break;
            case 3:
                timeConditionObject.relative = "Before";
                timeConditionObject.checkin = "CheckIn";
                break;
            case 4:
                timeConditionObject.relative = "Before";
                timeConditionObject.checkin = "CheckOut";
                break;
            case 5:
                timeConditionObject.relative = "DuringBookings";
                timeConditionObject.checkin = "";
                break;
            case 6:
                timeConditionObject.relative = "SpecificTime";
                timeConditionObject.checkin = "";
                break;
            default:
                timeConditionObject.relative = "After";
                timeConditionObject.checkin = "CheckOut";
                break;
        }
        // console.log("timeConditionObject: ", timeConditionObject);
        setTimeCondition(timeConditionObject);
        setRuleConditionId(timeConditionId);
        setEditMode(true);
    };

    const EditPostCheckoutConditionHandler = (timeValue, relativeTime, timeConditionId) => {
        // setConditionType("PostCheckOut");
        const timeConditionObject = timeCondition;
        timeConditionObject.TimeValue = timeValue;
        timeConditionObject.relative = "After";
        timeConditionObject.checkin = "CheckOut";
        // console.log("timeConditionObject: ", timeConditionObject);
        setTimeCondition(timeConditionObject);
        setRuleConditionId(timeConditionId);
        setEditMode(true);
    };
    const EditDeviceConditionButtonHandler = (
        deviceId,
        stateId,
        ruleCondId,
        deviceDuration,
    ) => {
        // setConditionType("Device");
        // setDeviceSelected({ deviceId });
        setSelectedDeviceId(deviceId);
        // setStateSelected({ deviceStateId: stateId });devicesStates);
        const filtered = devicesStates.filter((x) => x.device.deviceId === deviceId)[0].states;
        setSelectedStatesList(filtered);
        setSelectedDeviceStateId(stateId);
        setRuleConditionId(ruleCondId);
        setDeviceConditionDurationType(deviceDuration > 0 ? CONTINUOUS_DURATION : INSTANT_DURATION);
        setDurationMinutes(deviceDuration);
        setEditMode(true);
    };

    const handleBookingLimitState = (event) => {
        setisBookingLimitEnabled(event.target.checked);
        setBookingAlertLimit(-1);
    };

    const updateConditionOnLick = () => {
        if (onClick !== undefined) onClick();
        if (conditionType === "Time") {
            EditTimeConditionButtonHandler(
                param.timeValue,
                param.relativeTime,
                param.timeConditionId,
            );
        } else if (conditionType === "PostCheckOut") {
            EditPostCheckoutConditionHandler(
                param.timeValue,
                param.relativeTime,
                param.timeConditionId,
            );
        } else if (conditionType === "Device") {
            EditDeviceConditionButtonHandler(
                param.device.deviceId,
                param.deviceCondition.deviceStateId,
                param.deviceCondition.deviceConditionId,
                param.deviceCondition.duration,
            );
        } else {
            console.log("not in the list of types of conditions.");
        }
        setDefaultValues();
    };

    const EditConditionHandler = async () => {
        // setDisableControls(true);

        if (conditionType === "Device") {
            const conditionObject = deviceConditions.filter(
                (x) => x.deviceCondition.deviceConditionId === ruleConditionId,
            );
            conditionObject[0].deviceCondition.ruleId = rule.ruleId;
            conditionObject[0].deviceCondition.deviceId = selectedDeviceId;
            conditionObject[0].deviceCondition.deviceStateId = selectedDeviceStateId;
            conditionObject[0].deviceCondition.duration = deviceConditionDurationType === CONTINUOUS_DURATION ? durationMinutes : 0;
            conditionObject[0].deviceCondition.isActive = true;
            await updateDeviceCondition({
                id: ruleConditionId,
                condition: conditionObject[0].deviceCondition,
            });
        } else if (conditionType === "Time" || conditionType === "PostCheckOut") {
            const timeConditionObject = ConvertToRelativeTime(timeCondition.relative + timeCondition.checkin, timeCondition);
            await updateRelativeTimeCondition({
                id: ruleConditionId,
                condition: timeConditionObject,
            });
        }
        setEditMode(false);
    };

    const setDefaultValues = () => {
        setMinuteAlertLimit(-1);
        setBookingAlertLimit(-1);
        setMinuteAlertInterval(0);
        setisMinuteLimitEnabled(false);
        setisBookingLimitEnabled(false);
    };
    return (
        <>
            <IconButton
                color="primary"
                size="small"
                onClick={updateConditionOnLick}
            >
                <Edit />
            </IconButton>
            {
                conditionType === "Device" && (
                    <Dialog
                        open={editMode}
                        // onClose={() => setEditMode(false)}
                        fullWidth
                        maxWidth="sm"
                        fullScreen={isMobile}
                    >
                        <DialogTitle>Edit Condition</DialogTitle>
                        <DialogContent>
                            {!loadingDialog && (
                                <Grid container sx={{ marginTop: "16px" }}>
                                    <span style={{ marginRight: "16px" }}>If</span>
                                    <Grid item>
                                        <Select
                                            variant="standard"
                                            disabled={isUpdatingCondition}
                                            fullWidth
                                            value={selectedDeviceId}
                                            size="small"
                                            onChange={(e) => {
                                                setSelectedDeviceId(e.target.value);
                                                const filtered = devicesStates.filter((x) => x.device.deviceId === e.target.value)[0].states;
                                                setSelectedStatesList(filtered);
                                                setSelectedDeviceStateId(filtered[0].deviceStateId);
                                            }}
                                        >
                                            {devicesStates.map((d) => (
                                                <MenuItem key={d.device.deviceId} value={d.device.deviceId}>{d.device.displayName}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    {selectedDeviceId > 0 && (
                                        <Grid container spacing={1} sx={{ marginTop: "8px" }}>
                                            <span style={{ marginRight: "16px", color: "white" }}>If</span>
                                            <Grid item>
                                                {selectedStatesList != null && (
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        {/* <InputLabel id="states-select">State</InputLabel> */}
                                                        <Select
                                                            variant="standard"
                                                            labelId="duration-select"
                                                            label="Duration"
                                                            disabled={isUpdatingCondition}
                                                            fullWidth
                                                            value={deviceConditionDurationType}
                                                            onChange={(e) => {
                                                                setDeviceConditionDurationType(e.target.value);
                                                            }}
                                                        >
                                                            {durationOptions.map((d) => (
                                                                <MenuItem key={d.id} value={d.value}>{d.value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Grid>
                                            {selectedDeviceId > 0 && (
                                                <Grid item>
                                                    {selectedStatesList != null && (
                                                        <FormControl
                                                            size="small"
                                                            fullWidth
                                                        >
                                                            {/* <InputLabel id="states-select">State</InputLabel> */}
                                                            <Select
                                                                variant="standard"
                                                                labelId="states-select"
                                                                label="State"
                                                                disabled={isUpdatingCondition}
                                                                fullWidth
                                                                value={selectedDeviceStateId}
                                                                onChange={(e) => {
                                                                    setSelectedDeviceStateId(e.target.value);
                                                                }}
                                                                open={openDeviceState}
                                                                onOpen={handleOpenDeviceState}
                                                                onClose={handleCloseDeviceState}
                                                            >
                                                                {selectedStatesList.map((s) => (
                                                                    <MenuItem
                                                                        key={s.deviceStateId}
                                                                        value={s.deviceStateId}
                                                                    >
                                                                        {s.displayName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                </Grid>
                                            )}
                                            {selectedDeviceId > 0 && deviceConditionDurationType === CONTINUOUS_DURATION
                                            && selectedDeviceStateId != null && (
                                                <>
                                                    <Grid item>
                                                        for approximately
                                                    </Grid>
                                                    <Grid item xs={2} sx={{ marginRight: "1px" }}>
                                                        {selectedStatesList != null && (
                                                            <TextField
                                                                id="filled-number"
                                                                type="number"
                                                                variant="standard"
                                                                disabled={isUpdatingCondition}
                                                                value={durationMinutes}
                                                                onChange={(e) => {
                                                                    setDurationMinutes(e.target.value);
                                                                }}
                                                                slotProps={{
                                                                    inputLabel: {
                                                                        shrink: true,
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item>
                                                        minutes
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            )}

                            {loadingDialog && (
                                <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                disabled={isUpdatingCondition}
                                color="info"
                                onClick={() => setEditMode(false)}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={isUpdatingCondition}
                                loadingPosition="end"
                                endIcon={<Save />}
                                onClick={(e) => EditConditionHandler(e)}
                            >
                                <span>Update</span>
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                )
            }

            {/* Dialog for Editing a time condition */}
            {
                conditionType === "Time" && (
                    <Dialog
                        open={editMode}
                        fullWidth
                        fullScreen={isMobile}
                        maxWidth="sm"
                    >
                        <DialogTitle>Edit Condition</DialogTitle>
                        <DialogContent>
                            {!loadingDialog && (
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        marginTop: 0,
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                        >
                                            <InputLabel>Time Condition Type</InputLabel>
                                            <Select
                                                labelId="time-condition-label"
                                                id="time-condition-helper"
                                                label="Time Condition Type"
                                                disabled={isUpdatingCondition}
                                                value={timeCondition.relative}
                                                onChange={(e) => TimeTypeOnChangeHandler(e.target.value)}
                                            >
                                                <MenuItem value="AfterBefore">Before/After</MenuItem>
                                                <MenuItem value="InBetweenBookings">In Between</MenuItem>
                                                <MenuItem value="DuringBookings">Currently Booked</MenuItem>
                                                <MenuItem value="SpecificTime">Specific Time</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    {(timeCondition.relative === "AfterBefore" || timeCondition.relative === "After" || timeCondition.relative === "Before") && (
                                        <>
                                            <Grid item xs={12}>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={beforeAfterState}
                                                        value={timeCondition.TimeValue}
                                                        label="Minutes"
                                                        onInput={(e) => numberOnly(e.target.id)}
                                                        inputProps={{
                                                            maxLength: 4,
                                                        }}
                                                        onClick={updateTimeText}
                                                        onChange={handleTimeChange}
                                                        size="small"
                                                    />
                                                    <FormControl
                                                        fullWidth
                                                        size="small"
                                                    >
                                                        <InputLabel>Before/After</InputLabel>
                                                        <Select
                                                            labelId="before-after-label"
                                                            id="before-after-helper"
                                                            label="Before/After"
                                                            disabled={beforeAfterState}
                                                            value={timeCondition.relative}
                                                            fullWidth
                                                            onClick={updateTimeText}
                                                            onChange={handleRelativeChange}
                                                        >
                                                            <MenuItem value="Before">Before</MenuItem>
                                                            <MenuItem value="After">After</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl
                                                        fullWidth
                                                        size="smal"
                                                    >
                                                        <InputLabel id="checkinout-label">Checkin/Checkout</InputLabel>
                                                        <Select
                                                            labelId="checkinout-label"
                                                            id="checkinout-helper"
                                                            label="Checkin/Checkout"
                                                            fullWidth
                                                            disabled={beforeAfterState}
                                                            value={timeCondition.checkin}
                                                            onClick={updateTimeText}
                                                            onChange={handleCheckinChange}
                                                            size="small"
                                                        >
                                                            <MenuItem value="CheckIn">Check-in</MenuItem>
                                                            <MenuItem value="CheckOut">Check-out</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {timeTextNotification()}
                                            </Grid>
                                        </>
                                    )}
                                    {(specificTimeFormVisible || timeCondition.relative === "SpecificTime") && (
                                        <Stack spacing={1}>
                                            <CustomTimeField
                                                format="h:mm A"
                                                value={timeSet}
                                                onChange={(newValue) => setTimeSet(newValue)}
                                            />
                                            <StyledWeekdayToggleButtons
                                                value={days}
                                                onChange={handleDays}
                                                color="primary"
                                            >
                                                <ToggleButton value="SUN">S</ToggleButton>
                                                <ToggleButton value="MON">M</ToggleButton>
                                                <ToggleButton value="TUE">T</ToggleButton>
                                                <ToggleButton value="WED">W</ToggleButton>
                                                <ToggleButton value="THU">T</ToggleButton>
                                                <ToggleButton value="FRI">F</ToggleButton>
                                                <ToggleButton value="SAT">S</ToggleButton>
                                            </StyledWeekdayToggleButtons>
                                        </Stack>
                                    )}
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={(
                                                <Switch
                                                    checked={isMinuteLimitEnabled}
                                                    onChange={handleMinuteLimitState}
                                                    color="primary"
                                                />
                                            )}
                                            label="Limit alerts per minute? &nbsp;"
                                            labelPlacement="end"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        {isMinuteLimitEnabled && (
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    paddingLeft: isMobile ? theme.spacing(7) : 0,
                                                }}
                                            >
                                                <TextField
                                                    label="Maximum Alerts"
                                                    variant="outlined"
                                                    disabled={!isMinuteLimitEnabled}
                                                    value={timeCondition.minuteLimit}
                                                    onInput={(e) => numberOnly(e.target.id)}
                                                    inputProps={{
                                                        maxLength: 4,
                                                    }}
                                                    onChange={(e) => setMinuteAlertLimitHandler(e.target.value)}
                                                />
                                                <TextField
                                                    label="Minutes"
                                                    variant="outlined"
                                                    disabled={!isMinuteLimitEnabled}
                                                    value={timeCondition.minuteInterval}
                                                    onInput={(e) => numberOnly(e.target.id)}
                                                    inputProps={{
                                                        maxLength: 4,
                                                    }}
                                                    onChange={(e) => setMinuteAlertInterval(e.target.value)}
                                                />
                                            </Stack>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={(
                                                <Switch
                                                    checked={isBookingLimitEnabled}
                                                    onChange={handleBookingLimitState}
                                                    color="primary"
                                                />
                                            )}
                                            label="Limit alerts per booking?"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {isBookingLimitEnabled && (
                                            <TextField
                                                label="Maximum Alerts"
                                                variant="outlined"
                                                disabled={!isBookingLimitEnabled}
                                                value={timeCondition.bookingLimit}
                                                onInput={(e) => numberOnly(e.target.id)}
                                                inputProps={{
                                                    maxLength: 4,
                                                }}
                                                onChange={(e) => setBookingAlertLimitHandler(e.target.value)}
                                                sx={{
                                                    marginLeft: isMobile ? theme.spacing(7) : 0,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {loadingDialog && (
                                <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                disabled={isUpdatingCondition}
                                color="info"
                                onClick={() => setEditMode(false)}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={isUpdatingCondition}
                                loadingPosition="end"
                                endIcon={<Save />}
                                onClick={(e) => EditConditionHandler(e)}
                            >
                                <span>Update</span>
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                )
            }
            {
                (conditionType === "PostCheckOut") && (
                    <Dialog open={editMode} onClose={() => setEditMode(false)} fullWidth maxWidth="xs">
                        <DialogTitle>
                            Edit Post Checkout Routine
                        </DialogTitle>
                        <DialogContent>
                            <Box>
                                <TextField
                                    style={{ marginTop: "8px" }}
                                    label="Delay Time (in minutes)"
                                    variant="outlined"
                                    value={timeCondition.TimeValue}
                                    onInput={(e) => numberOnly(e.target.id)}
                                    helperText="Enter time in minutes"
                                    inputProps={{
                                        maxLength: 4,
                                    }}
                                    onChange={setPostCheckoutMinutesHandler}
                                    disabled={isUpdatingCondition}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                disabled={isUpdatingCondition}
                                color="info"
                                onClick={() => setEditMode(false)}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={isUpdatingCondition}
                                loadingPosition="end"
                                endIcon={<Save />}
                                onClick={(e) => EditConditionHandler(e)}
                            >
                                <span>Update</span>
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
}
