import React, { useEffect, useRef, useState } from "react";
import {
    Alert, Grid, Step, StepLabel,
    Typography,
    Stepper, Button, Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPersonWalkingLuggage,
    faDoorClosed,
} from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import MyProfile from "./profile/MyProfile";
import PropertyAdd from "./PropertyAdd";
import UpdateICalendar from "./iCalendar/UpdateICalendar";
import PartnerListForm from "./IoT/PartnerListForm";
import {
    showLoader,
    updateSignUpProcessFlow,
} from "../features/auth/authSlice";
import {
    STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE,
    STATUS_CODE_ADD_CALENDAR_COMPLETE,
    STATUS_CODE_ADD_PROPERTY_COMPLETE,
    STATUS_CODE_ADD_ROOM_COMPLETE,
    STATUS_CODE_OTP_SUCCESS,
    STATUS_CODE_PCR_COMPLETE,
    STATUS_CODE_PROFILE_COMPLETE,
    STATUS_CODE_TESTALERT_COMPLETE,
    STATUS_CODE_TOMORROW_REPORT_COMPLETE,
} from "../utils/SignUpStatusCode";
import TestAlertButton from "./IoT/TestAlertButton";
import { CheckBasicSensors } from "../api/IoT/devices";
import PCoRSetUp from "./IoT/PCoRSetUp";
import HouseCard from "./helper/HouseCard";
import { useUpdateSignUpProcessMutation } from "../features/auth/hostAPI";
import { useLazyGetPremadeRulesAndRuleGroupsByPropertyQuery } from "../features/auth/rulesAPI";
import { useGetAllPartnersQuery } from "../features/auth/partnersAPI";
import CustomAlertDialog from "./helper/CustomAlertDialog";
import PropertyRoom from "./Property/PropertyRoom";
import { useCreateHouseTimerMutation } from "../features/auth/devicesAPI";

const STEP_USERPROFILE = 0;
const STEP_TOMORROWREPORT = 1;
const STEP_ADDPROPERTY = 2;
const STEP_ADDROOM = 3;
const STEP_ICALENDAR = 4;
const STEP_DEVICES = 5;
const STEP_PCR = 6;
const STEP_SMS = 7;

function RequiredInfo() {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const signUpProcessFlow = useSelector(
        (state) => state.auth.signUpProcessFlow,
    );
    const [alertSeverity, setAlertSeverity] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [iCalendar, setICalendar] = useState(null);
    const [propertyId, setPropertyId] = useState(0);
    const [timeZone, setTimeZone] = useState(null);
    const [apidevData, setapidevData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showNoteOnCheckInOutDoors, setshowNoteOnCheckInOutDoors] = useState(false);
    const [alertMessage, setAlertMessage] = useState([]);
    const [alertTitle, setAlertTitle] = useState("");
    const [skipTitle, setSkipTitle] = useState("");
    const [addTitle, setAddTitle] = useState("");
    const [skipDisabled, setSkipDisabled] = useState(false);
    const [skipLoading, setSkipLoading] = useState(false);
    const [addMoreLoading, setAddMoreLoading] = useState(false);
    const [showPartnerList, setShowPartnerList] = useState(true);
    const [basicSensorsComplete, setBasicSensorsComplete] = useState(false);
    const [reloadPartnerList, setReloadPartnerList] = useState(false);
    const [deviceFailedAlert, setDeviceFailedAlert] = useState(false);
    const [premadeRulesFailed, setPremadeRulesFailed] = useState(false);
    const [relTime, setRelTime] = useState({});
    const [enableNextWizardButton, setEnableNextWizardButton] = useState(false);
    const [enableResetWizzardButton, setEnableResetWizzardButton] = useState(false);
    const dispatch = useDispatch();
    const steps = [STEP_USERPROFILE,
        STEP_TOMORROWREPORT,
        STEP_ADDPROPERTY,
        STEP_ADDROOM,
        STEP_ICALENDAR,
        STEP_DEVICES,
        STEP_PCR,
        STEP_SMS];

    const [updateSignUpProcess, {
        isLoading: isLoadingUpdateSignUpProcess,
    }] = useUpdateSignUpProcessMutation();

    const [getPremadeRulesAndRuleGroups, {
        data: dataGetPremadeRulesAndRuleGroups,
        isLoading: isLoadingGetPremadeRulesAndRuleGroups,
        isSuccess: isSuccessGetPremadeRulesAndRuleGroups,
    }] = useLazyGetPremadeRulesAndRuleGroupsByPropertyQuery({ propertyId });

    const {
        data: dataGetAllPartners,
    } = useGetAllPartnersQuery();

    const profileRef = useRef(null);
    const propertyRef = useRef(null);
    const propertyRoomRef = useRef(null);
    const iCalendarRef = useRef(null);
    const pcorRef = useRef(null);
    const testSmsRef = useRef(null);

    useEffect(() => {
        if (dataGetAllPartners !== null) {
            setapidevData(dataGetAllPartners);
        }
    }, [dataGetAllPartners]);

    const [
        createHouseTimer,
        {
            error: errorUCreateHouseTimerMutation,
            isError: isErrorUCreateHouseTimerMutation,
            isSuccess: isSuccessUCreateHouseTimerMutation,
            isLoading: isLoadingUCreateHouseTimerMutation,
        },
    ] = useCreateHouseTimerMutation();

    useEffect(() => {
        switch (signUpProcessFlow.statusCode) {
            case STATUS_CODE_OTP_SUCCESS:
                setActiveStep(STEP_USERPROFILE);
                break;
            case STATUS_CODE_PROFILE_COMPLETE:
                setActiveStep(STEP_TOMORROWREPORT);
                break;
            case STATUS_CODE_TOMORROW_REPORT_COMPLETE:
                setActiveStep(STEP_ADDPROPERTY);
                break;
            case STATUS_CODE_ADD_PROPERTY_COMPLETE:
                setActiveStep(STEP_ADDROOM);
                setPropertyId(signUpProcessFlow.initialPropertyId);
                setTimeZone(signUpProcessFlow.initialPropertyTimezone);
                break;
            case STATUS_CODE_ADD_ROOM_COMPLETE:
                setActiveStep(STEP_ICALENDAR);
                setPropertyId(signUpProcessFlow.initialPropertyId);
                setTimeZone(signUpProcessFlow.initialPropertyTimezone);
                setEnableNextWizardButton(true);
                break;
            case STATUS_CODE_ADD_CALENDAR_COMPLETE:
                setActiveStep(STEP_DEVICES);
                setEnableNextWizardButton(false);
                setPropertyId(signUpProcessFlow.initialPropertyId);
                (async () => {
                    const hasBasicSensors = await CheckBasicSensors(
                        signUpProcessFlow.initialPropertyId,
                    );
                    if (
                        hasBasicSensors.hasMainDoorSensor
                        && hasBasicSensors.hasMotionSensor
                    ) {
                        setSkipTitle("Skip Step");
                        setSkipDisabled(false);
                        setBasicSensorsComplete(true);
                    }
                })();
                break;
            case STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE:
                setPropertyId(signUpProcessFlow.initialPropertyId);
                (async () => {
                    dispatch(
                        showLoader({
                            open: true,
                            message: "Please wait.",
                        }),
                    );
                    await getPremadeRulesAndRuleGroups({
                        propertyId: signUpProcessFlow.initialPropertyId,
                    }).then((data) => {
                        if (data.isSuccess) {
                            data.data.rules.forEach((r) => {
                                if (
                                    r.rule.name === "Post check-out routine ongoing"
                                ) {
                                    setRelTime(r.relativeTimeCondition);
                                }
                            });
                        }
                        if (data.isError) {
                            setPremadeRulesFailed(true);
                            setEnableNextWizardButton(true);
                        }
                    });
                    dispatch(showLoader({ open: false, message: "" }));
                })();
                setTimeout(() => setActiveStep(STEP_PCR), 3000);
                break;
            case STATUS_CODE_PCR_COMPLETE:
                (async () => {
                    await createHouseTimer();
                })();
                setPropertyId(signUpProcessFlow.initialPropertyId);
                setEnableNextWizardButton(true);
                setActiveStep(STEP_SMS);
                break;
            default:
                window.location.replace("/property");
        }
    }, [
        dispatch,
        navigate,
        signUpProcessFlow.initialPropertyId,
        signUpProcessFlow.initialPropertyTimezone,
        signUpProcessFlow.statusCode,
        reloadPartnerList,
    ]);

    const updateSignUpFlow = async (statusCode) => {
        await updateSignUpProcess({ statusCode });
    };

    // const callbackScheduler = async () => {
    //     await updateSignUpFlow(STATUS_CODE_ADD_CALENDAR_COMPLETE);
    //     setActiveStep(STEP_DEVICES);
    // };

    // const onSkipPostCheckOutRoutine = async () => {
    //     setSkipLoading(true);
    //     await updateSignUpFlow(STATUS_CODE_TESTALERT_COMPLETE);
    // };

    const OnSkipHandler = async () => {
        setSkipLoading(true);
        setDeviceFailedAlert(false);
        setBasicSensorsComplete(false);
        setShowAlert(false);
        setshowNoteOnCheckInOutDoors(true);
        setShowPartnerList(false);
        await updateSignUpFlow(STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE);
        setSkipLoading(false);
    };

    const onAddMoreHandler = async () => {
        setAddMoreLoading(true);
        await API.get("UserAPI", "/getSignUpProcessStatus").then(async (res) => {
            await updateSignUpFlow(res);
        });
        setShowAlert(false);
        setAddMoreLoading(false);
    };

    // this sets the next signup process
    const callbackDevice = async (
        title,
        message,
        severity,
        cantSkip,
        skipLabel,
        addLabel,
    ) => {
        setDeviceFailedAlert(false);
        if (!cantSkip) {
            setBasicSensorsComplete(true);
        } else {
            setBasicSensorsComplete(false);
        }
        setAlertSeverity(severity);
        setAlertTitle(title);
        setSkipTitle(skipLabel);
        setAddTitle(addLabel);
        setAlertMessage(message);
        setSkipDisabled(cantSkip);
        setShowAlert(true);
    };

    const callbackPCoR = async (setLoading) => {
        await updateSignUpFlow(STATUS_CODE_PCR_COMPLETE);
        setLoading(false);
    };

    const wizardTitle = (_stepId) => {
        switch (_stepId) {
            case STEP_USERPROFILE:
                return "Profile";
            case STEP_TOMORROWREPORT:
                return "General Time Settings";
            case STEP_ADDPROPERTY:
                return "Add Property";
            case STEP_ADDROOM:
                return "Add Rooms";
            case STEP_ICALENDAR:
                return "Add Booking Schedule to the Property";
            case STEP_DEVICES:
                return "Add Devices to the Property";
            case STEP_PCR:
                return "Set up the Post Check-Out Routine";
            case STEP_SMS:
                return "Test Alerts";
            default:
                return "";
        }
    };

    const wizardResetButton = () => {
        if (activeStep === STEP_ADDROOM) {
            const handleResetClick = (e) => {
                propertyRoomRef.current.resetRoomSettings();
            };
            return (
                <Button
                    variant="outlined"
                    disabled={!enableResetWizzardButton}
                    onClick={handleResetClick}
                >
                    Reset
                </Button>
            );
        }
        return <>&nbsp;</>;
    };

    const wizardNextButtonLabel = () => {
        if (activeStep === STEP_PCR) {
            if (!premadeRulesFailed) {
                return "Next";
            }

            return "Finish";
        }

        if (activeStep === STEP_SMS) return "Finish";
        return "Next";
    };

    return (
        <HouseCard smallImage px={5} py={3}>
            <Stack
                direction="column"
                sx={{
                    justifyContent: "space-between",
                    // height: "100%",
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((s, i) => {
                                const idx = i;
                                return (
                                    <Step
                                        key={idx}
                                        sx={{ padding: 0 }}
                                    >
                                        <StepLabel sx={{ marginRight: -1 }} />
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {wizardTitle(activeStep)}
                        </Typography>
                    </Grid>
                    {(activeStep === STEP_USERPROFILE) && (
                        <Grid item xs={12}>
                            <MyProfile
                                ref={profileRef}
                                signUpWizardStep={activeStep}
                                callbackIsFormValid={(e) => setEnableNextWizardButton(e)}
                                callbackSaveSuccess={(e) => {
                                    if (e !== null) {
                                        setActiveStep(STEP_TOMORROWREPORT);
                                        dispatch(
                                            updateSignUpProcessFlow({
                                                statusCode: STATUS_CODE_PROFILE_COMPLETE,
                                            }),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    {(activeStep === STEP_TOMORROWREPORT) && (
                        <Grid item xs={12}>
                            <MyProfile
                                ref={profileRef}
                                signUpWizardStep={activeStep}
                                callbackIsFormValid={(e) => setEnableNextWizardButton(e)}
                                callbackSaveSuccess={(e) => {
                                    if (e !== null) {
                                        setActiveStep(STEP_ADDPROPERTY);
                                        dispatch(
                                            updateSignUpProcessFlow({
                                                statusCode: STATUS_CODE_TOMORROW_REPORT_COMPLETE,
                                            }),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    {activeStep === STEP_ADDPROPERTY && (
                        <Grid item xs={12}>
                            <PropertyAdd
                                ref={propertyRef}
                                // callbackRequire={callbackProperty}
                                callbackIsFormValid={(e) => setEnableNextWizardButton(e)}
                                callbackSaveSuccess={(e) => {
                                    if (e) {
                                        // setPropertyId(dataProperty.id);
                                        // setTimeZone(dataProperty.timeZone);
                                        setActiveStep(STEP_ADDROOM);
                                        dispatch(
                                            updateSignUpProcessFlow({
                                                statusCode: STATUS_CODE_ADD_PROPERTY_COMPLETE,
                                            }),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    {activeStep === STEP_ADDROOM && (
                        <Grid item xs={12}>
                            <PropertyRoom
                                ref={propertyRoomRef}
                                disableEntry={false}
                                callbackIsFormValid={(e) => {
                                    setEnableNextWizardButton(e);
                                    setEnableResetWizzardButton(e);
                                }}
                                callbackSaveSuccess={(e) => {
                                    if (e) {
                                        setActiveStep(STEP_ICALENDAR);
                                        dispatch(
                                            updateSignUpProcessFlow({
                                                statusCode: STATUS_CODE_ADD_ROOM_COMPLETE,
                                            }),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    {activeStep === STEP_ICALENDAR && propertyId !== 0 && timeZone !== null && (
                        <Grid item xs={12}>
                            <UpdateICalendar
                                ref={iCalendarRef}
                                propertyFK={signUpProcessFlow.initialPropertyId}
                                iCalendar={iCalendar}
                                setICalendar={setICalendar}
                                timeZone={userInfo.localTimeZoneDatabaseName}
                                standardCheckInTime={userInfo.standardCheckInTime}
                                standardCheckOutTime={userInfo.standardCheckOutTime}
                                callbackSaveSuccess={async (e) => {
                                    if (e) {
                                        setActiveStep(STEP_DEVICES);
                                        await updateSignUpProcess({ statusCode: STATUS_CODE_ADD_CALENDAR_COMPLETE });
                                        dispatch(
                                            updateSignUpProcessFlow({
                                                statusCode: STATUS_CODE_ADD_CALENDAR_COMPLETE,
                                            }),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    {activeStep === STEP_DEVICES && (
                        <Grid item xs={12}>
                            {showAlert && (
                                <CustomAlertDialog
                                    open={showAlert}
                                    title={alertTitle}
                                    message={alertMessage}
                                    dialogType={alertSeverity}
                                    okButtonText={addTitle}
                                    cancelButtonText={skipTitle}
                                    callbackOk={onAddMoreHandler}
                                    callbackCancel={OnSkipHandler}
                                    cancelLoading={skipLoading}
                                    okLoading={addMoreLoading}
                                />
                            )}
                            {showNoteOnCheckInOutDoors && (
                                <Alert
                                    severity="info"
                                    variant="standard"
                                    onClose={() => {
                                        setShowAlert(false);
                                    }}
                                >
                                    Devices are being added...
                                    {" "}
                                    <br />
                                    <br />
                                    <strong>Note:</strong>
                                    {" "}
                                    Check-In/Out Door Sensors
                                    {" "}
                                    <span className="fa-layers">
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={faDoorClosed}
                                            color="#cceacc"
                                            style={{ marginLeft: 5 }}
                                        />
                                        <FontAwesomeIcon
                                            icon={faPersonWalkingLuggage}
                                            color="green"
                                            style={{
                                                margin: "0px 0px 0px 0px",
                                            }}
                                        />
                                    </span>
                                    {" "}
                                    <span style={{ marginLeft: "10px" }}>
                                        are used to notify hosts that their guests have
                                        arrived/left. Door sensors can be toggled in/out of this
                                        alert group by clicking on their
                                    </span>
                                    {" "}
                                    <span className="fa-layers">
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={faDoorClosed}
                                            color="#cceacc"
                                            style={{ marginLeft: 5 }}
                                        />
                                        <FontAwesomeIcon
                                            icon={faPersonWalkingLuggage}
                                            color="green"
                                            style={{
                                                margin: "0px 0px 0px 0px",
                                            }}
                                        />
                                    </span>
                                    {" "}
                                    <span style={{ marginLeft: "10px" }}>icon in Devices.</span>
                                </Alert>
                            )}
                            {apidevData && showPartnerList && (
                                <PartnerListForm
                                    partnerList={apidevData}
                                    propertyId={propertyId}
                                    callbackRequired={callbackDevice}
                                    forSignUp
                                />
                            )}
                            {basicSensorsComplete && showPartnerList && (
                                <LoadingButton
                                    sx={{ marginTop: "2rem" }}
                                    variant="contained"
                                    loading={skipLoading}
                                    loadingPosition="center"
                                    onClick={OnSkipHandler}
                                    disabled={skipDisabled}
                                >
                                    Skip step
                                </LoadingButton>
                            )}
                        </Grid>
                    )}
                    {activeStep === STEP_PCR && (
                        <Grid item xs={12}>
                            {!premadeRulesFailed ? (
                                <>
                                    <Alert
                                        severity="info"
                                        variant="standard"
                                        sx={{ marginBottom: "2rem" }}
                                    >
                                        {/* <Typography> */}
                                        <strong>Note:</strong>
                                        {" "}
                                        The Post Check-out Routine
                                        automatically checks the property using sensors
                                        {" "}
                                        <strong>30 minutes after check-out.</strong>
                                        {" "}
                                        You can change
                                        this default time now or later in the
                                        <strong> Alerts</strong>
                                        {" "}
                                        tab.
                                        {/* </Typography> */}
                                    </Alert>

                                    <PCoRSetUp
                                        ref={pcorRef}
                                        callbackRequired={callbackPCoR}
                                        relTimeCond={relTime}
                                        callbackIsFormValid={(e) => setEnableNextWizardButton(e)}
                                        callbackSaveSuccess={async (e) => {
                                            if (e) {
                                                setActiveStep(STEP_SMS);
                                                await updateSignUpProcess({ statusCode: STATUS_CODE_PCR_COMPLETE });
                                                dispatch(
                                                    updateSignUpProcessFlow({
                                                        statusCode: STATUS_CODE_PCR_COMPLETE,
                                                    }),
                                                );
                                            }
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <Alert severity="error" variant="standard">
                                        {/* <Typography> */}
                                        <strong>Note:</strong>
                                        {" "}
                                        The Post Check-out Routine
                                        automatically checks the property using sensors
                                        {" "}
                                        <strong>30 minutes after check-out.</strong>
                                        {" "}
                                        <div style={{ marginTop: "1rem" }}>
                                            <span>
                                                Unfortunately, the previous step is taking longer than
                                                expected. We will be skipping the current and next step,
                                                but you can always change this default time later in the
                                                <strong> Alerts</strong>
                                                {" "}
                                                tab.
                                            </span>
                                        </div>
                                        {/* </Typography> */}
                                    </Alert>
                                    {/* <LoadingButton
                                        sx={{ marginTop: "2rem" }}
                                        variant="contained"
                                        loading={skipLoading}
                                        loadingPosition="center"
                                        onClick={onSkipPostCheckOutRoutine}
                                        disabled={skipDisabled}
                                    >
                                        Skip step
                                    </LoadingButton> */}
                                </>
                            )}
                        </Grid>
                    )}
                    {activeStep === STEP_SMS && (
                        <Grid item xs={12}>
                            <TestAlertButton
                                ref={testSmsRef}
                                propertyId={propertyId}
                                callbackSaveSuccess={async (e) => {
                                    if (e) {
                                        setActiveStep(STEP_SMS);
                                        await updateSignUpProcess({ statusCode: STATUS_CODE_TESTALERT_COMPLETE });
                                        dispatch(
                                            updateSignUpProcessFlow({
                                                statusCode: STATUS_CODE_TESTALERT_COMPLETE,
                                            }),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} />
                </Grid>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                >
                    {wizardResetButton()}
                    <Button
                        variant="contained"
                        disabled={!enableNextWizardButton}
                        onClick={async (e) => {
                            switch (activeStep) {
                                case STEP_USERPROFILE:
                                    await profileRef.current.saveProfile();
                                    break;
                                case STEP_TOMORROWREPORT:
                                    profileRef.current.saveTomorrowReport();
                                    break;
                                case STEP_ADDPROPERTY:
                                    await propertyRef.current.addNewProperty(e);
                                    break;
                                case STEP_ADDROOM:
                                    await propertyRoomRef.current.addNewRoomRequest();
                                    break;
                                case STEP_ICALENDAR:
                                    await iCalendarRef.current.click();
                                    break;
                                case STEP_PCR:
                                    if (!premadeRulesFailed) await pcorRef.current.proceedSetup();
                                    else {
                                        await updateSignUpProcess({ statusCode: STATUS_CODE_TESTALERT_COMPLETE });
                                        dispatch(
                                            updateSignUpProcessFlow({
                                                statusCode: STATUS_CODE_TESTALERT_COMPLETE,
                                            }),
                                        );
                                    }
                                    break;
                                case STEP_SMS:
                                    await testSmsRef.current.proceedUpdateRuleState();
                                    break;
                                default:
                                    break;
                            }
                        }}
                    >
                        {wizardNextButtonLabel()}
                    </Button>
                </Stack>
            </Stack>
        </HouseCard>
    );
}

export default RequiredInfo;
