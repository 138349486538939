import { CloseOutlined, InsertLink, LinkOff, Logout, OpenInBrowser } from "@mui/icons-material";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, Link, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, makeStyles } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { LoadingButton } from "@mui/lab";
import { v4 as uuid } from "uuid";
import SelectDevices from "./SelectDevices";
import SelectMultipleDevices from "./SelectMultipleDevice";
import { useGetAllPartnersQuery, useLazyGetAllPartnersQuery } from "../../features/auth/partnersAPI";
import { useSwitchPartnerAccountMutation } from "../../features/auth/accountsAPI";
import { useLazyGetDevicesByPropertyIdQuery, useLazyGetPropertyDeviceStatesByPropertyQuery } from "../../features/auth/devicesAPI";
import { useLazyGetRulesByPropertyQuery } from "../../features/auth/rulesAPI";
import { useLazyGetActionsByRuleIdQuery } from "../../features/auth/actionsAPI";
import { useLazyGetConditionsByRuleQuery } from "../../features/auth/conditionsAPI";
import CustomRemoveDialog from "../helper/CustomRemoveDialog";

function PartnerListForm({
    propertyId,
    callbackRequired,
    forSignUp = false,
    setHasDuplicate,
    ClosePartnerList,
    refreshPartnerList,
    configureAlertBox,
    updateDeviceHandler,
    setSelectedDevice,
}) {
    const [authUrl, setAuthUrl] = useState("");
    const [openAuthDlg, setOpenAuthDlg] = useState(false);
    const [openAddDeviceDlg, setOpenAddDeviceDlg] = useState(false);
    const [switchLoading, setSwitchLoading] = useState(false);
    const [authCode, setAuthCode] = useState("");
    const [partnerId, setPartnerId] = useState(null);
    const [confirmRemoveAccount, setConfirmRemoveAccount] = useState(false);
    const [partner, setPartner] = useState();
    const [partnerList, setPartnerList] = useState([]);
    const [loading, setLoading] = useState(false);
    // ifttt
    const [iftttPartnerAccountId, setIftttPartnerAccountId] = useState(0);
    // sensibo
    const [cancelLoading, setCancelLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [sensiboAuthKey, setSensiboAuthKey] = useState("");
    const [hasAuthError, setHasAuthError] = useState(false);
    const [authErrorMsg, setAuthErrorMsg] = useState("");

    const [triggerGetConditionsByRule] = useLazyGetConditionsByRuleQuery();
    const [triggerGetActionsByRule] = useLazyGetActionsByRuleIdQuery();
    const [
        triggerGetRulesByProperty,
        { data: dataGetRulesByProperty, isSuccess: isSuccessGetRulesByProperty },
    ] = useLazyGetRulesByPropertyQuery();
    const [
        triggerGetPropertyDeviceStatesByProperty,
        {
            data: dataGetPropertyDeviceStatesByProperty,
            isLoading: isLoadingGetPropertyDeviceStatesByProperty,
            isFetching: isFetchingGetPropertyDeviceStatesByProperty,
        },
    ] = useLazyGetPropertyDeviceStatesByPropertyQuery();
    const [
        triggerGetDevicesByPropertyId,
        {
            data: dataDevicesByProperty,
            isLoading: isLoadingGetDevicesByPropertyId,
            isFetching: isFetchingGetDevicesByPropertyId,
        },
    ] = useLazyGetDevicesByPropertyIdQuery();
    const [
        triggerGetAllPartners,
        {
            data: dataLazyGetPartners,
            isSuccess: isSuccessLazyGetPartners,
            isError: isErrorLazyGetPartners,
            isLoading: isLoadingLazyGetPartners,
            isFetching: isFetchingLazyGetPartners,
        },
    ] = useLazyGetAllPartnersQuery();
    const
        {
            data: dataGetPartners,
            isSuccess: isSuccessGetPartners,
            isError: isErrorGetPartners,
            isLoading: isLoadingGetPartners,
            isFetching: isFetchingGetPartners,
        } = useGetAllPartnersQuery();
    const [
        switchPartnerAccount,
        {
            error: errorSwitchPartnerAccount,
            isError: isErrorSwitchPartnerAccount,
            isSuccess: isSuccessSwitchPartnerAccount,
            isLoading: isLoadingSwitchPartnerAccount,
        },
    ] = useSwitchPartnerAccountMutation();
    const onCancelHandler = () => {
        setCancelLoading(true);
        setOpenAuthDlg(false);
        setCancelLoading(false);
    };

    const onSubmitHandler = async () => {
        setSubmitLoading(true);
        if (sensiboAuthKey.length < 30) {
            setAuthErrorMsg("Please enter a valid API key.");
            setHasAuthError(true);
            setSubmitLoading(false);
        } else {
            // send apiKey to api/sensibo to check if valid
            const myInit = {
                body: {
                    access_token: sensiboAuthKey,
                },
            };
            API.post("IoTAPI", "/sensibo/", myInit)
                .then(() => {
                    setAuthCode(sensiboAuthKey);
                    setOpenAuthDlg(false);
                    setOpenAddDeviceDlg(true);
                    setHasAuthError(false);
                })
                .catch((ex) => {
                    const exception = { ...ex };
                    if (exception.response.status === 403) {
                        setAuthErrorMsg("Please enter a valid API key.");
                        setHasAuthError(true);
                    }
                })
                .finally(() => {
                    setSubmitLoading(false);
                });
        }
    };

    const sxCloseDlgButton = {
        position: "absolute",
        top: 8,
        right: 8,
    };

    useEffect(() => {
        if (dataGetPartners !== undefined) {
            setSwitchLoading(false);
            setPartnerList(dataGetPartners);
            (async () => {
                triggerGetDevicesByPropertyId({ propertyId });
                triggerGetPropertyDeviceStatesByProperty({ propertyId });
                await triggerGetRulesByProperty({ propertyId }).then(
                    (rules) => {
                        rules.data.forEach((r) => {
                            triggerGetConditionsByRule({ ruleId: r.ruleId });
                            triggerGetActionsByRule({ ruleId: r.ruleId });
                        });
                    },
                );
            })();
        }
    }, [dataGetPartners, isSuccessSwitchPartnerAccount, isErrorSwitchPartnerAccount]);

    useEffect(() => {
        const postMsgHandler = (ev) => {
            if (typeof ev.data !== "object") return;
            if (!ev.data.type) return;
            if (ev.data.type !== "auth-query-param") return;
            if (!ev.data.code) return;
            if (!ev.data.state) return;
            setAuthCode(ev.data.code);
            setOpenAuthDlg(false);
            setOpenAddDeviceDlg(true);
        };

        window.addEventListener("message", postMsgHandler);

        return () => window.removeEventListener("message", postMsgHandler);
    }, []);

    useEffect(() => {
    }, [confirmRemoveAccount]);
    const handleCloseAddDeviceDlgProperties = async () => {
        setOpenAddDeviceDlg(false);
    };

    const handleCloseAddDeviceDlgSignUp = async (mainDoor, door, motion, thermostat) => {
        let message = [""];
        let title = "";
        if (mainDoor && door && motion) {
            if (thermostat) {
                title = "Your devices will be ready shortly.";
                message = ["Would you like to add more devices?"];
                callbackRequired(title, message, "info", false, "Later", "Yes");
            } else {
                title = "You haven't added an AC Thermostat.";
                message = ["Add one from another partner?"];
                callbackRequired(title, message, "info", false, "Skip", "Add");
            }
        } else if (!mainDoor && motion) {
            title = "Please add a door sensor for the main entrance.";
            message = ["Add more sensors from another partner?"];
            callbackRequired(title, message, "warning", true, "Skip", "Add More");
        } else if (!motion && mainDoor) {
            title = "Please add at least one motion sensor.";
            message = ["Add more sensors from another partner?"];
            callbackRequired(title, message, "warning", true, "Skip", "Add More");
        } else {
            title = "Please add at least one door sensor for the main entrance and one motion sensor.";
            message = ["Add sensors from another partner?"];
            callbackRequired(title, message, "warning", true, "Skip", "Add More");
        }
        setOpenAddDeviceDlg(false);
    };
    return (
        <>
            {partnerList !== undefined && (
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                        <Typography id="modal-modal-title" variant="subtitle1">
                            List of Partners
                        </Typography>
                    </Grid>
                    {(partnerList.length === 0 || loading) && (
                        <Grid item xs={12}>
                            <Skeleton variant="rounded" height={30} width="inherit">
                                Loading...Please Wait
                            </Skeleton>
                        </Grid>
                    )}
                    {partnerList.length > 0
              && partnerList.map((data) => (
                  <Fragment key={data.partnerId}>
                      <Grid item xs={12} sm={4} align="center">
                          <Box
                              component="img"
                              src={`/images/partners/${data.name}.png`}
                              width={32}
                              height={32}
                              display="inline-block"
                              marginRight={1}
                              sx={{ objectFit: "contain", verticalAlign: "middle" }}
                          />
                          <Typography
                              variant="subtitle2"
                              display="inline"
                              sx={{ verticalAlign: "middle" }}
                          >
                              {data.name}
                          </Typography>
                      </Grid>
                      {!data.hasToken && (
                          <Grid item xs={6} sm={4} align="center">
                              <Button
                                  // this is a fake button for UI purposes
                                  variant="contained"
                                  color="success"
                                  size="small"
                                  fullWidth
                                  disabled
                                  startIcon={<OpenInBrowser />}
                              >
                                  Devices
                              </Button>
                          </Grid>
                      )}
                      <Grid item xs={6} sm={4} align="center">
                          <Button
                              onClick={() => {
                                  setAuthUrl("");
                                  if (data.name === "Yosmart") {
                                      setIftttPartnerAccountId(
                                          0,
                                      );
                                      setPartnerId(data.partnerId);
                                      setAuthCode(null); // => resets auth page code
                                      if (data.hasToken && data.isActive) {
                                          setOpenAddDeviceDlg(true);
                                      } else {
                                          setAuthUrl(
                                              `${
                                                  data.urlAuth + window.location.origin
                                              }/deviceauth/property/${propertyId}/selectdevices/${
                                                  data.partnerId
                                              }`,
                                          );
                                          setOpenAuthDlg(true);
                                      }
                                  } else if (data.name === "Sensibo") {
                                      setIftttPartnerAccountId(
                                          0,
                                      );
                                      setPartnerId(data.partnerId);
                                      setAuthCode(null); // => resets auth page code
                                      if (data.hasToken && data.isActive) {
                                          setOpenAddDeviceDlg(true);
                                      } else {
                                          setOpenAuthDlg(true);
                                      }
                                  } else if (data.name === "IFTTT") {
                                      setIftttPartnerAccountId(data.partnerAccountId);
                                      setPartnerId(data.partnerId);
                                      setAuthCode(null); // => resets auth page code
                                      if (data.hasToken && data.isActive) {
                                          setOpenAddDeviceDlg(true);
                                      } else {
                                          setAuthCode(uuid());
                                          setOpenAddDeviceDlg(true);
                                      }
                                  }
                              }}
                              variant={data.hasToken ? "contained" : "contained"}
                              color={data.hasToken ? "success" : "primary"}
                              startIcon={
                                  data.hasToken ? <OpenInBrowser /> : <InsertLink />
                              }
                              size="small"
                              fullWidth
                          >
                              {data.hasToken ? "Devices" : "Link"}
                          </Button>
                      </Grid>
                      {data.hasToken && (
                          <Grid item xs={6} sm={4} align="center">
                              <Button
                                  key={data.partnerId}
                                  onClick={() => {
                                      setPartner(data);
                                      setConfirmRemoveAccount(true);
                                  }}
                                  variant="contained"
                                  color="error"
                                  startIcon={<LinkOff />}
                                  fullWidth
                                  size="small"
                              >
                                  Unlink
                              </Button>
                          </Grid>
                      )}
                  </Fragment>
              ))}
                    <CustomRemoveDialog
                        open={confirmRemoveAccount}
                        title="Confirm Unlink"
                        message="Unlinking will remove all devices associated with this third-party account. Do you still wish to proceed?"
                        okButtonText="Unlink"
                        onRemoveClick={async () => {
                            setSwitchLoading(true);
                            await switchPartnerAccount({
                                partnerAccountId: partner.partnerAccountId,
                            });
                            setConfirmRemoveAccount(false);
                        }}
                        onCancelClick={() => setConfirmRemoveAccount(false)}
                        loading={isLoadingSwitchPartnerAccount}
                        icon="unlink"
                    />
                </Grid>
            )}
            {authUrl !== "" && (
                <Dialog open={openAuthDlg} maxWidth="md" fullWidth>
                    <DialogTitle>
              &nbsp;
                        <IconButton
                            onClick={() => setOpenAuthDlg(false)}
                            sx={sxCloseDlgButton}
                        >
                            <CloseOutlined />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <iframe
                            title="Device Auth Page"
                            src={authUrl}
                            width="100%"
                            height={500}
                        />
                    </DialogContent>
                </Dialog>
            )}
            {authUrl === "" && (
                <Dialog open={openAuthDlg}>
                    <DialogTitle>Sensibo Authentication</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        Log in to your Sensibo account via this
                                        {" "}
                                        <Link
                                            href="https://home.sensibo.com/me/api"
                                            target="_blank"
                                        >
                                            webapp.
                                        </Link>
                                        {" "}
                                    </Grid>
                                    <Grid item>
                                        Generate an API key, then copy and paste the key on the
                                        textbox below.
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                {/* <FormControl> */}
                                <TextField
                                    label="API Key"
                                    placeholder="******"
                                    required
                                    type="password"
                                    error={sensiboAuthKey.length === 0}
                                    helperText="Required"
                                    value={sensiboAuthKey}
                                    onChange={(e) => setSensiboAuthKey(e.target.value)}
                                />
                                {/* </FormControl> */}
                            </Grid>
                            {hasAuthError && (
                                <Grid item>
                                    <Alert
                                        severity="error"
                                        onClose={() => setHasAuthError(false)}
                                    >
                                        {authErrorMsg}
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            disabled={submitLoading}
                            variant="contained"
                            onClick={onCancelHandler}
                            loading={cancelLoading}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            disabled={
                                submitDisabled || sensiboAuthKey.length === 0 || cancelLoading
                            }
                            variant="contained"
                            onClick={onSubmitHandler}
                            loading={submitLoading}
                        >
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            )}
            <Dialog open={openAddDeviceDlg} maxWidth={forSignUp ? "md" : "sm"} fullWidth>
                <DialogContent>
                    {forSignUp ? (
                        <SelectMultipleDevices
                            paramAuthCode={authCode}
                            paramPropertyId={propertyId}
                            paramPartnerId={partnerId}
                            closeDlgCallback={handleCloseAddDeviceDlgSignUp}
                            callbackRequiredHandler={callbackRequired}
                            setHasDuplicate={setHasDuplicate}
                            propertyId={propertyId}
                            iftttPartnerAccountId={iftttPartnerAccountId}
                        />
                    ) : (
                        <SelectDevices
                            paramAuthCode={authCode}
                            paramPropertyId={propertyId}
                            paramPartnerId={partnerId}
                            closeDlgCallback={handleCloseAddDeviceDlgProperties}
                            closePartnersModal={ClosePartnerList}
                            refreshPartnerList={refreshPartnerList}
                            configureAlertBox={configureAlertBox}
                            updateDeviceHandler={updateDeviceHandler}
                            setSelectedDevice={setSelectedDevice}
                            iftttPartnerAccountId={iftttPartnerAccountId}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default PartnerListForm;
