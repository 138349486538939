import React, { useEffect, useRef, useState } from "react";
import {
    Skeleton,
    Container,
    FormControl,
    Grid,
    TextField,
    Alert,
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { API } from "aws-amplify";
import { Send } from "@mui/icons-material";
import {
    StyleTableContainer,
    StyledTableCell,
    StyledTableHeadCell,
} from "../helper/StyleTable";
import ProxyDevice from "./ProxyDevice";
import * as PARTNERS from "./constants/Partners";
import { useAddDevicesMutation, useAddUpdatePropertyDeviceStateMutation, useDeletePropertyDeviceStateMutation, useGetDevicePartnersQuery, useUpdateDeviceMutation } from "../../features/auth/devicesAPI";
import { useLazyGetRulesByPropertyQuery, useUpdateRuleMutation } from "../../features/auth/rulesAPI";
import { useLazyGetConditionsByRuleQuery } from "../../features/auth/conditionsAPI";
import { useLazyGetActionsByRuleIdQuery } from "../../features/auth/actionsAPI";
import CustomDeviceSelectButton from "./CustomDeviceSelectButton";

export default function SelectDevices({
    paramAuthCode,
    paramPartnerId,
    paramPropertyId,
    closeDlgCallback,
    closePartnersModal,
    configureAlertBox,
    updateDeviceHandler,
    iftttPartnerAccountId,
}) {
    const [partnerAccId, setpartnerAccountId] = useState(null);
    const [apidevBodydata, setBodyDevData] = useState({
        DeviceId: "",
        type: "doorSensor",
        token: "",
        partnerId: paramPartnerId,
        PropertyId: paramPropertyId,
        DisplayName: "",
        Name: "doorSensor",
        isCheckInOutDoor: false,
    });
    const [DeviceName, setDeviceName] = useState("");
    const [FloorNumber, setFloorNumber] = useState("1");

    const [DevFromAPI, setDeviceFromAPI] = useState([]);
    const [loading, setLoading] = useState(false);

    const [showGetDevicesLoader, setShowGetDevicesLoader] = useState(true);
    const [disableConfirmBtn, setDisableConfirmBtn] = useState(false);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(0);
    const txtDeviceRef = useRef();
    const [showDeviceEditField, setShowDeviceEditField] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertMsgSeverity, setAlertMsgSeverity] = useState("");

    const [pollingGetDevicePartners, setPollingGetDevicePartners] = useState(3000);
    const [counterGetDevicePartners, setCounterGetDevicePartners] = useState(0);
    const [paramCode, setParamCode] = useState(paramAuthCode || "");
    const {
        data: dataGetDevicePartners,
        isSuccess: isSuccessGetDevicePartners,
        isError: isErrorGetDevicePartners,
        isFetching: isFetchingGetDevicePartners,
        isLoading: isLoadingGetDevicePartners,
        error: errorGetDevicePartners,
    } = useGetDevicePartnersQuery(
        {
            apiBody: {
                body: {
                    code: paramCode,
                    propertyId: paramPropertyId,
                    partnerId: paramPartnerId,
                },
            },
        },
        {
            skip: iftttPartnerAccountId !== 0,
            pollingInterval: pollingGetDevicePartners,
        },
    );

    const isGettingDevicePartners = isFetchingGetDevicePartners || isLoadingGetDevicePartners;

    useEffect(() => {
        setpartnerAccountId(iftttPartnerAccountId);
    }, [iftttPartnerAccountId]);

    // if get device partners is success, set pollingGetDevicePartners to zero (0)
    // else continue polling for 3 seconds
    useEffect(() => {
        if (isSuccessGetDevicePartners) {
            setPollingGetDevicePartners(0);
        }
    }, [isSuccessGetDevicePartners]);

    // if error getting device partners, increase counterGetDevicePartners
    useEffect(() => {
        if (isErrorGetDevicePartners) {
            if (errorGetDevicePartners.status === 400) {
                localConfigureAlertBox(
                    "Failed to get devices due to an unexpected error. Please try again later.",
                    "error",
                );
                setPollingGetDevicePartners(0);
            } else {
                localConfigureAlertBox("Retrying...", "info");
                setParamCode("");
                setCounterGetDevicePartners(counterGetDevicePartners + 1);
            }
        }
    }, [isErrorGetDevicePartners, errorGetDevicePartners]);

    useEffect(() => {
        if (counterGetDevicePartners > 2) {
            localConfigureAlertBox(
                "Failed to get devices due to an unexpected error. Please try again later.",
                "error",
            );
            // setting pollingGetDevicePartners to zero will stop repeate get device partners request
            setPollingGetDevicePartners(0);
        }
    }, [counterGetDevicePartners]);

    useEffect(() => {
        setShowGetDevicesLoader(isGettingDevicePartners);
    }, [isGettingDevicePartners]);

    useEffect(() => {
        if (dataGetDevicePartners !== undefined) {
            localConfigureAlertBox("", "");
            // console.log("setShowGetDevicesLoader after partner post: ", showGetDevicesLoader);
            // setInfoMsg("");
            const getDevice = dataGetDevicePartners.Payload.Others;
            setpartnerAccountId(dataGetDevicePartners.PartnerAccountId);
            // console.log("PA set: ", JSON.parse(res.data).PartnerAccountId);
            const deviceArr = [];
            getDevice.forEach((d) => {
                if (d.name !== "YoLink Fob" && d.name !== "YoLink Hub") {
                    const formattedData = {
                        DeviceId: d.deviceId,
                        type: d.type,
                        DeviceToken: d.token,
                        partnerId: paramPartnerId,
                        PartnerAccountId: dataGetDevicePartners.PartnerAccountId,
                        PropertyId: parseInt(paramPropertyId, 10),
                        DisplayName: `${d.name} - ${d.deviceId}`,
                        Name: d.name,
                        isSelected: false,
                    };
                    deviceArr.push(formattedData);
                }
            });
            setDeviceFromAPI(deviceArr);
            setShowGetDevicesLoader(false);
            if (dataGetDevicePartners.Payload.Others.length > 0) {
                setDisableConfirmBtn(false);
            }
        }
    }, [dataGetDevicePartners]);

    const localConfigureAlertBox = (msg, severity) => {
        setAlertMsgSeverity(severity);
        setAlertMsg(msg);
    };

    const [
        addUpdatePropertyDeviceState,
        {
            data: addedUpdatePropertyDeviceState,
            error: errorAddedUpdatePropertyDeviceState,
            isError: isErrorAddedUpdatePropertyDeviceState,
            isSuccess: isSuccessAddedUpdatePropertyDeviceState,
        },
    ] = useAddUpdatePropertyDeviceStateMutation();
    const [
        addDevices,
        {
            data: addedDevices,
            error: errorAddDevices,
            isError: isErrorAddDevices,
            isSuccess: isSuccessAddDevices,
        },
    ] = useAddDevicesMutation();
    const [
        deletePropertyDeviceState,
        {
            data: deletedPropertyDeviceState,
            message: addedDeletedPropertyDeviceState,
            error: errorDeletedPropertyDeviceState,
            isError: isErrorDeletedPropertyDeviceState,
            isSuccess: isSuccessDeletedPropertyDeviceState,
        },
    ] = useDeletePropertyDeviceStateMutation();

    const [updateRule] = useUpdateRuleMutation();
    const [
        triggerGetRulesByProperty,
        { data: dataGetRulesByProperty, isSuccess: isSuccessGetRulesByProperty },
    ] = useLazyGetRulesByPropertyQuery();
    const [triggerGetConditionsByRule] = useLazyGetConditionsByRuleQuery();
    const [triggerGetActionsByRule] = useLazyGetActionsByRuleIdQuery();

    const configureNewDefaultRuleName = (oldRuleName, displayName) => {
        if (displayName !== "") {
            if (oldRuleName.includes("is open.")) {
                return `${displayName} is open.`;
            }
            if (oldRuleName.includes("has been left opened.")) {
                return `${displayName} has been left opened.`;
            }
            return `${displayName} is in alert state`;
        }
        return "";
    };

    useEffect(() => {
        if (
            isSuccessAddedUpdatePropertyDeviceState
            && addedUpdatePropertyDeviceState !== undefined
        ) {
            SubmitDevice();
        }

        if (
            isErrorAddedUpdatePropertyDeviceState
            && errorAddedUpdatePropertyDeviceState !== undefined
        ) {
            localConfigureAlertBox(
                "An unexpected error occured. Please try again later.",
                "error",
            );
            setLoading(false);
        }
    }, [
        isSuccessAddedUpdatePropertyDeviceState,
        isErrorAddedUpdatePropertyDeviceState,
    ]);

    useEffect(() => {
        configureAlertBox("", "");
        if (isSuccessAddDevices && addedDevices !== undefined) {
            configureAlertBox(
                `Currently adding your device: ${addedDevices.addedDevices[0].displayName}`,
                "info",
            );
        }
        if (isErrorAddDevices && errorAddDevices !== undefined) {
            errorAddDevices.data.failedDevices.forEach(async (device) => {
                await deletePropertyDeviceState({
                    propertyId: paramPropertyId,
                    displayName: device.displayName,
                });
            });
            if (
                errorAddDevices.message[0].includes("An update will be done instead")
            ) {
                configureAlertBox(`${errorAddDevices.message[0]}`, "warning");
                setTimeout(() => {
                    updateDeviceHandler(errorAddDevices.data.updateDevices[0]);
                }, 3000);
            } else {
                configureAlertBox(errorAddDevices.message[0], "error");
            }
        }
    }, [isSuccessAddDevices, isErrorAddDevices]);

    useEffect(() => {
        if (showDeviceEditField) {
            txtDeviceRef.current.childNodes[1].childNodes[0].focus();
        }
    }, [showDeviceEditField]);

    const SubmitDevice = async () => {
        setLoading(true);
        const bodyContent = {
            body: {
                partnerAccountId: partnerAccId,
                propertyId: parseInt(paramPropertyId, 10),
                devices: [
                    {
                        fromPropertyDetails: true,
                        ExternalId: apidevBodydata.DeviceId,
                        type: apidevBodydata.type,
                        DeviceToken: apidevBodydata.token,
                        partnerId: paramPartnerId,
                        PartnerAccountId: partnerAccId,
                        PropertyId: parseInt(paramPropertyId, 10),
                        DisplayName: DeviceName,
                        FloorNumber: parseInt(FloorNumber, 10),
                        Name: apidevBodydata.Name,
                        isCheckInOutDoor: apidevBodydata.isCheckInOutDoor,
                        isEnabled: true,
                    },
                ],
            },
        };
        await addDevices({ devicesArray: bodyContent });
        setLoading(false);
        if (closeDlgCallback) {
            closeDlgCallback();
            closePartnersModal();
        }
    };

    const AddDeviceBtn = async (e) => {
        e.preventDefault();
        setLoading(true);
        const bodyContent = {
            body: {
                partnerAccountId: partnerAccId,
                propertyId: parseInt(paramPropertyId, 10),
                devices: [
                    {
                        fromPropertyDetails: true,
                        ExternalId: apidevBodydata.DeviceId,
                        type: apidevBodydata.type,
                        DeviceToken: apidevBodydata.token,
                        partnerId: paramPartnerId,
                        PartnerAccountId: partnerAccId,
                        PropertyId: parseInt(paramPropertyId, 10),
                        DisplayName: DeviceName,
                        FloorNumber: parseInt(FloorNumber, 10),
                        Name: apidevBodydata.Name,
                        isCheckInOutDoor: apidevBodydata.isCheckInOutDoor,
                        isEnabled: true,
                    },
                ],
            },
        };
        await addUpdatePropertyDeviceState({
            devicesArray: bodyContent.body.devices,
        });
    };

    const handleSelectedDeviceList = (e, index) => {
        const deviceInfo = DevFromAPI.find((x) => x.DeviceId === index);

        setSelectedDeviceIndex(index);
        setDeviceName(`${deviceInfo.Name} - ${deviceInfo.DeviceId}`);
        setBodyDevData((prev) => ({
            ...prev,
            DeviceId: deviceInfo.DeviceId,
            type: deviceInfo.type,
            token: deviceInfo.DeviceToken,
            Name: deviceInfo.Name,
        }));
        setShowDeviceEditField(true);
    };

    return (
        <Container maxWidth="md">
            <FormControl sx={{ width: "100%" }}>
                <form>
                    <Grid container spacing={1}>
                        {alertMsg !== "" && alertMsgSeverity !== "" && (
                            <Grid item xs={12}>
                                <Alert
                                    severity="error"
                                    onClose={() => {
                                        setAlertMsg("");
                                        setAlertMsgSeverity("");
                                    }}
                                >
                                    {alertMsg}
                                </Alert>
                            </Grid>
                        )}

                        {paramPartnerId !== 3 && (
                            <Grid item xs={12}>
                                <StyleTableContainer sx={{ maxHeight: 200 }}>
                                    <Table stickyHeader size="small">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableHeadCell>
                                                    Please select the device that you want to add
                                                </StyledTableHeadCell>
                                                <StyledTableHeadCell width={100} />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {showGetDevicesLoader && (
                                                <TableRow>
                                                    <StyledTableCell colSpan={2}>
                                                        <Skeleton
                                                            variant="rounded"
                                                            height={30}
                                                            width="inherit"
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            Loading...Please Wait
                                                        </Skeleton>
                                                    </StyledTableCell>
                                                </TableRow>
                                            )}

                                            {(DevFromAPI.length === 0
                                                || alertMsg.includes("Failed to get devices")) && !showGetDevicesLoader && (
                                                <TableRow>
                                                    <StyledTableCell>No devices found.</StyledTableCell>
                                                </TableRow>
                                            )}

                                            {DevFromAPI.map((res) => {
                                                const devandtoken = `${res.Name} - ${res.DeviceId}`;
                                                return (
                                                    <TableRow key={res.DeviceId}>
                                                        <StyledTableCell>
                                                            {devandtoken}
                                                            {/* <CustomDeviceSelectButton
                                                                label={devandtoken}
                                                                fullWidth
                                                                isSelected={selectedDeviceIndex === res.DeviceId}
                                                                disabled={loading}
                                                                onClick={(e) => handleSelectedDeviceList(e, res.DeviceId)}
                                                            /> */}
                                                            {/* <Button
                                                                variant={
                                                                    selectedDeviceIndex === res.DeviceId
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                fullWidth
                                                                disabled={loading}
                                                                onClick={(e) => handleSelectedDeviceList(e, res.DeviceId)}
                                                            >
                                                                {devandtoken}
                                                            </Button> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <Switch
                                                                checked={selectedDeviceIndex === res.DeviceId}
                                                                onChange={(e) => handleSelectedDeviceList(e, res.DeviceId)}
                                                            />
                                                        </StyledTableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </StyleTableContainer>
                            </Grid>
                        )}
                        {paramPartnerId === PARTNERS.IFTTT_PARTNERID && (
                            <ProxyDevice
                                setDevData={setBodyDevData}
                                setName={setDeviceName}
                                devData={apidevBodydata}
                                showLoader={showGetDevicesLoader}
                                setFloorNumber={setFloorNumber}
                                floorNumber={FloorNumber}
                            />
                        )}
                        <Grid item xs={12} />
                        <Grid item xs={12} display={showDeviceEditField ? "" : "none"}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        className="txtDeviceName"
                                        label="Device Name"
                                        error={DeviceName.length === 0}
                                        helperText="Name must be filled out."
                                        onChange={(e) => {
                                            setDeviceName(e.target.value);
                                        }}
                                        ref={txtDeviceRef}
                                        fullWidth
                                        size="small"
                                        disabled={loading}
                                        value={DeviceName}
                                    />
                                </Grid>
                                {/* <Grid item xs={2}>
                                    <TextField
                                        className="txtFloorNumber"
                                        label="Floor Number"
                                        type="number"
                                        error={FloorNumber.length === 0}
                                        helperText="Floor Number must be filled out"
                                        onChange={(e) => {
                                            setFloorNumber(e.target.value);
                                        }}
                                        fullWidth
                                        size="small"
                                        disabled={loading}
                                    />
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        color="close"
                                        variant="outlined"
                                        onClick={() => {
                                            if (closeDlgCallback) closeDlgCallback();
                                        }}
                                        disabled={loading}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <LoadingButton
                                        variant="contained"
                                        loading={loading}
                                        loadingPosition="end"
                                        disabled={
                                            loading
                                            || DeviceName.length === 0
                                            || FloorNumber.length === 0
                                            || disableConfirmBtn
                                        }
                                        endIcon={<Send />}
                                        fullWidth
                                        onClick={(e) => AddDeviceBtn(e)}
                                    >
                                        Add New Device
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </FormControl>
        </Container>
    );
}
